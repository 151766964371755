import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Empty, notification, Pagination, Result, Spin } from "antd";
import { sampleApiCall } from "../../apiservice/authService";
import useFormatApiRequest from "../../hooks/formatApiRequest";
import { useAppDispatch } from "../../Redux/reduxCustomHook";
import { ILoadState } from "../../utils/loading.utils.";
import "./Vendor-Profile-Comp.css";

type NotificationType = "success" | "info" | "warning" | "error";

type IVendorTransactionsList = {
  externalFilter?: any;
  initialDefaultFilter?: any;
  hidePagination?: boolean;
};

export const VendorTransactionsList: React.FC<IVendorTransactionsList> = ({
  externalFilter,
  initialDefaultFilter,
  hidePagination = false,
}) => {
  const [transactionsLoadState, settransactionsLoadState] =
    useState<ILoadState>("loading");
  const [loadTransactionsData, setLoadtransactionsData] = useState(true);
  const [transactionsDefaultFilter, settransactionsDefaultFilter] = useState(
    initialDefaultFilter || {}
  );
  const [tableData, setTableData] = useState<any[]>([]);
  const [api, contextHolder] = notification.useNotification();

  // Pagination Constant/Variables
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const perPage = 10;

  // For Navigator/Redux
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Use Effect to reload API when External Filter has changed
  useEffect(() => {
    if (externalFilter) {
      settransactionsDefaultFilter({
        ...transactionsDefaultFilter,
        ...externalFilter,
      });
      setLoadtransactionsData(true);
      settransactionsLoadState("loading");
    }
  }, [externalFilter]);

  // A custom hook to Load All transactions Details
  const transactionsDataResult = useFormatApiRequest(
    () => sampleApiCall(transactionsDefaultFilter),
    loadTransactionsData,
    () => {
      setLoadtransactionsData(false);
    },
    () => {
      processtransactionsResult();
    }
  );

  // Process The Current transactions Data Result
  const processtransactionsResult = async () => {
    if (transactionsDataResult.httpState === "SUCCESS") {
      setTableData(
        transactionsDataResult.data?.data ||
          Array.from({ length: 10 }, (_, i) => ({
            transactionName: `Transactions from Sales ${i + 1}`,
            transactionDate: `Jan 1st 2023`,
            amount: `$15`,
            status: `Success`,
            image: "https://via.placeholder.com/400",
          }))
      );
      settransactionsLoadState("completed");
      setTotalItems(transactionsDataResult.data?.meta?.total || 10);
    } else if (transactionsDataResult.httpState === "ERROR") {
      settransactionsLoadState("error");
    } else if (transactionsDataResult.httpState === "LOADING") {
      settransactionsLoadState("loading");
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  // Use to Control Pagination On Change Event
  const onPageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    settransactionsDefaultFilter({
      ...transactionsDefaultFilter,
      page: page,
      perPage: pageSize,
    });
    setLoadtransactionsData(true);
  };

  // Navigate to the next Page
  const navigateForward = async (index: number) => {
    navigate(`/vendors/profile-settings-transaction/${index}`);
  };

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}
      <div className="grid pt-4">
        {/* " Show Loading Indicator" */}
        {transactionsLoadState === "loading" && (
          <div className="mt-2 mb-2 flex justify-center items-center pt-20 pb-20">
            <Spin size="large" />
          </div>
        )}

        {/* " Show Loading Error" */}
        {transactionsLoadState === "error" && (
          <div className="mt-2 mb-2">
            <Result
              status="500"
              title={<span className="">Error</span>}
              subTitle={
                <span className="">
                  Sorry, something went wrong, it could be a network Related
                  error
                </span>
              }
              extra={
                <Button
                  onClick={() => setLoadtransactionsData(true)}
                  type="primary"
                >
                  Reload
                </Button>
              }
            />
          </div>
        )}

        {/* " Show No data" */}
        {transactionsLoadState === "noData" && (
          <div className="mt-2">
            <Empty />
          </div>
        )}

        {/* " Show Data " */}
        {transactionsLoadState === "completed" && (
          <div className="mt-2">
            <div>
              {/* transactions */}
              <div className="grid grid-cols-1 gap-4">
                {tableData.map((transactions, index) => (
                  <div key={index} onClick={() => navigateForward(index)}>
                    {/* Cards */}
                    <div className="flex flex-row p-2 w-full bg-white border border-gray-200 rounded-xl">
                      <p className="w-12 flex items-center justify-start">
                        <img
                          className="w3-circle"
                          style={{ width: "48px" }}
                          src={transactions?.image}
                          alt=""
                        />
                      </p>

                      <div className="grow p-2 flex flex-col items-start justify-center">
                        <h2 className="font-semibold font-sans text-md mb-2">
                          {transactions?.transactionName}
                        </h2>
                        <p className="text-sm font-san">
                          {transactions?.transactionDate}
                        </p>
                      </div>

                      <p className="w-12 flex flex-col items-end justify-center">
                        <p className="mb-2"></p>
                        <p className="text-sm font-san">
                          {transactions?.amount}
                        </p>
                      </p>
                    </div>
                    {/* End  Cards */}
                  </div>
                ))}
              </div>
              {/* End transactions */}
            </div>

            {!hidePagination && (
              <div className="grid mt-6">
                <Pagination
                  current={currentPage || 1}
                  onChange={onPageChange}
                  pageSize={perPage}
                  total={totalItems}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
