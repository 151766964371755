import React, { useState } from "react";
import { CloseCircleOutlined, EditOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import "./Vendor-Profile-Comp.css";
import VendorOnboardingPhotoFormComp from "../vendorOnboardingComponents/Vendor-onboarding-Photos-Form-comp";

const VendorProfileBusinessImagesComp = () => {
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState<number>(-1); // State to track the index of the image to delete

  const images = [
    "https://via.placeholder.com/400",
    "https://via.placeholder.com/400",
    "https://via.placeholder.com/400",
    "https://via.placeholder.com/400",
  ];

  const showAddModal = () => {
    setIsAddModalVisible(true);
  };

  const handleAddModalCancel = () => {
    setIsAddModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleDelete = (index) => {
    setDeleteIndex(index); // Set the index of the image to delete
    setIsModalVisible(true); // Show the confirmation modal
  };

  const confirmDelete = () => {
    const updatedImages = [...images];
    updatedImages.splice(deleteIndex, 1);
    setIsModalVisible(false); // Close the modal after deletion
  };

  return (
    <div className=" p-2 bg-white border border-gray-300 rounded-lg mb-4">
      <div className="flex items-center">
        {/* Title */}
        <div className="ml-2 flex-grow">
          <h2 className="pt-2 pb-2 font-sans text-lg font-semibold">
            Business Image
          </h2>
        </div>

        {/* Right Edit Icon */}
        <div className="ml-4 flex-shrink-0">
          <button
            className="text-gray-800 hover:text-green"
            onClick={showAddModal}
          >
            <EditOutlined className="text-2xl" />
          </button>
        </div>
      </div>

      <div className="flex flex-wrap">
        {images.map((image, index) => (
          <div key={index} className="relative w-1/2 lg:w-1/4 p-2">
            <button
              onClick={() => handleDelete(index)}
              className="absolute top-2 right-2 bg-white rounded-full p-1 hover:bg-gray-200"
            >
              <CloseCircleOutlined className="text-red-500 text-lg" />
            </button>
            <img
              src={image}
              alt={`Image_ ${index + 1}`}
              className="rounded-lg shadow-md"
            />
          </div>
        ))}
      </div>

      {/* Delete Confirmation Modal */}
      <Modal
        title="Confirm Delete"
        visible={isModalVisible}
        onOk={confirmDelete} // Triggered when user clicks "OK"
        onCancel={handleCancel}
        okText="Delete"
        cancelText="Cancel"
      >
        <p>Are you sure you want to delete this image?</p>
      </Modal>

      {/* Edit Profile Modal */}
      <Modal
        title="Add Images"
        visible={isAddModalVisible}
        onCancel={handleAddModalCancel}
        okText="Save"
        cancelText="Cancel"
        footer={[<p style={{ minHeight: "00px" }}></p>]}
      >
        <div style={{ top: "50px", maxHeight: "450px", overflow: "scroll" }}>
          <VendorOnboardingPhotoFormComp
            editMode
          ></VendorOnboardingPhotoFormComp>
        </div>
      </Modal>
    </div>
  );
};

export default VendorProfileBusinessImagesComp;
