import instance from "../utils/axios.wrapper";
import { convertObjToQueryParams } from "../utils/basic.utils";

export const sampleApiCall = async (body?: any) => {
  const axios = await instance("", "https://dummyjson.com/", true);
  const { data } = await axios.get("/todos");
  const result: any = await data;
  return result;
};

export const vendorAuthSignUp = async (body: any) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.post(
    "api/v1/vendor/auth/register/basic-details",
    body
  );
  const result: any = await data;
  return result;
};

export const vendorAuthSignUpAddress = async (body: any) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.post(
    "api/v1/vendor/auth/register/address-details",
    body
  );
  const result: any = await data;
  return result;
};

export const vendorAuthSignUpBusinessDetails = async (body: any) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.post(
    "api/v1/vendor/auth/register/business-details",
    body
  );
  const result: any = await data;
  return result;
};

export const vendorAuthSignIn = async (body: any) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.post("api/v1/vendor/auth/login", body);
  const result = await data;
  return result;
};

export const vendorAuthRefreshSession = async () => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.get("api/v1/vendor/auth/sessions-login");
  const result = await data;
  return result;
};

export const vendorAuthSendverifyEmail = async (body?: any) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.post(
    "api/v1/vendor/auth/verify-email/send-email",
    body
  );
  const result = await data;
  return result;
};

export const vendorAuthConfirmToken = async (body: any) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.post(
    "api/v1/vendor/auth/verify-email/confirm-token",
    body
  );
  const result = await data;
  return result;
};

export const vendorAuthUpdateDetails = async (body: any) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.patch("api/v1/vendorAuth/update-details", body);
  const result = await data;
  return result;
};

export const vendorAuthUpdateDP = async (body: any) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.patch("api/v1/vendorAuth/update-dp", body);
  const result = await data;
  return result;
};

export const vendorAuthUpdatePassword = async (body: any) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.post("api/v1/vendorAuth/verify-email", body);
  const result = await data;
  return result;
};

export const vendorAuthResetPassword = async (body: any) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.post(
    "api/v1/vendorAuth/forgot-password/send-email",
    body
  );
  const result = await data;
  return result;
};

export const vendorAuthSetNewPassword = async (body: any) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.post(
    "api/v1/vendorAuth/forgot-password/confirm-token",
    body
  );
  const result = await data;
  return result;
};

// Vendors Services
export const vendorGetServices = async (body?: any) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.get(
    `api/v1/vendor/services${convertObjToQueryParams(body)}`
  );
  const result: any = await data;
  return result;
};

export const vendorGetService = async (id: string | number) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.get(`api/v1/vendor/services/${id}`);
  const result: any = await data;
  return result;
};

// Vendor Sub Service
export const vendorGetSubServices = async (body?: any) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.get(
    `api/v1/vendor/subservices${convertObjToQueryParams(body)}`
  );
  const result: any = await data;
  return result;
};

export const vendorGetSubService = async (id: string | number) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.get(`api/v1/vendor/subservices/${id}`);
  const result: any = await data;
  return result;
};

// Vendor Add Service
export const vendorAddService = async (body: any) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.post("api/v1/vendor/vendor-subservices", body);
  const result = await data;
  return result;
};

export const vendorGetSavedSubServices = async (body?: any) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.get(
    `api/v1/vendor/vendor-subservices${convertObjToQueryParams(body)}`
  );
  const result: any = await data;
  return result;
};

// Vendor Image
export const vendorGetImage = async (body?: any) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.get(
    `api/v1/vendor/vendor-images${convertObjToQueryParams(body)}`
  );
  const result: any = await data;
  return result;
};

export const vendorAddImage = async (body: any) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.post("api/v1/vendor/vendor-images", body);
  const result = await data;
  return result;
};

// Vendor Notifications
export const getUserNotifications = async (body?: any) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.get(
    `api/v1/notifications${convertObjToQueryParams(body)}`
  );
  const result: any = await data;
  return result;
};

export const getUserNotification = async (id: string | number) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.get(`api/v1/notifications/${id}`);
  const result: any = await data;
  return result;
};

//Availability
export const vendorGetAvailability = async (body?: any) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.get(
    `api/v1/vendor/vendor-availabilities${convertObjToQueryParams(body)}`
  );
  const result: any = await data;
  return result;
};

export const vendorUpdateAvailability = async (body: any) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.put("api/v1/vendor/vendor-availabilities", body);
  const result = await data;
  return result;
};

// User Activity
export const getUserRecentActivities = async (body?: any) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.get(
    `api/v1/recent-activities${convertObjToQueryParams(body)}`
  );
  const result: any = await data;
  return result;
};
