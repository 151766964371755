import { MessageOutlined } from "@ant-design/icons";
import {
  Button,
  Empty,
  notification,
  Pagination,
  Rate,
  Result,
  Spin,
} from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { sampleApiCall } from "../../apiservice/authService";
import useFormatApiRequest from "../../hooks/formatApiRequest";
import { useAppDispatch } from "../../Redux/reduxCustomHook";
import { ILoadState } from "../../utils/loading.utils.";
import "./Prop-Comp.css";
type NotificationType = "success" | "info" | "warning" | "error";

type IPropsListComp = {
  externalFilter?: any;
  initialDefaultFilter?: any;
  hidePagination?: boolean;
};

export const PropsListComp: React.FC<IPropsListComp> = ({
  externalFilter,
  initialDefaultFilter,
  hidePagination = true,
}) => {
  const [propsLoadState, setPropsLoadState] = useState<ILoadState>("loading");
  const [loadpropsData, setLoadPropsData] = useState(true);
  const [propsDefaultFilter, setPropsDefaultFilter] = useState(
    initialDefaultFilter || {}
  );
  const [tableData, setTableData] = useState<any[]>([]);
  const [api, contextHolder] = notification.useNotification();

  // Pagination Constant/Variables
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const perPage = 10;

  // For Navigator/Redux
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Use Effect to reload API when External Filter has changed
  useEffect(() => {
    if (externalFilter) {
      setPropsDefaultFilter({
        ...propsDefaultFilter,
        ...externalFilter,
      });
      setLoadPropsData(true);
      setPropsLoadState("loading");
    }
  }, [externalFilter]);

  // A custom hook to Load All props Details
  const propsDataResult = useFormatApiRequest(
    () => sampleApiCall(propsDefaultFilter),
    loadpropsData,
    () => {
      setLoadPropsData(false);
    },
    () => {
      processpropsResult();
    }
  );

  // Process The Current Props Data Result
  const processpropsResult = async () => {
    if (propsDataResult.httpState === "SUCCESS") {
      setTableData(
        propsDataResult.data?.data || [
          {
            title: "Braid",
            imageUrl: `${
              process.env.PUBLIC_URL + "/images/topService/service1.png"
            }`,
            images: [
              `${
                process.env.PUBLIC_URL + "/images/topService/propsImage1.png"
              }`,
              `${
                process.env.PUBLIC_URL + "/images/topService/propsImage2.png"
              }`,
              `${
                process.env.PUBLIC_URL + "/images/topService/propsImage3.png"
              }`,
              `${
                process.env.PUBLIC_URL + "/images/topService/propsImage4.png"
              }`,
            ],
          },
          {
            title: "Loc",
            imageUrl: `${
              process.env.PUBLIC_URL + "/images/topService/service2.png"
            }`,
            images: [
              `${
                process.env.PUBLIC_URL + "/images/topService/propsImage1.png"
              }`,
              `${
                process.env.PUBLIC_URL + "/images/topService/propsImage2.png"
              }`,
              `${
                process.env.PUBLIC_URL + "/images/topService/propsImage3.png"
              }`,
              `${
                process.env.PUBLIC_URL + "/images/topService/propsImage4.png"
              }`,
            ],
          },
          {
            title: "Natural Hair",
            imageUrl: `${
              process.env.PUBLIC_URL + "/images/topService/service3.png"
            }`,
            images: [
              `${
                process.env.PUBLIC_URL + "/images/topService/propsImage1.png"
              }`,
              `${
                process.env.PUBLIC_URL + "/images/topService/propsImage2.png"
              }`,
              `${
                process.env.PUBLIC_URL + "/images/topService/propsImage3.png"
              }`,
              `${
                process.env.PUBLIC_URL + "/images/topService/propsImage4.png"
              }`,
            ],
          },
          {
            title: "Braid",
            imageUrl: `${
              process.env.PUBLIC_URL + "/images/topService/service4.png"
            }`,
            images: [
              `${
                process.env.PUBLIC_URL + "/images/topService/propsImage1.png"
              }`,
              `${
                process.env.PUBLIC_URL + "/images/topService/propsImage2.png"
              }`,
              `${
                process.env.PUBLIC_URL + "/images/topService/propsImage3.png"
              }`,
              `${
                process.env.PUBLIC_URL + "/images/topService/propsImage4.png"
              }`,
            ],
          },
          {
            title: "Loc",
            imageUrl: `${
              process.env.PUBLIC_URL + "/images/topService/service5.png"
            }`,
            images: [
              `${
                process.env.PUBLIC_URL + "/images/topService/propsImage1.png"
              }`,
              `${
                process.env.PUBLIC_URL + "/images/topService/propsImage2.png"
              }`,
              `${
                process.env.PUBLIC_URL + "/images/topService/propsImage3.png"
              }`,
              `${
                process.env.PUBLIC_URL + "/images/topService/propsImage4.png"
              }`,
            ],
          },
          {
            title: "Natural Hair",
            imageUrl: `${
              process.env.PUBLIC_URL + "/images/topService/service6.png"
            }`,
            images: [
              `${
                process.env.PUBLIC_URL + "/images/topService/propsImage1.png"
              }`,
              `${
                process.env.PUBLIC_URL + "/images/topService/propsImage2.png"
              }`,
              `${
                process.env.PUBLIC_URL + "/images/topService/propsImage3.png"
              }`,
              `${
                process.env.PUBLIC_URL + "/images/topService/propsImage4.png"
              }`,
            ],
          },
        ]
      );
      setPropsLoadState("completed");
      setTotalItems(propsDataResult.data?.meta?.total || 1);
    } else if (propsDataResult.httpState === "ERROR") {
      setPropsLoadState("error");
    } else if (propsDataResult.httpState === "LOADING") {
      setPropsLoadState("loading");
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  // Use to Control Pagination On Change Event
  const onPageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPropsDefaultFilter({
      ...propsDefaultFilter,
      page: page,
      perPage: pageSize,
    });
    setLoadPropsData(true);
  };

  // Navigate to the next Page
  const navigateForward = async (index: number) => {
    // dispatch({
    //   type: "TENANT_ADD_SELECTED_Props",
    //   payload: tableData[index],
    // });
    navigate(`/explore/explore-professionals/1/username`);
  };

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}
      <div className="grid p-4">
        {/* " Show Loading Indicator" */}
        {propsLoadState === "loading" && (
          <div className="mt-2 mb-2 flex justify-center items-center pt-20 pb-20">
            <Spin size="large" />
          </div>
        )}

        {/* " Show Loading Error" */}
        {propsLoadState === "error" && (
          <div className="mt-2 mb-2">
            <Result
              status="500"
              title={<span className="">Error</span>}
              subTitle={
                <span className="">
                  Sorry, something went wrong, it could be a network Related
                  error
                </span>
              }
              extra={
                <Button onClick={() => setLoadPropsData(true)} type="primary">
                  Reload
                </Button>
              }
            />
          </div>
        )}

        {/* " Show No data" */}
        {propsLoadState === "noData" && (
          <div className="mt-2">
            <Empty></Empty>
          </div>
        )}

        {/* " Show Data " */}
        {propsLoadState === "completed" && (
          <div className="mt-2">
            <div>
              {/* Title */}
              <div className="mb-4">
                <span className="font-sans text-lg">
                  Find top pros by service
                </span>
              </div>

              {/* Props */}
              <div className="">
                <div className="grid grid-cols-1 gap-6">
                  {tableData.map((props, index) => (
                    <>
                      <div key={index}>
                        {/* Cards */}
                        <div
                          onClick={() => {
                            navigateForward(index);
                          }}
                          className="flex flex-col p-2 w-full shadow-lg shadow-stone-100 bg-white border border-gray-200 rounded-xl"
                        >
                          <div className="p-1 flex flex-row">
                            <div className="basis-20 p-2 ">
                              <img
                                className=""
                                alt="Props"
                                src={props.imageUrl}
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "100px",
                                }}
                              />
                            </div>

                            <div className="flex-grow p-2 ">
                              <h2 className="font-semibold font-sans text-xl mb-2">
                                Samarthan Pyna
                              </h2>
                              <p className="font-light font-sans text-lg text-grey-700  mb-2">
                                Payne beauty salon
                              </p>
                              <p className="font-light font-sans text-lg  mb-1">
                                <Rate defaultValue={3} /> &nbsp;
                                <span>(4.6)</span>
                              </p>
                              {/* <Flex gap="middle" vertical>
                                <Flex gap="middle">
                                  <Rate defaultValue={3} />
                                  <span>(4.6)</span>
                                </Flex>
                              </Flex> */}
                            </div>

                            <div className="basis-12  p-2 ">
                              <MessageOutlined style={{ zoom: "1.5" }} />
                            </div>
                          </div>

                          <div className="p-1 flex flex-row">
                            {(props?.images || []).map((image, index) => (
                              <div className="basis-1/4 p-2">
                                <img
                                  className=""
                                  alt="Props"
                                  src={image}
                                  style={{
                                    maxWidth: "100%",
                                    maxHeight: "100px",
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                        {/* End  Cards */}
                      </div>
                    </>
                  ))}
                </div>
              </div>
              {/* End Props */}
            </div>

            {!hidePagination && (
              <div className="mt-2">
                <Pagination
                  current={currentPage || 1}
                  onChange={onPageChange}
                  pageSize={perPage}
                  total={totalItems}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
