import React, { useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import "./Vendor-Profile-Comp.css";
import { IVendorAuthType } from "../../apiservice/vendor-authService.type";
import { useAppSelector } from "../../Redux/reduxCustomHook";
import { RootState } from "../../Redux/store";
import VendorOnboardingBusinessFormComp from "../vendorOnboardingComponents/Vendor-onboarding-Business-Form-comp";

const VendorProfileBusinessComp = () => {
  const authVendorData: IVendorAuthType = useAppSelector(
    (state: RootState) => state?.VendorAuthData
  );

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="flex items-center p-2 bg-white border border-gray-300 rounded-lg mb-4">
      {/* Left Picture Circle */}
      <div className="w-30 h-30 flex-shrink-0 rounded-full overflow-hidden">
        <img
          style={{ width: "50px", height: "50px" }}
          src={authVendorData?.data?.credentials?.dpUrl}
          alt="Profile"
          className="w-full h-full object-cover border border-gray-600"
        />
      </div>

      {/* Middle Business Information */}
      <div className="ml-4 flex-grow">
        <h2 className="text-xl font-semibold">Business Name</h2>
        <p className="text-gray-500">
          {authVendorData?.data?.credentials?.businessName}
        </p>
      </div>

      {/* Right Edit Icon */}
      <div className="ml-4 flex-shrink-0">
        <button className="text-gray-800 hover:text-green" onClick={showModal}>
          <EditOutlined className="text-2xl" />
        </button>
      </div>

      {/* Edit Profile Modal */}
      <Modal
        title="Edit Business"
        visible={isModalVisible}
        onCancel={handleCancel}
        okText="Save"
        cancelText="Cancel"
        footer={[<p style={{ minHeight: "00px" }}></p>]}
      >
        <div style={{ top: "50px", maxHeight: "450px", overflow: "scroll" }}>
          <VendorOnboardingBusinessFormComp editMode></VendorOnboardingBusinessFormComp>
        </div>
      </Modal>
    </div>
  );
};

export default VendorProfileBusinessComp;
