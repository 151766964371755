import TopBar from "../../../components/LayoutComponent/TopBar/topBar";
import Tabbar from "../../../components/LayoutComponent/TabBar/Tabbar";
import "../explore.css";
import { PropsDetailCard } from "../../../components/PropComponents/PropsDetailsCard";
import { PropServicesList } from "../../../components/PropComponents/PropServicesList";
import { IAuthType } from "../../../apiservice/authService.type";
import { useAppSelector } from "../../../Redux/reduxCustomHook";
import { RootState } from "../../../Redux/store";

export const ExploreProfessionalUserPage = () => {
  const authData: IAuthType = useAppSelector(
    (state: RootState) => state?.AuthData
  );
  return (
    <>
      <div className="w3-content" style={{ minHeight: "400px" }}>
        <TopBar showBackButton></TopBar>
        <PropsDetailCard></PropsDetailCard>
        <PropServicesList></PropServicesList>
        <Tabbar menuType={authData.data?.token ? "users" : "general"}></Tabbar>
      </div>
    </>
  );
};

export default ExploreProfessionalUserPage;
