import { LoadingOutlined, MailOutlined } from "@ant-design/icons";
import { notification } from "antd";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { sampleApiCall } from "../../apiservice/authService";
import useFormatApiRequest from "../../hooks/formatApiRequest";
import { useAppDispatch } from "../../Redux/reduxCustomHook";
import { NotificationType } from "../../utils/mscType.type";
import "./Vendor-Profile-Comp.css";

const VendorProfileBankAccount = () => {
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [loadApi, setLoadApi] = useState(false);
  const [user, setUser] = useState<any>({});
  const [api, contextHolder] = notification.useNotification();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Use to collect Site Description Change
  const handleInputChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setUser((values: any) => ({ ...values, [name]: value }));
  };

  // Use to Submit Form
  const handleSubmit = (event: any) => {
    event.preventDefault();
    setLoadApi(true);
    setFormLoading(true);
  };

  // A custom hook to format the login Api
  const result = useFormatApiRequest(
    () => sampleApiCall(user),
    loadApi,
    () => {
      setLoadApi(false);
    },
    () => {
      processApi();
    }
  );

  // Process Api
  const processApi = async () => {
    if (result.httpState === "SUCCESS") {
      setFormLoading(false);

      openNotificationWithIcon("info", "", "Bank Account Saved", "#D9FFB5");

      // Handle Success Here
    } else if (result.httpState === "ERROR") {
      setFormLoading(false);
      // Handle Error Here
      openNotificationWithIcon(
        "info",
        "",
        result.data?.response?.data?.errors?.[0] ||
          result.data?.response?.data?.message ||
          result.errorMsg ||
          "Error",
        "#FFC2B7"
      );
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  return (
    <>
      {/* The context is used to hold the notification from ant design */}
      {contextHolder}

      {/* Main Design */}
      <div className="grid p-4">
        <div className="mb-6">
          <p className="font-sans text-lg">Fill In Your Bank Account Details</p>
        </div>

        <form onSubmit={handleSubmit}>
          {/* Bank Name */}
          <div className="relative w-full mb-6">
            <span>Bank Name</span>
            <select
              required
              name="bankName"
              value={user?.bankName}
              onChange={handleInputChange}
              className="pl-4 pr-4 py-2 border rounded-lg w-full h-16 bg-stone-100"
            >
              <option value="">Select Bank</option>
              <option value="Bank of America">Bank of America</option>
              <option value="Chase Bank">Chase Bank</option>
              <option value="Wells Fargo">Wells Fargo</option>
              <option value="Citi Bank">Citi Bank</option>
              <option value="US Bank">US Bank</option>
              {/* Add more options as needed */}
            </select>
          </div>

          {/* Account Number */}
          <div className="relative w-full mb-6">
            <span>Account Number</span>
            <input
              required
              name="accountNumber"
              value={user?.accountNumber}
              onChange={handleInputChange}
              type="text"
              className="pl-4 pr-4 py-2 border rounded-lg w-full h-16 bg-stone-100"
              placeholder="Account Number"
            />
          </div>

          {/* Account Name */}
          <div className="relative w-full mb-6">
            <span>Account Name</span>
            <input
              required
              name="accountName"
              value={user?.accountName}
              onChange={handleInputChange}
              type="text"
              className="pl-4 pr-4 py-2 border rounded-lg w-full h-16 bg-stone-100"
              placeholder="Account Name"
            />
          </div>

          {/* Submit Button */}
          <div className="mb-2">
            <button className="w-full h-16 font-sans bg-stone-900 text-white rounded-xl text-lg">
              {!formLoading ? "Save" : <LoadingOutlined rev={undefined} />}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default VendorProfileBankAccount;
