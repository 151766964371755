import TopBar from "../../../components/LayoutComponent/TopBar/topBar";
import Tabbar from "../../../components/LayoutComponent/TabBar/Tabbar";
import "../explore.css";
import { PropsListComp } from "../../../components/PropComponents/PropsList";
import { useAppSelector } from "../../../Redux/reduxCustomHook";
import { IAuthType } from "../../../apiservice/authService.type";
import { RootState } from "../../../Redux/store";

export const ExploreProfessional = () => {
    const authData: IAuthType = useAppSelector(
      (state: RootState) => state?.AuthData
    );
  return (
    <>
      <div className="w3-content" style={{ minHeight: "400px" }}>
        <TopBar></TopBar>
        <PropsListComp></PropsListComp>
        <Tabbar menuType={authData.data?.token ? "users" : "general"}></Tabbar>
      </div>
    </>
  );
};

export default ExploreProfessional;
