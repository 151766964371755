import TopBar from "../../../components/LayoutComponent/TopBar/topBar";
import Tabbar from "../../../components/LayoutComponent/TabBar/Tabbar";
import VendorGetStartedVideosWrapperComp from "../../../components/vendorProfileComp/VendorGetStartedVideosWrapperComp";
import "../vendorDashboard.css";

export const VendorProfileSettingGettingStartedPage = () => {
  return (
    <>
      <div className="w3-content" style={{ minHeight: "400px" }}>
        <TopBar></TopBar>
        <VendorGetStartedVideosWrapperComp></VendorGetStartedVideosWrapperComp>
        <Tabbar menuType="vendors"></Tabbar>
      </div>
    </>
  );
};

export default VendorProfileSettingGettingStartedPage;
