import VendorProductOrderDetailCard from "./VendorProductOrderDetailCard";
import "./vendorProductsComp.css";

export const VendorProductOrderDetailsComp = () => {
  return (
    <div className="grid p-4">
      <VendorProductOrderDetailCard></VendorProductOrderDetailCard>
    </div>
  );
};

export default VendorProductOrderDetailsComp;
