import "./vendorAuth.css";

export const VendorAuthHeader = () => {
  return (
    <>
      <div className="grid">
        <img
          className="md:hidden"
          src={`${
            process.env.PUBLIC_URL + "/images/vendorAuth/vendorheader.svg"
          }`}
          alt="Image_"
          style={{ width: "100%" }}
        />
      </div>
    </>
  );
};

export default VendorAuthHeader;
