import "./Home-Comp.css";

export const HomeTopCard = () => {
  return (
    <>
      <div className="grid p-4">
        <img
          src={`${process.env.PUBLIC_URL + "/images/homePage/homeBanner.svg"}`}
          alt="Image_"
          style={{ width: "100%" }}
        />
      </div>
    </>
  );
};

export default HomeTopCard;
