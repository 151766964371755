import { EditOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { useState } from "react";
import { IVendorAuthType } from "../../apiservice/vendor-authService.type";
import { useAppSelector } from "../../Redux/reduxCustomHook";
import { RootState } from "../../Redux/store";
import "./Vendor-Profile-Comp.css";
import VendorProfileUpdateComp from "./Vendor-Profile-Update-Comp";

export const VendorProfileDetailsComp = () => {
  const authVendorData: IVendorAuthType = useAppSelector(
    (state: RootState) => state?.VendorAuthData
  );
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="flex items-center p-2 bg-white border border-gray-300 rounded-lg mb-4">
      {/* Profile Details */}
      <div className="ml-2 flex-grow">
        <h2 className="pt-2 pb-2 font-sans text-lg font-semibold">
          {authVendorData?.data?.credentials?.fullName}
        </h2>
        <p className="pt-2 pb-2 font-sans text-sm text-gray-500">
          {authVendorData?.data?.credentials?.email}
        </p>
        <p className="pt-2 pb-8 font-sans text-sm text-gray-500">
          {authVendorData?.data?.credentials?.phoneNumber}
        </p>
      </div>

      {/* Right Edit Icon */}
      <div className="ml-4 flex-shrink-0">
        <button className="text-gray-800 hover:text-green" onClick={showModal}>
          <EditOutlined className="text-2xl" />
        </button>
      </div>

      {/* Edit Profile Modal */}
      <Modal
        title="Update Your Profile"
        visible={isModalVisible}
        onCancel={handleCancel}
        okText="Save"
        cancelText="Cancel"
        footer={[<p style={{ minHeight: "00px" }}></p>]}
      >
        <div style={{ top: "50px", maxHeight: "450px", overflow: "scroll" }}>
          <VendorProfileUpdateComp></VendorProfileUpdateComp>
        </div>
      </Modal>
    </div>
  );
};

export default VendorProfileDetailsComp;
