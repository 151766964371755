import {
  Button,
  Collapse,
  CollapseProps,
  Empty,
  notification,
  Pagination,
  Result,
  Spin,
} from "antd";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { vendorGetServices } from "../../apiservice/vendor-authService";
import { IVendorServiceData } from "../../apiservice/vendor-authService.type";
import useFormatApiRequest from "../../hooks/formatApiRequest";
import { useAppDispatch } from "../../Redux/reduxCustomHook";
import { ILoadState } from "../../utils/loading.utils.";
import { VendorServiceSubCategoryList } from "./Vendor-Service-Sub-Category-List.";
import "./vendoronboarding.css";
type NotificationType = "success" | "info" | "warning" | "error";

type IVendorServiceCategoryList = {
  externalFilter?: any;
  initialDefaultFilter?: any;
  hidePagination?: boolean;
  editMode?: boolean;
};

export const VendorServiceCategoryList: React.FC<
  IVendorServiceCategoryList
> = ({
  externalFilter,
  initialDefaultFilter,
  hidePagination = true,
  editMode = false,
}) => {
  const [serviceCategoriesLoadState, setServiceCategoriesLoadState] =
    useState<ILoadState>("loading");
  const [loadserviceCategoriesData, setLoadServiceCategoriesData] =
    useState(true);
  const [serviceCategoriesDefaultFilter, setServiceCategoriesDefaultFilter] =
    useState(initialDefaultFilter || {});
  const [tableData, setTableData] = useState<CollapseProps["items"]>([]);
  const [api, contextHolder] = notification.useNotification();

  // Pagination Constant/Variables
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const perPage = 10;

  // For Navigator/Redux
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Use Effect to reload API when External Filter has changed
  useEffect(() => {
    if (externalFilter) {
      setServiceCategoriesDefaultFilter({
        ...serviceCategoriesDefaultFilter,
        ...externalFilter,
      });
      setLoadServiceCategoriesData(true);
      setServiceCategoriesLoadState("loading");
    }
  }, [externalFilter]);

  // A custom hook to Load All serviceCategories Details
  const serviceCategoriesDataResult = useFormatApiRequest(
    () => vendorGetServices(serviceCategoriesDefaultFilter),
    loadserviceCategoriesData,
    () => {
      setLoadServiceCategoriesData(false);
    },
    () => {
      processserviceCategoriesResult();
    }
  );

  // Process The Current ServiceCategories Data Result
  const processserviceCategoriesResult = async () => {
    if (serviceCategoriesDataResult.httpState === "SUCCESS") {
      setTableData(
        getServiceList(serviceCategoriesDataResult.data?.data) || []
      );
      setServiceCategoriesLoadState("completed");
      setTotalItems(serviceCategoriesDataResult.data?.meta?.total || 1);
    } else if (serviceCategoriesDataResult.httpState === "ERROR") {
      setServiceCategoriesLoadState("error");
    } else if (serviceCategoriesDataResult.httpState === "LOADING") {
      setServiceCategoriesLoadState("loading");
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  // Use to Control Pagination On Change Event
  const onPageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setServiceCategoriesDefaultFilter({
      ...serviceCategoriesDefaultFilter,
      page: page,
      perPage: pageSize,
    });
    setLoadServiceCategoriesData(true);
  };

  const getServiceList = (services: IVendorServiceData[]) => {
    return services.map((service, index) => {
      return {
        key: index,
        label: service.title,
        children: (
          <p>
            <VendorServiceSubCategoryList
              initialDefaultFilter={{ serviceId: service.id }}
            ></VendorServiceSubCategoryList>
          </p>
        ),
      };
    });
  };

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}
      <div className="grid p-4">
        {/* " Show Loading Indicator" */}
        {serviceCategoriesLoadState === "loading" && (
          <div className="mt-2 mb-2 flex justify-center items-center pt-20 pb-20">
            <Spin size="large" />
          </div>
        )}

        {/* " Show Loading Error" */}
        {serviceCategoriesLoadState === "error" && (
          <div className="mt-2 mb-2">
            <Result
              status="500"
              title={<span className="">Error</span>}
              subTitle={
                <span className="">
                  Sorry, something went wrong, it could be a network Related
                  error
                </span>
              }
              extra={
                <Button
                  onClick={() => setLoadServiceCategoriesData(true)}
                  type="primary"
                >
                  Reload
                </Button>
              }
            />
          </div>
        )}

        {/* " Show No data" */}
        {serviceCategoriesLoadState === "noData" && (
          <div className="mt-2">
            <Empty></Empty>
          </div>
        )}

        {/* " Show Data " */}
        {serviceCategoriesLoadState === "completed" && (
          <div className="mt-2">
            <div>
              {/* Title */}
              <div className="w3-col flex flex-nowrap mb-4">
                <div className=" basis-1/2">
                  <span className="font-sans font-bold text-xl">
                    Select a service
                  </span>
                </div>
                <div className=" basis-1/2 flex items-center justify-end">
                  {!editMode ? (
                    <Link
                      className="font-sans"
                      to={"/vendors/onboarding-availability"}
                    >
                      Continue
                    </Link>
                  ) : null}
                </div>
              </div>

              {/* ServiceCategories */}
              <div className="w3-col">
                <div className="grid grid-cols-1 gap-4">
                  <Collapse items={tableData} />
                </div>
              </div>
              {/* End ServiceCategories */}
            </div>

            {!hidePagination && (
              <div className="mt-2">
                <Pagination
                  current={currentPage || 1}
                  onChange={onPageChange}
                  pageSize={perPage}
                  total={totalItems}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
