import "./userAuth.css";

export const UserAuthHeader = () => {
  return (
    <>
      <div className="grid">
        <img
          className="md:hidden"
          src={`${process.env.PUBLIC_URL + "/images/userAuth/userHeader.svg"}`}
          alt="Image_"
          style={{ width: "100%" }}
        />
      </div>
    </>
  );
};

export default UserAuthHeader;
