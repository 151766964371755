import { MessageOutlined } from "@ant-design/icons";
import { Rate } from "antd";
import "./Prop-Comp.css";

export const PropsDetailCard = () => {
  const selectedProps = {
    title: "Braid",
    imageUrl: `${process.env.PUBLIC_URL + "/images/topService/service1.png"}`,
    images: [
      `${process.env.PUBLIC_URL + "/images/topService/propsImage1.png"}`,
      `${process.env.PUBLIC_URL + "/images/topService/propsImage2.png"}`,
      `${process.env.PUBLIC_URL + "/images/topService/propsImage3.png"}`,
      `${process.env.PUBLIC_URL + "/images/topService/propsImage4.png"}`,
    ],
  };
  return (
    <>
      <div className="container grid p-4">
        {/* Cards */}
        <div className="flex flex-col p-2 w-full shadow-lg shadow-stone-100 bg-white border border-gray-200 rounded-xl">
          <div className="p-1 flex flex-row">
            <div className="basis-20 p-2 ">
              <img
                className=""
                alt="Props"
                src={selectedProps.imageUrl}
                style={{
                  maxWidth: "100%",
                  maxHeight: "100px",
                }}
              />
            </div>

            <div className="flex-grow p-2 ">
              <h2 className="font-semibold font-sans text-xl mb-2">
                Samarthan Pyna
              </h2>
              <p className="font-light font-sans text-lg text-grey-700  mb-2">
                Payne beauty salon
              </p>
              <p className="font-light font-sans text-lg  mb-1">
                <Rate defaultValue={3} /> &nbsp;
                <span>(4.6)</span>
              </p>
              {/* <Flex gap="middle" vertical>
                                <Flex gap="middle">
                                  <Rate defaultValue={3} />
                                  <span>(4.6)</span>
                                </Flex>
                              </Flex> */}
            </div>

            <div className="basis-12  p-2 ">
              <MessageOutlined style={{ zoom: "1.5" }} />
            </div>
          </div>

          <div className="p-1 flex flex-row">
            {(selectedProps?.images || []).map((image, index) => (
              <div className="basis-1/4 p-2">
                <img
                  className=""
                  alt="Props"
                  src={image}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100px",
                  }}
                />
              </div>
            ))}
          </div>
        </div>
        {/* End  Cards */}
      </div>
    </>
  );
};

export default PropsDetailCard;
