import React from "react";
import "./Vendor-Profile-Comp.css";

const VendorProfileHelpComp = () => {
  return (
    <div className="min-h-screen bg-gray-100 p-4">
      <div className="bg-white rounded-lg shadow-md p-6">
        <h1 className="text-2xl font-bold mb-4 text-center">Help & Support</h1>
        <p className="mb-4">
          Welcome to the Help and Support page. Here you can find answers to the
          most frequently asked questions and get support for any issues you
          might be facing.
        </p>

        <div className="mb-4">
          <h2 className="text-xl font-semibold mb-2">FAQs</h2>
          <ul className="list-disc pl-5">
            <li className="mb-2">
              <strong>How do I reset my password?</strong>
              <p>
                To reset your password, go to the settings page and select
                "Change Password". Follow the prompts to reset your password.
              </p>
            </li>
            <li className="mb-2">
              <strong>How can I contact support?</strong>
              <p>
                You can contact our support team via the "Contact Us" section at
                the bottom of this page.
              </p>
            </li>
            <li className="mb-2">
              <strong>Where can I find the user manual?</strong>
              <p>
                The user manual can be accessed from the "User Manual" section
                in the app menu.
              </p>
            </li>
          </ul>
        </div>

        <div className="mb-4">
          <h2 className="text-xl font-semibold mb-2">Contact Us</h2>
          <p>
            If you need further assistance, feel free to contact our support
            team:
          </p>
          <ul className="list-none pl-0">
            <li className="mb-2">
              <strong>Email:</strong> support@example.com
            </li>
            <li className="mb-2">
              <strong>Phone:</strong> +1 234 567 890
            </li>
            <li className="mb-2">
              <strong>Address:</strong> 1234 Example St, City, Country
            </li>
          </ul>
        </div>

        <div className="mt-6 text-center">
          <button className="bg-black text-white px-4 py-2 rounded-lg">
            Send us a Message
          </button>
        </div>
      </div>
    </div>
  );
};

export default VendorProfileHelpComp;
