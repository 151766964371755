import "../../App.css";
import { Routes, Route } from "react-router-dom";
import Nopage from "../Nopage/Nopage";
import VendorSignin from "./vendorSignin/vendorSignin";
import VendorSignUp from "./vendorSignUp/vendorSignUp";
import VendorForgetPassword from "./vendorForgetPassword/vendorForgetPassword";
import VendorSetNewPassword from "./vendorSetNewPassword/vendorSetNewPassword";
import VendorVerifyEmail from "./vendorVerifyEmail/vendorVerifyEmail";

const VendorAuthRoutes = () => {
  return (
    <Routes>
      <Route index element={<VendorSignin />} />
      <Route path="/signin" element={<VendorSignin />} />
      <Route path="/signup" element={<VendorSignUp />} />
      <Route path="/forget-password" element={<VendorForgetPassword />} />
      <Route path="/set-new-password" element={<VendorSetNewPassword />} />
      <Route path="/verify-email" element={<VendorVerifyEmail />} />
      <Route path="*" element={<Nopage />} />
    </Routes>
  );
};

export default VendorAuthRoutes;
