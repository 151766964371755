import React from "react";
import { useNavigate } from "react-router-dom";
import {
  UserOutlined,
  PlayCircleOutlined,
  LockOutlined,
  QuestionCircleOutlined,
  LogoutOutlined,
  BankOutlined,
  ShopOutlined,
  TransactionOutlined,
} from "@ant-design/icons";
import "./Vendor-Profile-Comp.css";
import { useAppSelector } from "../../Redux/reduxCustomHook";
import { RootState } from "../../Redux/store";
import { IVendorAuthType } from "../../apiservice/vendor-authService.type";

export const VendorProfileSettingListComp = () => {
  const navigate = useNavigate();

  const menuItems = [
    {
      icon: <UserOutlined />,
      text: "Profile And Business",
      path: "/vendors/profile",
    },
    { icon: <ShopOutlined />, text: "Products", path: "/vendors/products" },
    {
      icon: <ShopOutlined />,
      text: "Orders",
      path: "/vendors/products/orders",
    },
    {
      icon: <BankOutlined />,
      text: "Manage Bank Account",
      path: "/vendors/profile-settings-bank-details",
    },
    {
      icon: <TransactionOutlined />,
      text: "Transactions",
      path: "/vendors/profile-settings-transactions",
    },
    {
      icon: <PlayCircleOutlined />,
      text: "Getting Started",
      path: "/vendors/profile-settings-getting-started",
    },
    {
      icon: <LockOutlined />,
      text: "Change Password",
      path: "/vendors/profile-settings-change-password",
    },
    {
      icon: <QuestionCircleOutlined />,
      text: "Help Center",
      path: "/vendors/profile-settings-help-center",
    },
    { icon: <LogoutOutlined />, text: "Logout", path: "/vendors/logout" },
  ];

  const handleNavigation = (path) => {
    navigate(path);
  };

  const authVendorData: IVendorAuthType = useAppSelector(
    (state: RootState) => state?.VendorAuthData
  );

  return (
    <div className="grid p-4">
      {/* Account Settings */}
      <h2 className="pt-2 pb-2 font-sans text-lg font-semibold">
        Account Settings
      </h2>
      <p className="pt-2 pb-8 font-sans text-sm">
        {authVendorData?.data?.credentials?.fullName}
      </p>

      {menuItems.map((item, index) => (
        <div
          key={index}
          className="flex items-center p-2 pt-4 pb-4 border-t hover:bg-gray-100 cursor-pointer font-sans"
          onClick={() => handleNavigation(item.path)}
        >
          <div className="text-xl text-gray-600">{item.icon}</div>
          <span className="pl-4 text-md font-medium text-gray-900">
            {item.text}
          </span>
        </div>
      ))}
    </div>
  );
};

export default VendorProfileSettingListComp;
