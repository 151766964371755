import "../../App.css";
import { Routes, Route } from "react-router-dom";
import { HomePage } from "./Home/HomePage";
import Nopage from "../Nopage/Nopage";
import LandingPagesLayout from "./Layout/Layout";
import { ExploreProfessional } from "./ExploreProfessional/ExploreProfessional";
import NotificationsPage from "./Notifications/Notifications";
import { ExploreProfessionalUserPage } from "./ExploreProfessionalUserPage/ExploreProfessionalUserPage";
import { ExploreProfessionalCalenderPage } from "./ExploreProfessionalCalenderPage/ExploreProfessionalCalenderPage";
import ExploreProfessionalTimePage from "./ExploreProfessionalTimePage/ExploreProfessionalTimePage";
import ExploreProfessionalBookingPage from "./ExploreProfessionalBookingPage/ExploreProfessionalBookingPage";
import StartPage from "./Start/Start";

const ExplorePagesRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<LandingPagesLayout />}>
        <Route index element={<StartPage />} />
        <Route path="/explore-home" element={<HomePage />} />
        <Route
          path="/explore-professionals"
          element={<ExploreProfessional />}
        />
        <Route path="/explore-professionals-search" element={<Nopage />} />
        <Route
          path="/explore-professionals/:id/:userName"
          element={<ExploreProfessionalUserPage />}
        />
        <Route
          path="/explore-professionals/:id/calender"
          element={<ExploreProfessionalCalenderPage />}
        />
        <Route
          path="/explore-professionals/:id/calender/time"
          element={<ExploreProfessionalTimePage />}
        />
        <Route
          path="/explore-professionals/:id/calender/booking"
          element={<ExploreProfessionalBookingPage />}
        />
        <Route path="/notifications" element={<NotificationsPage />} />
        <Route path="*" element={<Nopage />} />
      </Route>
    </Routes>
  );
};

export default ExplorePagesRoute;
