import React, { useState } from "react";
import { EditOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import "./Vendor-Profile-Comp.css";
import { vendorGetSavedSubServices } from "../../apiservice/vendor-authService";
import useFormatApiRequest from "../../hooks/formatApiRequest";
import { ILoadState } from "../../utils/loading.utils.";
import { IVendorMyServiceData } from "../../apiservice/vendor-authService.type";
import VendorOnboardingServicesFormComp from "../vendorOnboardingComponents/Vendor-onboarding-Service-Form-comp";
import { useNavigate } from "react-router-dom";

const VendorProfileBusinessServicesComp = () => {
  const navigate = useNavigate();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [serviceSubCategoriesLoadState, setServiceSubCategoriesLoadState] =
    useState<ILoadState>("loading");
  const [loadserviceSubCategoriesData, setLoadServiceSubCategoriesData] =
    useState(true);
  const [subServiceIndex, setSubServiceIndex] = useState<number>(-1);
  const [tableData, setTableData] = useState<IVendorMyServiceData[]>([]);

  const handleShowModal = (index) => {
    setSubServiceIndex(index);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  // A custom hook to Load All serviceSubCategories Details
  const serviceSubCategoriesDataResult = useFormatApiRequest(
    () => vendorGetSavedSubServices({}),
    loadserviceSubCategoriesData,
    () => {
      setLoadServiceSubCategoriesData(false);
    },
    () => {
      processserviceSubCategoriesResult();
    }
  );

  // Process The Current ServiceSubCategories Data Result
  const processserviceSubCategoriesResult = async () => {
    if (serviceSubCategoriesDataResult.httpState === "SUCCESS") {
      setTableData(serviceSubCategoriesDataResult.data?.data || []);
      setServiceSubCategoriesLoadState("completed");
    } else if (serviceSubCategoriesDataResult.httpState === "ERROR") {
      setServiceSubCategoriesLoadState("error");
    } else if (serviceSubCategoriesDataResult.httpState === "LOADING") {
      setServiceSubCategoriesLoadState("loading");
    }
  };

  return (
    <div className=" p-2 bg-white border border-gray-300 rounded-lg mb-4">
      <div className="flex items-center">
        {/* Title */}
        <div className="ml-2 flex-grow">
          <h2 className="pt-2 pb-2 font-sans text-lg font-semibold">
            My Services
          </h2>
        </div>

        {/* Right Edit Icon */}
        <div className="ml-4 flex-shrink-0">
          <button
            onClick={() => {
              handleNavigation("/vendors/profile-add-services");
            }}
            className="text-gray-800 hover:text-green"
          >
            <PlusCircleOutlined className="text-2xl" />
          </button>
        </div>
      </div>

      <div className="flex flex-wrap">
        <div className="grid grid-cols-1 gap-y-2 w-full">
          {tableData.map((serviceSubCategories, index) => (
            <>
              <div key={index}>
                {/* Cards */}
                <div className="flex items-center justify-start p-1 w-full bg-white border border-gray-200 rounded-xl">
                  <div className="p-2 basis-3/4">
                    <span>{serviceSubCategories?.subService?.title}</span>
                  </div>

                  <div className="basis-1/4 p-2 flex items-center justify-end">
                    <span
                      onClick={() => {
                        handleShowModal(index);
                      }}
                      className="font-medium rounded-lg text-xs px-5 py-2.5 me-2 mb-2 text-white bg-black hover:bg-green-500"
                    >
                      <EditOutlined />
                    </span>
                  </div>
                </div>
                {/* End  Cards */}
              </div>
            </>
          ))}
        </div>
      </div>

      {/* Edit Profile Modal */}
      <Modal
        title="Add Services"
        visible={isModalVisible}
        onCancel={handleCancel}
        okText="Save"
        cancelText="Cancel"
        footer={[<p style={{ minHeight: "00px" }}></p>]}
      >
        <div style={{ maxHeight: "400px", overflow: "scroll" }}>
          <VendorOnboardingServicesFormComp
            title={tableData[subServiceIndex]?.subService?.title}
            subServiceId={tableData[subServiceIndex]?.subService?.id}
          ></VendorOnboardingServicesFormComp>
        </div>
      </Modal>
    </div>
  );
};

export default VendorProfileBusinessServicesComp;
