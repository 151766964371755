import TopBar from "../../../components/LayoutComponent/TopBar/topBar";
import Tabbar from "../../../components/LayoutComponent/TabBar/Tabbar";
import "../explore.css";
import { PropsDetailCard } from "../../../components/PropComponents/PropsDetailsCard";
import PropsCalender from "../../../components/PropComponents/PropsCalender";
import { PropsCalenderTime } from "../../../components/PropComponents/PropCalenderTime";
import { useAppSelector } from "../../../Redux/reduxCustomHook";
import { IAuthType } from "../../../apiservice/authService.type";
import { RootState } from "../../../Redux/store";

export const ExploreProfessionalCalenderPage = () => {
    const authData: IAuthType = useAppSelector(
      (state: RootState) => state?.AuthData
    );
  return (
    <>
      <div className="w3-content" style={{ minHeight: "400px" }}>
        <TopBar showBackButton></TopBar>
        <PropsDetailCard></PropsDetailCard>
        <PropsCalenderTime></PropsCalenderTime>
        {/* <PropsCalender></PropsCalender> */}
        <Tabbar menuType={authData.data?.token ? "users" : "general"}></Tabbar>
      </div>
    </>
  );
};

export default ExploreProfessionalCalenderPage;
