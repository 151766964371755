import React from "react";
import "./vendorProductsComp.css";

const VendorOrderOrderDetailCard = () => {
  return (
    <>
      <div className="flex flex-col p-2 bg-white border border-gray-300 rounded-lg mb-4">
        <div className="flex items-center">
          {/* Title */}
          <div className="flex-grow">
            <h2 className="pt-2 pb-2 font-sans text-lg font-semibold">
              Order Details
            </h2>
          </div>
        </div>

        {/* Picture */}
        <div className="flex">
          <img
            src={`https://via.placeholder.com/400`}
            alt="Profile"
            className="w-full h-full object-cover rounded-lg"
            style={{ height: "120px", width: "120px" }}
          />
        </div>

        {/* Order Information  */}
        <div className="flex flex-col">
          <h2 className="text-xl font-semibold mt-4">Order Name</h2>
          <p className="text-gray-500 mt-4">
            {/* Price Field */}
            <strong>Price:</strong> $100
          </p>
          <p className="text-gray-500 mt-4">
            {/* Shipping Fees Field */}
            <strong>Shipping Fees:</strong> $10
          </p>

          <p className="text-gray-500 mt-4">
            {/*   Quantity  */}
            <strong>Quantity :</strong> 5
          </p>

          <p className="text-gray-500 mt-4">
            {/* Total */}
            <strong>Total:</strong> $180
          </p>
        </div>
      </div>

      <div className="flex flex-col p-2 bg-white border border-gray-300 rounded-lg mb-4">
        <div className="flex items-center">
          {/* Title */}
          <div className="flex-grow">
            <h2 className="pt-2 pb-2 font-sans text-lg font-semibold">
              Contact Information
            </h2>
          </div>
        </div>

        {/* Order Information  */}
        <div className="flex flex-col">
          <h2 className="text-xl font-semibold mt-4">Emmanuel Soligbo</h2>
          <p className="text-gray-500 mt-4">
            {/* Phone Number */}
            <strong>Phone Number</strong> 091481199229
          </p>
          <p className="text-gray-500 mt-4">
            {/* Address */}
            <strong>Address:</strong> 21 Universal Street
          </p>
        </div>
      </div>

      <div className="flex flex-col p-2 bg-white border border-gray-300 rounded-lg mb-4">
        <div className="flex items-center">
          {/* Title */}
          <div className="flex-grow">
            <h2 className="pt-2 pb-2 font-sans text-lg font-semibold">
              Actions
            </h2>
          </div>
        </div>

      
      </div>
    </>
  );
};

export default VendorOrderOrderDetailCard;
