import "../userDashboard.css";
import UserChatMessageWrapper from "../../../components/userMessagesComp/userChatMessageDetailCardWrapper";

export const UserMessagePage = () => {

  return (
    <>
      <div className="w3-content" style={{ minHeight: "400px" }}>
        <UserChatMessageWrapper></UserChatMessageWrapper>
      </div>
    </>
  );
};

export default UserMessagePage;
