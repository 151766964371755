import { LoadingOutlined } from "@ant-design/icons";
import { Button, Empty, notification, Result, Spin } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { sampleApiCall } from "../../apiservice/authService";
import { IAuthType } from "../../apiservice/authService.type";
import { vendorGetAvailability } from "../../apiservice/vendor-authService";
import { IVendorAuthType } from "../../apiservice/vendor-authService.type";
import useFormatApiRequest from "../../hooks/formatApiRequest";
import useAuth from "../../hooks/useAuth";
import { useAppDispatch, useAppSelector } from "../../Redux/reduxCustomHook";
import { RootState } from "../../Redux/store";
import { ILoadState } from "../../utils/loading.utils.";
import { NotificationType } from "../../utils/mscType.type";
import "./vendoronboarding.css";

type IVendorOnboardingAvailabilityFormComp = {
  editMode?: boolean;
};

const VendorOnboardingAvailabilityFormComp: React.FC<
  IVendorOnboardingAvailabilityFormComp
> = ({ editMode = false }) => {
  const [availabilityLoadState, setAvailabilityLoadState] =
    useState<ILoadState>("loading");
  const [loadavailabilityData, setLoadAvailabilityData] = useState(true);
  const [tableData, setTableData] = useState<any[]>([]);

  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [loadApi, setLoadApi] = useState(false);
  const [user, setUser] = useState<any>({});
  const [api, contextHolder] = notification.useNotification();

  const [loadAuth, setLoadAuth] = useState(true);

  const authVendorData: IVendorAuthType = useAppSelector(
    (state: RootState) => state?.VendorAuthData
  );

  // Use to refresh the Auth State
  const authState = useAuth(loadAuth, () => {
    setLoadAuth(false);
  });

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Use to collect Site Description Change
  const handleAddBreaks = (name, value) => {
    setUser((values: any) => ({ ...values, [name]: value }));
  };

  const handleInputChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setUser((values: any) => ({ ...values, [name]: value }));
  };

  const handleCheckChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.checked;
    setUser((values: any) => ({ ...values, [name]: value }));
  };

  // Use to Submit Form
  const handleSubmit = (event: any) => {
    event.preventDefault();
    setLoadApi(true);
    setFormLoading(true);
  };

  // A custom hook to format the login Api
  const result = useFormatApiRequest(
    () => sampleApiCall(user),
    loadApi,
    () => {
      setLoadApi(false);
    },
    () => {
      processApi();
    }
  );

  // Process Api
  const processApi = async () => {
    if (result.httpState === "SUCCESS") {
      setFormLoading(false);
      const signinResult: IAuthType = result.data;

      setTimeout(() => {
        if (!editMode) {
          navigate("/vendors/onboarding-photos");
        } else {
          setLoadAuth(true);
        }
      }, 1500);
      openNotificationWithIcon(
        "info",
        "",
        "Your Availability have been Saved",
        "#D9FFB5"
      );

      // Handle Success Here
    } else if (result.httpState === "ERROR") {
      setFormLoading(false);
      //Handle Error Here
      openNotificationWithIcon(
        "info",
        "",
        result.data?.response?.data?.errors?.[0] ||
          result.data?.response?.data?.message ||
          result.errorMsg ||
          "Login Error",
        "#FFC2B7"
      );
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  // Days of the week

  const daysOfTheWeek = [
    { day: "Monday" },
    { day: "Tuesday" },
    { day: "Wednesday" },
    { day: "Thursday" },
    { day: "Friday" },
    { day: "Saturday" },
    { day: "Sunday" },
  ];

  // Time Options for Select
  const generateTimeOptions = () => {
    const options: any[] = [];
    const startHour = 8;
    const endHour = 20;
    const interval = 30;

    for (let hour = startHour; hour <= endHour; hour++) {
      for (let minutes = 0; minutes < 60; minutes += interval) {
        const time = new Date(0, 0, 0, hour, minutes);
        const formattedTime = time.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        });
        options.push(formattedTime);
      }
    }
    return options;
  };

  // A custom hook to Load All availability Details
  const availabilityDataResult = useFormatApiRequest(
    () => vendorGetAvailability(),
    loadavailabilityData,
    () => {
      setLoadAvailabilityData(false);
    },
    () => {
      processavailabilityResult();
    }
  );

  // Process The Current Availability Data Result
  const processavailabilityResult = async () => {
    if (availabilityDataResult.httpState === "SUCCESS") {
      setTableData(
        availabilityDataResult.data?.data || [
          { day: "Monday" },
          { day: "Tuesday" },
          { day: "Wednesday" },
          { day: "Thursday" },
          { day: "Friday" },
          { day: "Saturday" },
          { day: "Sunday" },
        ]
      );
      setAvailabilityLoadState("completed");
    } else if (availabilityDataResult.httpState === "ERROR") {
      setAvailabilityLoadState("error");
    } else if (availabilityDataResult.httpState === "LOADING") {
      setAvailabilityLoadState("loading");
    }
  };

  const timeOptions = generateTimeOptions();

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}

      {/* "Main Desgin" */}
      <div className="grid p-4">
        <div className="flex mt-4 mb-4">
          <p>
            Horem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
            vulputate libero et velit interdum, ac aliquet odio mattis.
          </p>
        </div>

        <form onSubmit={handleSubmit}>
          {/* "Check Box" */}
          {daysOfTheWeek.map((weekDay, index) => (
            <div key={index} className="pt-2 pb-6 border-b mb-4  border-black">
              {/* "Check Options " */}
              <div className="flex">
                <div className="basis-5/6 text-lg font-semibold font-sans capitalize">
                  {weekDay.day}
                </div>
                <div className="flex items-center justify-end basis-1/6">
                  <label className="inline-flex items-center cursor-pointer">
                    <input
                      name={"check" + weekDay.day}
                      onChange={handleCheckChange}
                      type="checkbox"
                      className="sr-only peer"
                      checked={user?.["check" + weekDay.day]}
                    />
                    <div className="relative w-11 h-6 bg-gray-400 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300  rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300"></span>
                  </label>
                </div>
              </div>

              {user?.["check" + weekDay.day] && (
                <>
                  {/* "Available Time" */}
                  <div className="grid grid-cols-2 gap-2 gap-x-6 mt-6 mb-2">
                    <div>
                      <span className="text-sm"> Start </span>
                      <select
                        required
                        name={"startTime" + weekDay.day}
                        value={user?.["startTime" + weekDay.day]}
                        onChange={handleInputChange}
                        className="pl-4 pr-4 py-2 border rounded-lg w-full h-16 bg-stone-100"
                      >
                        <option value="" disabled>
                          Select Start Time
                        </option>
                        {timeOptions.map((time, index) => (
                          <option key={index} value={time}>
                            {time}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <span> End </span>
                      <select
                        required
                        name={"endTime" + weekDay.day}
                        value={user?.["endTime" + weekDay.day]}
                        onChange={handleInputChange}
                        className="pl-4 pr-4 py-2 border rounded-lg w-full h-16 bg-stone-100"
                      >
                        <option value="" disabled>
                          Select Start Time
                        </option>
                        {timeOptions.map((time, index) => (
                          <option key={index} value={time}>
                            {time}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  {/* "Add Breaks" */}
                  <div className="mt-6 mb-2 flex justify-start">
                    <span
                      onClick={() => {
                        handleAddBreaks(
                          "break" + weekDay.day,
                          user?.["break" + weekDay.day] ? false : true
                        );
                      }}
                    >
                      {user?.["break" + weekDay.day]
                        ? "Remove Break"
                        : "Add Break"}
                    </span>
                  </div>

                  {/* "Break Time" */}
                  {user?.["break" + weekDay.day] && (
                    <div className="grid grid-cols-2 gap-2 gap-x-6 mt-6 mb-2">
                      <div>
                        <span className="text-sm"> Break Start </span>
                        <select
                          required
                          name={"breakStartTime" + weekDay.day}
                          value={user?.["breakStartTime" + weekDay.day]}
                          onChange={handleInputChange}
                          className="pl-4 pr-4 py-2 border rounded-lg w-full h-16 bg-stone-100"
                        >
                          <option value="" disabled>
                            Select Start Time
                          </option>
                          {timeOptions.map((time, index) => (
                            <option key={index} value={time}>
                              {time}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div>
                        <span> Break End </span>
                        <select
                          required
                          name={"breakEndTime" + weekDay.day}
                          value={user?.["breakEndTime" + weekDay.day]}
                          onChange={handleInputChange}
                          className="pl-4 pr-4 py-2 border rounded-lg w-full h-16 bg-stone-100"
                        >
                          <option value="" disabled>
                            Select Start Time
                          </option>
                          {timeOptions.map((time, index) => (
                            <option key={index} value={time}>
                              {time}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          ))}

          {/* Save Button */}
          <div className="mb-2">
            <button
              type="submit"
              className="w-full h-16  font-sans bg-stone-900 text-white rounded-xl text-lg"
            >
              {!formLoading ? "Next" : <LoadingOutlined rev={undefined} />}
            </button>
          </div>
        </form>
      </div>

      <div className="grid p-4">
        {/* " Show Loading Indicator" */}
        {availabilityLoadState === "loading" && (
          <div className="mt-2 mb-2 flex justify-center items-center pt-20 pb-20">
            <Spin size="large" />
          </div>
        )}

        {/* " Show Loading Error" */}
        {availabilityLoadState === "error" && (
          <div className="mt-2 mb-2">
            <Result
              status="500"
              title={<span className="">Error</span>}
              subTitle={
                <span className="">
                  Sorry, something went wrong, it could be a network Related
                  error
                </span>
              }
              extra={
                <Button
                  onClick={() => setLoadAvailabilityData(true)}
                  type="primary"
                >
                  Reload
                </Button>
              }
            />
          </div>
        )}

        {/* " Show No data" */}
        {availabilityLoadState === "noData" && (
          <div className="mt-2">
            <Empty></Empty>
          </div>
        )}

        {/* " Show Data " */}
        {availabilityLoadState === "completed" && <></>}
      </div>
    </>
  );
};

export default VendorOnboardingAvailabilityFormComp;
