import {
  LoadingOutlined,
  LockOutlined,
  MailOutlined,
  PhoneOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { notification } from "antd";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { sampleApiCall } from "../../../apiservice/authService";
import { IAuthType } from "../../../apiservice/authService.type";
import useFormatApiRequest from "../../../hooks/formatApiRequest";
import { VENDOR_TOKEN_KEY, VENDOR_AUTH_DATA_KEY } from "../../../hooks/useAuth";
import { useAppDispatch } from "../../../Redux/reduxCustomHook";
import { storePlainString, storeJSON } from "../../../utils/localStorage";
import { NotificationType } from "../../../utils/mscType.type";
import VendorAuthHeader from "../vendorAuthHeader";
import "../vendorAuth.css";
import { vendorAuthSignUp } from "../../../apiservice/vendor-authService";

const VendorSignUp = () => {
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [loadApi, setLoadApi] = useState(false);
  const [user, setUser] = useState<any>({});
  const [api, contextHolder] = notification.useNotification();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Use to collect Site Description Change
  const handleInputChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setUser((values: any) => ({ ...values, [name]: value }));
  };

  // Use to Submit Form
  const handleSubmit = (event: any) => {
    event.preventDefault();
    setLoadApi(true);
    setFormLoading(true);
  };

  // A custom hook to format the login Api
  const result = useFormatApiRequest(
    () => vendorAuthSignUp(user),
    loadApi,
    () => {
      setLoadApi(false);
    },
    () => {
      processApi();
    }
  );

  // Process Api
  const processApi = async () => {
    if (result.httpState === "SUCCESS") {
      setFormLoading(false);
      const signinResult: IAuthType = result.data;
      storePlainString(VENDOR_TOKEN_KEY, signinResult?.data?.token || "");
      storeJSON(VENDOR_AUTH_DATA_KEY, signinResult);
      dispatch({ type: "VENDOR_AUTH_ADD_DATA", payload: signinResult });

      setTimeout(() => {
        // Handle Success Here
        navigate("/vendor-auth/verify-email");
      }, 1500);
      openNotificationWithIcon(
        "info",
        "",
        "Your Business Have being created",
        "#D9FFB5"
      );

      // Handle Success Here
    } else if (result.httpState === "ERROR") {
      setFormLoading(false);
      //Handle Error Here
      openNotificationWithIcon(
        "info",
        "",
        result.data?.response?.data?.errors?.[0] ||
          result.data?.response?.data?.message ||
          result.errorMsg ||
          "Login Error",
        "#FFC2B7"
      );
     
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}

      {/* "Main Desgin" */}
      <div className="w3-content" style={{ minHeight: "400px" }}>
        <VendorAuthHeader></VendorAuthHeader>

        <div className="grid p-4">
          <div className="mb-6">
            <p className=" font-semibold font-sans text-xl">
              {" "}
              Set Up Your Business.{" "}
            </p>
          </div>

          <form onSubmit={handleSubmit}>
            {/* Email Address */}
            <div className="relative w-full mb-6">
              <input
                required
                name="email"
                value={user?.email}
                onChange={handleInputChange}
                type="email"
                className="pl-10 pr-4 py-2 border rounded-lg w-full  h-16 bg-stone-100"
                placeholder="Email Address"
              />
              <div
                className="absolute inset-y-0 left-0 pl-3  
                    flex items-center  
                    pointer-events-none"
              >
                <MailOutlined />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-2">
              {/* First Name */}
              <div>
                <div className="relative w-full mb-6">
                  <input
                    required
                    name="firstName"
                    value={user?.firstName}
                    onChange={handleInputChange}
                    type="text"
                    className="pl-10 pr-4 py-2 border rounded-lg w-full  h-16 bg-stone-100"
                    placeholder="First Name"
                  />
                  <div
                    className="absolute inset-y-0 left-0 pl-3  
                    flex items-center  
                    pointer-events-none"
                  >
                    <UserOutlined />
                  </div>
                </div>
              </div>

              {/* Last Name */}
              <div>
                <div className="relative w-full mb-6">
                  <input
                    required
                    name="lastName"
                    value={user?.lastName}
                    onChange={handleInputChange}
                    type="text"
                    className="pl-10 pr-4 py-2 border rounded-lg w-full  h-16 bg-stone-100"
                    placeholder="Last Name"
                  />
                  <div
                    className="absolute inset-y-0 left-0 pl-3  
                    flex items-center  
                    pointer-events-none"
                  >
                    <UserOutlined />
                  </div>
                </div>
              </div>
            </div>

            {/* Phone Number */}
            <div className="relative w-full mb-6">
              <input
                required
                name="phoneNumber"
                value={user?.phoneNumber}
                onChange={handleInputChange}
                type="tel"
                className="pl-10 pr-4 py-2 border rounded-lg w-full  h-16 bg-stone-100"
                placeholder="Phone Number"
              />
              <div
                className="absolute inset-y-0 left-0 pl-3  
                    flex items-center  
                    pointer-events-none"
              >
                <PhoneOutlined />
              </div>
            </div>

            {/* Password */}
            <div className="relative w-full mb-6">
              <input
                required
                name="password"
                value={user?.password}
                onChange={handleInputChange}
                type="password"
                className="pl-10 pr-4 py-2 border rounded-lg w-full h-16 bg-stone-100"
                placeholder="Password"
              />
              <div
                className="absolute inset-y-0 left-0 pl-3  
                    flex items-center  
                    pointer-events-none"
              >
                <LockOutlined />
              </div>
            </div>

            {/* Confirm Password */}
            <div className="relative w-full mb-6">
              <input
                required
                name="confirmPassword"
                value={user?.confirmPassword}
                onChange={handleInputChange}
                type="password"
                className="pl-10 pr-4 py-2 border rounded-lg w-full h-16 bg-stone-100"
                placeholder="Confirm Password"
              />
              <div
                className="absolute inset-y-0 left-0 pl-3  
                    flex items-center  
                    pointer-events-none"
              >
                <LockOutlined />
              </div>
            </div>

            {/* Sign Up Button */}
            <div className="mb-2">
              <button className="w-full h-16  font-sans bg-stone-900 text-white rounded-xl text-lg">
                {!formLoading ? (
                  "Continue"
                ) : (
                  <LoadingOutlined rev={undefined} />
                )}
              </button>
            </div>

            {/* UnderLine */}
            <div className="mb-6 border-b-2 border-black">
              <br />
            </div>

            {/* Go To Login */}
            <div className="mb-2">
              <Link to={"/vendor-auth/signin"}>
                <button className="w-full font-sans h-16 border  border-stone-900 text-black rounded-xl text-lg">
                  Login to My Business
                </button>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default VendorSignUp;
