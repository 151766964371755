import VendorProductDetailCard from "./VendorProductDetailCard";
import VendorProductImagesComp from "./VendorProductImagesComp";
import "./vendorProductsComp.css";

export const VendorProductDetailsComp = () => {
  return <div className="grid p-4">

    <VendorProductDetailCard></VendorProductDetailCard>
    <VendorProductImagesComp></VendorProductImagesComp>

  </div>;
};

export default VendorProductDetailsComp;
