import "../../App.css";
import { Routes, Route } from "react-router-dom";
import Nopage from "../Nopage/Nopage";
import UserPagesLayout from "./Layout/Layout";
import UserHomePage from "./UserHome/UserHomePage";
import UserProfilePage from "./UserProfile/UserProfile";
import UserChatListPage from "./UserMessage/UserChatList";
import UserMessagePage from "./UserMessage/UserMessage";
import UserLogout from "./UserProfile/UserLogout";

const UserDashboardPagesRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<UserPagesLayout />}>
        <Route index element={<UserHomePage />} />
        <Route path="/home" element={<UserHomePage />} />
        <Route path="/profile" element={<UserProfilePage />} />
        <Route path="/chat" element={<UserChatListPage />} />
        <Route path="/chat-message/:id" element={<UserMessagePage />} />
        <Route path="/logout" element={<UserLogout />} />
        <Route path="*" element={<Nopage />} />
      </Route>
    </Routes>
  );
};

export default UserDashboardPagesRoute;
