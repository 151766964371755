import { LoadingOutlined } from "@ant-design/icons";
import { notification } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { sampleApiCall } from "../../apiservice/authService";
import {
  vendorAddService,
  vendorGetSavedSubServices,
} from "../../apiservice/vendor-authService";
import { IVendorAuthType } from "../../apiservice/vendor-authService.type";
import useFormatApiRequest from "../../hooks/formatApiRequest";
import useAuth from "../../hooks/useAuth";
import { useAppDispatch, useAppSelector } from "../../Redux/reduxCustomHook";
import { RootState } from "../../Redux/store";
import { ILoadState } from "../../utils/loading.utils.";
import { NotificationType } from "../../utils/mscType.type";
import "./vendoronboarding.css";

type IVendorOnboardingServicesFormComp = {
  subServiceId?: number | string;
  title?: string;
};

const VendorOnboardingServicesFormComp: React.FC<
  IVendorOnboardingServicesFormComp
> = ({ subServiceId, title }) => {
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [loadApi, setLoadApi] = useState(false);
  const [payLoad, setPayLoad] = useState<any>({});
  const [api, contextHolder] = notification.useNotification();

  const [loadAuth, setLoadAuth] = useState(true);

  const authVendorData: IVendorAuthType = useAppSelector(
    (state: RootState) => state?.VendorAuthData
  );

  const [serviceSubCategoriesLoadState, setServiceSubCategoriesLoadState] =
    useState<ILoadState>("loading");
  const [loadserviceSubCategoriesData, setLoadServiceSubCategoriesData] =
    useState(true);

  // Use to refresh the Auth State
  const authState = useAuth(loadAuth, () => {
    setLoadAuth(false);
  });

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Use to collect Site Description Change
  const handleInputChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setPayLoad((values: any) => ({ ...values, [name]: value }));
  };

  // Use to Submit Form
  const handleSubmit = (event: any) => {
    event.preventDefault();
    setLoadApi(true);
    setFormLoading(true);
  };

  // A custom hook to format the login Api
  const result = useFormatApiRequest(
    () => vendorAddService({ ...payLoad, subServiceId }),
    loadApi,
    () => {
      setLoadApi(false);
    },
    () => {
      processApi();
    }
  );

  // Process Api
  const processApi = async () => {
    if (result.httpState === "SUCCESS") {
      setFormLoading(false);
      setTimeout(() => {}, 1500);
      openNotificationWithIcon("info", "", "Service Saved", "#D9FFB5");
      alert("Service Saved");

      // Handle Success Here
    } else if (result.httpState === "ERROR") {
      setFormLoading(false);
      //Handle Error Here
      openNotificationWithIcon(
        "info",
        "",
        result.data?.response?.data?.errors?.[0] ||
          result.data?.response?.data?.message ||
          result.errorMsg ||
          "Login Error",
        "#FFC2B7"
      );
    }
  };

  // A custom hook to Load All serviceSubCategories Details
  const serviceSubCategoriesDataResult = useFormatApiRequest(
    () => vendorGetSavedSubServices({ subServiceId }),
    loadserviceSubCategoriesData,
    () => {
      setLoadServiceSubCategoriesData(false);
    },
    () => {
      processserviceSubCategoriesResult();
    }
  );

  // Process The Current ServiceSubCategories Data Result
  const processserviceSubCategoriesResult = async () => {
    if (serviceSubCategoriesDataResult.httpState === "SUCCESS") {
      console.log(serviceSubCategoriesDataResult.data?.data);
    } else if (serviceSubCategoriesDataResult.httpState === "ERROR") {
      openNotificationWithIcon(
        "info",
        "",
        result.data?.response?.data?.errors?.[0] ||
          result.data?.response?.data?.message ||
          result.errorMsg ||
          "Error Loading Service",
        "#FFC2B7"
      );
    } else if (serviceSubCategoriesDataResult.httpState === "LOADING") {
      console.log("loading");
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}

      {/* "Main Desgin" */}
      <div className="grid p-4">
        <form onSubmit={handleSubmit}>
          {/* Service Name */}
          <div className="relative w-full mb-6">
            <span> Service Name </span>
            <input
              readOnly
              required
              name="serviceName"
              value={payLoad?.serviceName || title}
              onChange={handleInputChange}
              type="text"
              className="pl-4 pr-4 py-2 border rounded-lg w-full h-16 bg-stone-100"
              placeholder="Service Name"
            />
          </div>

          {/* Price */}
          <div className="relative w-full mb-6">
            <span> Price </span>
            <input
              required
              name="price"
              value={payLoad?.price}
              onChange={handleInputChange}
              type="number"
              step="0.01"
              className="pl-4 pr-4 py-2 border rounded-lg w-full h-16 bg-stone-100"
              placeholder="Price"
            />
          </div>

          {/* Duration */}
          <div className="relative w-full mb-6">
            <span> Duration </span>
            <select
              required
              name="durationInMinutes"
              value={payLoad?.durationInMinutes}
              onChange={handleInputChange}
              className="pl-4 pr-4 py-2 border rounded-lg w-full h-16 bg-stone-100"
            >
              <option value="">Select Duration</option>
              <option value="15">15 Minutes</option>
              <option value="30">30 Minutes</option>
              <option value="45">45 Minutes</option>
              <option value="60">1 Hour</option>
              <option value="90">1 Hour 30 Minutes</option>
              <option value="120">2 Hours</option>
            </select>
          </div>

          {/* Description */}
          <div className="relative w-full mb-6">
            <span> Description </span>
            <textarea
              required
              name="description"
              value={payLoad?.description}
              onChange={handleInputChange}
              className="pl-4 pr-4 py-2 border rounded-lg w-full h-32 bg-stone-100"
              placeholder="Description"
            />
          </div>

          {/* Continue Button */}
          <div className="mb-2">
            <button className="w-full h-16  font-sans bg-stone-900 text-white rounded-xl text-lg">
              {!formLoading ? "Save" : <LoadingOutlined rev={undefined} />}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default VendorOnboardingServicesFormComp;
