import { Link } from "react-router-dom";
import "./Home-Comp.css";

export const StartScreenComp = () => {
  return (
    <>
      <div
        className="flex items-center justify-center min-h-screen bg-cover bg-top sm:hidden"
        style={{
          backgroundImage:
            "url('" +
            process.env.PUBLIC_URL +
            "/images/splashscreen/welcomeCombine.svg" +
            "')",
        }}
      >
        <div className="absolute bottom-0 mb-10 w-full flex flex-col items-center">
          <button className="mb-4 w-3/4 py-2 text-white bg-green rounded-tl-2xl rounded-br-2xl">
            <Link to={"/explore/explore-home"}> Discover Service</Link>
          </button>
          <button className="mb-4 w-3/4 py-2 text-green bg-white border-green border rounded-tl-2xl rounded-br-2xl">
            <Link to={"/auth"}> {"User Login"}</Link>
          </button>
          <button className="w-3/4 py-2 text-green bg-white border-green border rounded-tl-2xl rounded-br-2xl">
            <Link to={"/vendor-auth/signin"}> {"Business Login"}</Link>
          </button>
        </div>
      </div>

      <div className="sm:flex flex-col w-full h-screen hidden">
        <div className="flex-1 flex flex-col h-3/5">
          <div className="flex justify-start">
            <img
              className="w-32 rounded-xl"
              alt="_img"
              src={`${process.env.PUBLIC_URL + "/Favicon.jpg"}`}
            />
          </div>
          <img
            className="rounded-xl h-3/4"
            alt="_img"
            src={`${
              process.env.PUBLIC_URL + "/images/splashscreen/welcomePicture.svg"
            }`}
          />
        </div>
        <div className="flex-1 flex items-center bg-stone-200 justify-center w-full">
          <div className="mt-10 w-full flex flex-col items-center">
            <button className="mb-4 w-3/4 py-2 text-white bg-green rounded-tl-2xl rounded-br-2xl h-12">
              <Link to={"/explore/explore-home"}> Discover Service</Link>
            </button>
            <button className="mb-4 w-3/4 py-2 text-green bg-white border-green border rounded-tl-2xl rounded-br-2xl h-12">
              <Link to={"/auth"}> {"User Login"}</Link>
            </button>
            <button className="w-3/4 py-2 text-green bg-white border-green border rounded-tl-2xl rounded-br-2xl h-12">
              <Link to={"/vendor-auth/signin"}> {"Business Login"}</Link>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default StartScreenComp;
