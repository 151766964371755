import { useEffect } from "react";
import { App } from "@capacitor/app";
import "../explore.css";
import StartScreenComp from "../../../components/HomeComponents/StartScreenComp";

export const StartPage = () => {
  useEffect(() => {
    const backHandler = () => {
      App.exitApp();
      return true;
    };
    const backButtonListener = App.addListener("backButton", backHandler);
    return () => {
      backButtonListener.remove();
    };
  }, []);

  return (
    <>
      <div className="w3-content">
        <StartScreenComp></StartScreenComp>
      </div>
    </>
  );
};

export default StartPage;
