import { useEffect } from "react";
import { App } from "@capacitor/app";
import TopBar from "../../../components/LayoutComponent/TopBar/topBar";
import Tabbar from "../../../components/LayoutComponent/TabBar/Tabbar";
import "../vendorDashboard.css";
import VendorTransactionsDetailsComp from "../../../components/vendorProfileComp/VendorTransactionsDetailsWrapperComp";

export const VendorTransactionsDetailsPage = () => {
  useEffect(() => {
    const backHandler = () => {
      App.exitApp();
      return true;
    };
    const backButtonListener = App.addListener("backButton", backHandler);
    return () => {
      backButtonListener.remove();
    };
  }, []);

  return (
    <>
      <div className="w3-content" style={{ minHeight: "400px" }}>
        <TopBar></TopBar>
        <VendorTransactionsDetailsComp></VendorTransactionsDetailsComp>
        <Tabbar menuType="vendors"></Tabbar>
      </div>
    </>
  );
};

export default VendorTransactionsDetailsPage;
