import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import {
  Button,
  Empty,
  notification,
  Pagination,
  Result,
  Spin,
  Modal,
} from "antd";
import { sampleApiCall } from "../../apiservice/authService";
import useFormatApiRequest from "../../hooks/formatApiRequest";
import { useAppDispatch } from "../../Redux/reduxCustomHook";
import { ILoadState } from "../../utils/loading.utils.";
import "./vendorProductsComp.css";

type NotificationType = "success" | "info" | "warning" | "error";

type IVendorProductList = {
  externalFilter?: any;
  initialDefaultFilter?: any;
  hidePagination?: boolean;
};

export const VendorProductList: React.FC<IVendorProductList> = ({
  externalFilter,
  initialDefaultFilter,
  hidePagination = false,
}) => {
  const [productsLoadState, setProductsLoadState] =
    useState<ILoadState>("loading");
  const [loadproductsData, setLoadProductsData] = useState(true);
  const [productsDefaultFilter, setProductsDefaultFilter] = useState(
    initialDefaultFilter || {}
  );
  const [tableData, setTableData] = useState<any[]>([]);
  const [api, contextHolder] = notification.useNotification();

  // Pagination Constant/Variables
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const perPage = 10;

  // For Navigator/Redux
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Use Effect to reload API when External Filter has changed
  useEffect(() => {
    if (externalFilter) {
      setProductsDefaultFilter({
        ...productsDefaultFilter,
        ...externalFilter,
      });
      setLoadProductsData(true);
      setProductsLoadState("loading");
    }
  }, [externalFilter]);

  // A custom hook to Load All products Details
  const productsDataResult = useFormatApiRequest(
    () => sampleApiCall(productsDefaultFilter),
    loadproductsData,
    () => {
      setLoadProductsData(false);
    },
    () => {
      processproductsResult();
    }
  );

  // Process The Current Products Data Result
  const processproductsResult = async () => {
    if (productsDataResult.httpState === "SUCCESS") {
      setTableData(
        productsDataResult.data?.data ||
          Array.from({ length: 10 }, (_, i) => ({
            productName: `Product ${i + 1}`,
            productDescription: `Description for product ${i + 1}...`,
            image: "https://via.placeholder.com/400",
          }))
      );
      setProductsLoadState("completed");
      setTotalItems(productsDataResult.data?.meta?.total || 10);
    } else if (productsDataResult.httpState === "ERROR") {
      setProductsLoadState("error");
    } else if (productsDataResult.httpState === "LOADING") {
      setProductsLoadState("loading");
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  // Use to Control Pagination On Change Event
  const onPageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setProductsDefaultFilter({
      ...productsDefaultFilter,
      page: page,
      perPage: pageSize,
    });
    setLoadProductsData(true);
  };

  // Navigate to the next Page
  const navigateForward = async (index: number) => {
    navigate(`/vendors/product/${index}`);
  };

  // Handle delete confirmation
  const handleDelete = (index: number) => {
    Modal.confirm({
      title: "Are you sure you want to delete this product?",
      onOk: () => {
        // Implement delete functionality here
        console.log(`Product ${index} deleted`);
      },
    });
  };

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}
      <div className="grid pt-4">
        {/* " Show Loading Indicator" */}
        {productsLoadState === "loading" && (
          <div className="mt-2 mb-2 flex justify-center items-center pt-20 pb-20">
            <Spin size="large" />
          </div>
        )}

        {/* " Show Loading Error" */}
        {productsLoadState === "error" && (
          <div className="mt-2 mb-2">
            <Result
              status="500"
              title={<span className="">Error</span>}
              subTitle={
                <span className="">
                  Sorry, something went wrong, it could be a network Related
                  error
                </span>
              }
              extra={
                <Button
                  onClick={() => setLoadProductsData(true)}
                  type="primary"
                >
                  Reload
                </Button>
              }
            />
          </div>
        )}

        {/* " Show No data" */}
        {productsLoadState === "noData" && (
          <div className="mt-2">
            <Empty />
          </div>
        )}

        {/* " Show Data " */}
        {productsLoadState === "completed" && (
          <div className="mt-2">
            <div>
              {/* Products */}
              <div className="grid grid-cols-1 gap-4">
                {tableData.map((Products, index) => (
                  <div key={index}>
                    {/* Cards */}
                    <div className="flex flex-row p-2 w-full bg-white border border-gray-200 rounded-xl">
                      <p className="w-12 flex items-center justify-start">
                        <img
                          className="rounded-xl"
                          style={{ width: "48px" }}
                          src={Products.image}
                          alt=""
                        />
                      </p>

                      <div className="grow p-2 flex flex-col items-start justify-center">
                        <h2 className="font-semibold font-sans text-md mb-2">
                          {Products.productName}
                        </h2>
                        <p className="text-sm font-san">
                          {Products.productDescription}
                        </p>
                      </div>

                      <p className="w-12 flex items-center justify-center">
                        <EyeOutlined
                          className="text-blue-500 cursor-pointer"
                          onClick={() => navigateForward(index)}
                        />
                        &nbsp;&nbsp;
                        <DeleteOutlined
                          className="text-red-500 cursor-pointer"
                          onClick={() => handleDelete(index)}
                        />
                      </p>
                    </div>
                    {/* End Cards */}
                  </div>
                ))}
              </div>
              {/* End Products */}
            </div>

            {!hidePagination && (
              <div className="grid mt-6">
                <Pagination
                  current={currentPage || 1}
                  onChange={onPageChange}
                  pageSize={perPage}
                  total={totalItems}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
