import { useState, useEffect } from "react";
import { authRefreshSession } from "../apiservice/authService";
import { IAuthType } from "../apiservice/authService.type";
import { vendorAuthRefreshSession } from "../apiservice/vendor-authService";
import { IVendorAuthType } from "../apiservice/vendor-authService.type";
import { useAppDispatch } from "../Redux/reduxCustomHook";
import {
  getJSON,
  getString,
  storeJSON,
  storePlainString,
} from "../utils/localStorage";
import useFormatApiRequest from "./formatApiRequest";

export const USER_TOKEN_KEY = "authTokenUser";
export const USER_AUTH_DATA_KEY = "authDataUser";
export const VENDOR_TOKEN_KEY = "authTokenVendor";
export const VENDOR_AUTH_DATA_KEY = "authDataVendor";

const useAuth = (loadApi?: Boolean, preReqFunc?: () => void) => {
  const [tokenUser, setTokenUser] = useState<any>(null);
  const [authDataUser, setAuthDataUser] = useState<any>(null);
  const [isAutheticatedUser, setIsAutheticatedUser] = useState(true);
  const [loadUserData, setLoadUserData] = useState(false);

  const [tokenVendor, setTokenVendor] = useState<any>(null);
  const [authDataVendor, setAuthDataVendor] = useState<any>(null);
  const [isAutheticatedVendor, setIsAutheticatedVendor] = useState(true);
  const [loadVendorData, setLoadVendorData] = useState(false);

  const dispatch = useAppDispatch();

  // This is use to check local storage the last stored Session Variables and get them and dispatch it.
  const checkAuth = async () => {
    // User Side
    if (
      getString(USER_TOKEN_KEY) !== null ||
      getJSON(USER_AUTH_DATA_KEY) !== null
    ) {
      setIsAutheticatedUser(true);
      setTokenUser(getString(USER_TOKEN_KEY));
      setAuthDataUser(getJSON(USER_AUTH_DATA_KEY));

      dispatch({
        type: "AUTH_ADD_DATA",
        payload: getJSON(USER_AUTH_DATA_KEY),
      });
      setLoadUserData(true);
    } else {
      setIsAutheticatedUser(false);
    }

    // Vendor Side
    if (
      getString(VENDOR_TOKEN_KEY) !== null ||
      getJSON(VENDOR_AUTH_DATA_KEY) !== null
    ) {
      setIsAutheticatedVendor(true);

      setTokenVendor(getString(VENDOR_TOKEN_KEY));
      setAuthDataVendor(getJSON(VENDOR_AUTH_DATA_KEY));
      dispatch({
        type: "VENDOR_AUTH_ADD_DATA",
        payload: getJSON(VENDOR_AUTH_DATA_KEY),
      });
      setLoadVendorData(true);
    } else {
      setIsAutheticatedVendor(false);
    }
  };

  useEffect(() => {
    checkAuth();
  }, [isAutheticatedUser, isAutheticatedVendor]);

  useEffect(() => {
    if (loadApi) {
      if (preReqFunc) {
        preReqFunc();
      }
      checkAuth();
    }
  }, [loadApi]);

  // A custom hook to Refresh User Data Information
  const userDataRefreshResult = useFormatApiRequest(
    () => authRefreshSession(),
    loadUserData,
    () => {
      setLoadUserData(false);
    },
    () => {
      processUserDataResult();
    }
  );

  // Process The User Data refresh Api
  const processUserDataResult = async () => {
    if (userDataRefreshResult.httpState === "SUCCESS") {
      const userRefreshResult: IAuthType = userDataRefreshResult.data;
      storePlainString(USER_TOKEN_KEY, userRefreshResult?.data?.token || "");
      storeJSON(USER_AUTH_DATA_KEY, userRefreshResult);
      dispatch({ type: "AUTH_ADD_DATA", payload: userRefreshResult });
    } else if (userDataRefreshResult.httpState === "ERROR") {
      console.log("Session Login Error");
    }
  };

  // A custom hook to Refresh Vendor Data Information
  const vendorDataRefreshResult = useFormatApiRequest(
    () => vendorAuthRefreshSession(),
    loadVendorData,
    () => {
      setLoadVendorData(false);
    },
    () => {
      processVendorDataResult();
    }
  );

  // Process The Vendor Data refresh Api
  const processVendorDataResult = async () => {
    if (vendorDataRefreshResult.httpState === "SUCCESS") {
      const vendorSessionResult: IVendorAuthType = vendorDataRefreshResult.data;
      storePlainString(VENDOR_TOKEN_KEY, vendorSessionResult?.data?.token || "");
      storeJSON(VENDOR_AUTH_DATA_KEY, vendorSessionResult);
      dispatch({ type: "VENDOR_AUTH_ADD_DATA", payload: vendorSessionResult });
    } else if (vendorDataRefreshResult.httpState === "ERROR") {
      console.log("Session Login Error for Vendor");
    }
  };

  return {
    isAutheticatedUser,
    authDataUser,
    tokenUser,
    isAutheticatedVendor,
    authDataVendor,
    tokenVendor,
  };
};

export default useAuth;
