import { useState } from "react";
import { useAppSelector } from "../../Redux/reduxCustomHook";
import { RootState } from "../../Redux/store";
import "./message-Comp.css";
import { VendorChatList } from "./vendorsChatList";

export const VendorChatListWrapper = () => {
  const [payLoadFilter, setpayLoadFilter] = useState<any>({});
  const [externalFilter, setExternalFilter] = useState<any>({});
  const authData: any = useAppSelector((state: RootState) => state);

  // Use to collect Change
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    // console.log(value)
    setpayLoadFilter((values) => ({ ...values, [name]: value }));
    if (name === "status") {
      setExternalFilter({ ...externalFilter, status: value });
    }
  };

  const handleButtonClick = (value) => {
    setpayLoadFilter((values) => ({ ...values }));
  };

  // Use to Update Filter
  const updateFilter = () => {
    setExternalFilter({ ...payLoadFilter });
  };

  return (
    <>
      <div className="grid p-4">
        {/* Title */}
        <div className="grid grid-cols-1">
          <div className="flex" style={{ padding: "5px", paddingTop: "15px" }}>
            <h2 className="font-sans text-2xl font-semibold"> Chat </h2>
          </div>
        </div>

        {/* Filters */}
        <div className="flex flex-row mt-6 gap-2">
          <div className="basis-10/12">
            <div>
              <input
                name="searchstring"
                value={payLoadFilter?.searchstring || ""}
                onChange={handleInputChange}
                onBlur={updateFilter}
                className="h-12 w-full border rounded-xl p-2"
                placeholder="Search"
              />
            </div>
          </div>

          <div className="basis-2/12">
            <div>
              <button className="h-12 w-full border rounded-xl p-2 border-green text-green">
                Search
              </button>
            </div>
          </div>
        </div>

        {/* Table/List/Grid */}
        <div className="grid mt-4">
          <VendorChatList></VendorChatList>
        </div>
      </div>
    </>
  );
};

export default VendorChatListWrapper;
