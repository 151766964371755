import { LeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../Redux/reduxCustomHook";
import { RootState } from "../../../Redux/store";
import "./topbar.css";

type ITopBar = {
  title?: string;
  displayType?: "search" | "title" | "none";
  showBackButton?: boolean;
  backButtonHref?: string;
};

export const TopBar: React.FC<ITopBar> = ({
  title,
  displayType = "search",
  showBackButton = false,
  backButtonHref = "/",
}) => {
  const authData: any = useAppSelector((state: RootState) => state);

  const navigate = useNavigate();

  // Navigate To Home
  const navigateToHome = async () => {
    navigate("/", { replace: true });
  };

  // Navigate BackWards
  const navigateBackward = async (url: string) => {
    navigate(-1);
  };

  return (
    <>
      {/* Top Bar Card */}
      <div className="fixedMenu bg-white">
        <>
          <div className="w3-content">
            <div className="grid">
              <div className="p-4 flex justify-center items-center">
                {showBackButton && (
                  <div className="w-9">
                    <p
                      className="font-sans text-lg"
                      onClick={() => {
                        navigateBackward(backButtonHref);
                      }}
                    >
                      <span>
                        <LeftOutlined /> &nbsp;
                      </span>
                    </p>
                  </div>
                )}

                <div className=" flex-grow">
                  {displayType === "title" && (
                    <p className="font-sans text-lg pt-2">
                      {title || "Soft Life"}
                    </p>
                  )}

                  {displayType === "search" && (
                    <input
                      readOnly
                      className="w-full h-10 bg-stone-300 rounded-2xl p-2 font-sans text-sm"
                      placeholder="Search Service, Stylist or Salon"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      </div>

      <div className="grid menuSpace">
        <br />
      </div>
    </>
  );
};

export default TopBar;
