import React from "react";
import "./Vendor-Profile-Comp.css";

const VendorTransactionsDetailsCard = () => {
  return (
    <>
      <div className="flex flex-col p-2 bg-white border border-gray-300 rounded-lg mb-4">
        <div className="flex items-center">
          {/* Title */}
          <div className="flex-grow">
            <h2 className="pt-2 pb-2 font-sans text-lg font-semibold">
              Transactions Details
            </h2>
          </div>
        </div>

        {/* Transactions Information  */}
        <div className="flex flex-col">
          <h2 className="text-xl font-semibold mt-4">Transactions Name</h2>
          <p className="text-gray-500 mt-4">
            {/* Price Field */}
            <strong>Price:</strong> $100
          </p>
          <p className="text-gray-500 mt-4">
            {/* Shipping Fees Field */}
            <strong>Shipping Fees:</strong> $10
          </p>

          <p className="text-gray-500 mt-4">
            {/*   Quantity  */}
            <strong>Quantity :</strong> 5
          </p>

          <p className="text-gray-500 mt-4">
            {/* Total */}
            <strong>Total:</strong> $180
          </p>
        </div>
      </div>
    </>
  );
};

export default VendorTransactionsDetailsCard;
