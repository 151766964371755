import { LoadingOutlined } from "@ant-design/icons";
import { notification } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { vendorAuthSignUpAddress } from "../../apiservice/vendor-authService";
import { IVendorAuthType } from "../../apiservice/vendor-authService.type";
import useFormatApiRequest from "../../hooks/formatApiRequest";
import useAuth from "../../hooks/useAuth";
import { useAppDispatch, useAppSelector } from "../../Redux/reduxCustomHook";
import { RootState } from "../../Redux/store";
import { NotificationType } from "../../utils/mscType.type";
import "./vendoronboarding.css";

type IVendorOnboardingAddressFormComp = {
  editMode?: boolean;
};

const VendorOnboardingAddressFormComp: React.FC<
  IVendorOnboardingAddressFormComp
> = ({ editMode = false }) => {
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [loadApi, setLoadApi] = useState(false);
  const [user, setUser] = useState<any>({});
  const [api, contextHolder] = notification.useNotification();

  const [loadAuth, setLoadAuth] = useState(true);

  const authVendorData: IVendorAuthType = useAppSelector(
    (state: RootState) => state?.VendorAuthData
  );

  // Use to refresh the Auth State
  const authState = useAuth(loadAuth, () => {
    setLoadAuth(false);
  });

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Use to collect Site Description Change
  const handleInputChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setUser((values: any) => ({ ...values, [name]: value }));
  };

  // Use to Submit Form
  const handleSubmit = (event: any) => {
    event.preventDefault();
    setLoadApi(true);
    setFormLoading(true);
  };

  // A custom hook to format the login Api
  const result = useFormatApiRequest(
    () => vendorAuthSignUpAddress(user),
    loadApi,
    () => {
      setLoadApi(false);
    },
    () => {
      processApi();
    }
  );

  // Process Api
  const processApi = async () => {
    if (result.httpState === "SUCCESS") {
      setFormLoading(false);
      setTimeout(() => {
        // Handle Success Here

        if (!editMode) {
          navigate("/vendors/onboarding-services-intro");
        } else {
          setLoadAuth(true);
        }
      }, 1500);
      openNotificationWithIcon(
        "info",
        "",
        "Address Added Succesfully",
        "#D9FFB5"
      );

      // Handle Success Here
    } else if (result.httpState === "ERROR") {
      setFormLoading(false);
      //Handle Error Here
      openNotificationWithIcon(
        "info",
        "",
        result.data?.response?.data?.errors?.[0] ||
          result.data?.response?.data?.message ||
          result.errorMsg ||
          "Login Error",
        "#FFC2B7"
      );
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}

      {/* "Main Desgin" */}
      <div className="grid p-4">
        {/* UnderLine */}
        <div className="mb-6 border-b-2 border-black">
          <br />
        </div>

        <div className="mb-6">
          <p className="font-sans text-lg">Fill In Your Business Address</p>
        </div>

        <form onSubmit={handleSubmit}>
          {/* Street Address */}
          <div className="relative w-full mb-6">
            <span> Street Address </span>
            <input
              required
              name="streetAddress"
              value={user?.streetAddress}
              onChange={handleInputChange}
              type="text"
              className="pl-4 pr-4 py-2 border rounded-lg w-full h-16 bg-stone-100"
              placeholder="Street Address"
            />
          </div>

          {/* Suite Number */}
          <div className="relative w-full mb-6">
            <span> Suite Number </span>
            <input
              required
              name="suiteNumber"
              value={user?.suiteNumber}
              onChange={handleInputChange}
              type="text"
              className="pl-4 pr-4 py-2 border rounded-lg w-full h-16 bg-stone-100"
              placeholder="Suite Number"
            />
          </div>

          {/* City */}
          <div className="relative w-full mb-6">
            <span> City </span>
            <input
              required
              name="city"
              value={user?.city}
              onChange={handleInputChange}
              type="text"
              className="pl-4 pr-4 py-2 border rounded-lg w-full h-16 bg-stone-100"
              placeholder="City"
            />
          </div>

          {/* State */}
          <div className="relative w-full mb-6">
            <span> State </span>
            <select
              required
              name="state"
              value={user?.state}
              onChange={handleInputChange}
              className="pl-4 pr-4 py-2 border rounded-lg w-full h-16 bg-stone-100"
            >
              <option value="">Select State</option>
              <option value="Abia">Abia</option>
              <option value="Adamawa">Adamawa</option>
              <option value="Akwa Ibom">Akwa Ibom</option>
              <option value="Anambra">Anambra</option>
              <option value="Bauchi">Bauchi</option>
              <option value="Bayelsa">Bayelsa</option>
              <option value="Benue">Benue</option>
              <option value="Borno">Borno</option>
              <option value="Cross River">Cross River</option>
              <option value="Delta">Delta</option>
              <option value="Ebonyi">Ebonyi</option>
              <option value="Edo">Edo</option>
              <option value="Ekiti">Ekiti</option>
              <option value="Enugu">Enugu</option>
              <option value="Gombe">Gombe</option>
              <option value="Imo">Imo</option>
              <option value="Jigawa">Jigawa</option>
              <option value="Kaduna">Kaduna</option>
              <option value="Kano">Kano</option>
              <option value="Katsina">Katsina</option>
              <option value="Kebbi">Kebbi</option>
              <option value="Kogi">Kogi</option>
              <option value="Kwara">Kwara</option>
              <option value="Lagos">Lagos</option>
              <option value="Nasarawa">Nasarawa</option>
              <option value="Niger">Niger</option>
              <option value="Ogun">Ogun</option>
              <option value="Ondo">Ondo</option>
              <option value="Osun">Osun</option>
              <option value="Oyo">Oyo</option>
              <option value="Plateau">Plateau</option>
              <option value="Rivers">Rivers</option>
              <option value="Sokoto">Sokoto</option>
              <option value="Taraba">Taraba</option>
              <option value="Yobe">Yobe</option>
              <option value="Zamfara">Zamfara</option>
              <option value="FCT">FCT</option>
            </select>
          </div>

          {/* Zip Code */}
          <div className="relative w-full mb-6">
            <span> Zip Code </span>
            <input
              required
              name="zipCode"
              value={user?.zipCode}
              onChange={handleInputChange}
              type="text"
              className="pl-4 pr-4 py-2 border rounded-lg w-full h-16 bg-stone-100"
              placeholder="Zip Code"
            />
          </div>

          {/* Forget Password Button */}
          <div className="mb-2">
            <button className="w-full h-16  font-sans bg-stone-900 text-white rounded-xl text-lg">
              {!formLoading ? "Continue" : <LoadingOutlined rev={undefined} />}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default VendorOnboardingAddressFormComp;
