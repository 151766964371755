import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { Button, Empty, notification, Pagination, Result, Spin } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { sampleApiCall } from "../../apiservice/authService";
import useFormatApiRequest from "../../hooks/formatApiRequest";
import { useAppDispatch } from "../../Redux/reduxCustomHook";
import { ILoadState } from "../../utils/loading.utils.";
import "./Vendor-Appointment-Comp.css";
type NotificationType = "success" | "info" | "warning" | "error";

type IVendorClientList = {
  externalFilter?: any;
  initialDefaultFilter?: any;
  hidePagination?: boolean;
};

export const VendorClientList: React.FC<IVendorClientList> = ({
  externalFilter,
  initialDefaultFilter,
  hidePagination = false,
}) => {
  const [clientsLoadState, setClientLoadState] =
    useState<ILoadState>("loading");
  const [loadclientsData, setLoadClientData] = useState(true);
  const [clientsDefaultFilter, setClientDefaultFilter] = useState(
    initialDefaultFilter || {}
  );
  const [tableData, setTableData] = useState<any[]>([]);
  const [api, contextHolder] = notification.useNotification();

  // Pagination Constant/Variables
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const perPage = 10;

  // For Navigator/Redux
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Use Effect to reload API when External Filter has changed
  useEffect(() => {
    if (externalFilter) {
      setClientDefaultFilter({
        ...clientsDefaultFilter,
        ...externalFilter,
      });
      setLoadClientData(true);
      setClientLoadState("loading");
    }
  }, [externalFilter]);

  // A custom hook to Load All clients Details
  const clientsDataResult = useFormatApiRequest(
    () => sampleApiCall(clientsDefaultFilter),
    loadclientsData,
    () => {
      setLoadClientData(false);
    },
    () => {
      processclientsResult();
    }
  );

  // Process The Current Client Data Result
  const processclientsResult = async () => {
    if (clientsDataResult.httpState === "SUCCESS") {
      setTableData(
        clientsDataResult.data?.data || [
          {
            fullName: "James Hannah",
            dateJoined: "2021-06-12",
            image: "https://example.com/images/alice_turner.jpg",
          },
          {
            fullName: "James Hannah",
            dateJoined: "2021-06-12",
            image: "https://example.com/images/alice_turner.jpg",
          },
          {
            fullName: "James Hannah",
            dateJoined: "2021-06-12",
            image: "https://example.com/images/alice_turner.jpg",
          },
        ]
      );
      setClientLoadState("completed");
      setTotalItems(clientsDataResult.data?.meta?.total || 1);
    } else if (clientsDataResult.httpState === "ERROR") {
      setClientLoadState("error");
    } else if (clientsDataResult.httpState === "LOADING") {
      setClientLoadState("loading");
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  // Use to Control Pagination On Change Event
  const onPageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setClientDefaultFilter({
      ...clientsDefaultFilter,
      page: page,
      perPage: pageSize,
    });
    setLoadClientData(true);
  };

  // Navigate to the next Page
  const navigateForward = async (index: number) => {
    navigate(`/vendors/client/${index}/Emmanuel`);
  };

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}
      <div className="grid pt-4">
        {/* " Show Loading Indicator" */}
        {clientsLoadState === "loading" && (
          <div className="mt-2 mb-2 flex justify-center items-center pt-20 pb-20">
            <Spin size="large" />
          </div>
        )}

        {/* " Show Loading Error" */}
        {clientsLoadState === "error" && (
          <div className="mt-2 mb-2">
            <Result
              status="500"
              title={<span className="">Error</span>}
              subTitle={
                <span className="">
                  Sorry, something went wrong, it could be a network Related
                  error
                </span>
              }
              extra={
                <Button onClick={() => setLoadClientData(true)} type="primary">
                  Reload
                </Button>
              }
            />
          </div>
        )}

        {/* " Show No data" */}
        {clientsLoadState === "noData" && (
          <div className="mt-2">
            <Empty></Empty>
          </div>
        )}

        {/* " Show Data " */}
        {clientsLoadState === "completed" && (
          <div className="mt-2">
            <div>
              {/* Client */}
              <div className="grid grid-cols-1 gap-4">
                {tableData.map((clients, index) => (
                  <>
                    <div key={index}>
                      {/* Cards */}
                      <div
                        className="flex flex-row p-2 w-full bg-white border border-gray-200 rounded-xl"
                        onClick={() => navigateForward(index)}
                      >
                        <p className="w-12 flex items-center justify-start">
                          <img
                            className="w3-circle"
                            style={{ width: "48px" }}
                            src={`${
                              process.env.PUBLIC_URL +
                              "/images/auth/profileSample.png"
                            }`}
                            alt=""
                          />
                        </p>

                        <div className="grow p-2 flex items-center justify-start">
                          <h2 className="font-semibold font-sans text-md mb-2">
                            {clients?.fullName}
                          </h2>
                        </div>
                      </div>
                      {/* End  Cards */}
                    </div>
                  </>
                ))}
              </div>
              {/* End Client */}
            </div>

            {!hidePagination && (
              <div className="grid mt-6">
                <Pagination
                  current={currentPage || 1}
                  onChange={onPageChange}
                  pageSize={perPage}
                  total={totalItems}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
