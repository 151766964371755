import {
  UserOutlined,
  PlayCircleOutlined,
  LockOutlined,
  QuestionCircleOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import "./User-Profile-Comp.css";

export const UserProfileSettingComp = () => {
  const navigate = useNavigate();
  const menuItems = [
    {
      icon: <UserOutlined />,
      text: "Contact Information",
      path: "/users/profile",
    },
    {
      icon: <PlayCircleOutlined />,
      text: "Getting Started",
      path: "/users/profile",
    },
    { icon: <LockOutlined />, text: "Change Password", path: "/users/profile" },
    {
      icon: <QuestionCircleOutlined />,
      text: "Help Center",
      path: "/users/profile",
    },
    { icon: <LogoutOutlined />, text: "Logout", path: "/users/logout" },
  ];

  const handleNavigation = (path) => {
    navigate(path);
  };
  return (
    <>
      <div className="grid p-4">
        {/* Account Settings */}
        <h2 className="pt-2 pb-2 font-sans text-lg font-semibold">
          Account Settings
        </h2>
        <p className="pt-2 pb-8 font-sans text-sm">Emmanuel Soligbo</p>

        {menuItems.map((item, index) => (
          <div
            key={index}
            onClick={() => handleNavigation(item.path)}
            className="flex items-center p-2 pt-4 pb-4 border-t hover:bg-gray-100 cursor-pointer font-sans"
          >
            <div className="text-xl text-gray-600">{item.icon}</div>
            <span className="pl-4 text-md font-medium text-gray-900">
              {item.text}
            </span>
          </div>
        ))}
      </div>
    </>
  );
};

export default UserProfileSettingComp;
