import "../../App.css";
import { Routes, Route } from "react-router-dom";
import Nopage from "../Nopage/Nopage";
import VendorPagesLayout from "./Layout/Layout";
import VendorHomePage from "./VendorDashboard/VendorHomePage";
import VendorOnboardingSetAddressPage from "./vendorOnboarding/VendorOnboardingAddressPage";
import VendorOnboardingAddServicePage from "./vendorOnboarding/VendorOnboardingAddServicePage";
import VendorOnboardingAvailabilityPage from "./vendorOnboarding/VendorOnboardingAvailabilityPage";
import VendorOnboardingBusinessTitlePage from "./vendorOnboarding/VendorOnboardingBusinessTitlePage";
import VendorOnboardingPhotosPage from "./vendorOnboarding/VendorOnboardingPhotosPage";
import VendorOnboardingServiceIntroPage from "./vendorOnboarding/VendorOnboardingServiceIntroPage";
import { VendorCalenderPage } from "./VendorDashboard/VendorCalenderPage";
import VendorClientPage from "./VendorDashboard/VendorClientPage";
import { VendorChatListPage } from "./VendorDashboard/VendorChatListPage";
import VendorChatMessagePage from "./VendorDashboard/VendorChatMessagePage";
import VendorProfilePage from "./VendorDashboard/VendorProfilePage";
import VendorSettingsProfilePage from "./VendorDashboard/VendorProfileSettingsPage";
import VendorClientDetailsPage from "./VendorDashboard/VendorClientDetailsPage";
import VendorProductsitemsPage from "./VendorDashboard/VendorProductsitemsPage";
import VendorProductsDetailsPage from "./VendorDashboard/VendorProductsDetailsPage";
import VendorProductsitemsLocationPage from "./VendorDashboard/VendorProductsitemsLocationPage";
import VendorProductsOrdersPage from "./VendorDashboard/VendorProductsOrdersPage";
import VendorProfileSettingBanksPage from "./VendorDashboard/VendorProfileSettingBanksPage";
import VendorProfileSettingChangePasswordPage from "./VendorDashboard/VendorProfileSettingChangePasswordPage";
import VendorProfileSettingGettingStartedPage from "./VendorDashboard/VendorProfileSettingGettingStartedPage";
import VendorProfileSettingHelpCenterPage from "./VendorDashboard/VendorProfileSettingHelpCenterPage";
import VendorProductsOrdersDetailsPage from "./VendorDashboard/VendorProductsOrdersDetailsPage";
import { VendorProfileSettingTransactionsPage } from "./VendorDashboard/VendorProfileSettingsTransactionsPage";
import VendorTransactionsDetailsPage from "./VendorDashboard/VendorProfileSettingsTransactionsDetailsPage";
import VendorCalenderDetailsPage from "./VendorDashboard/VendorCalenderDetailsPage";
import VendorLogout from "./VendorDashboard/VendorLogout";
import VendorProfileSettingsAddServicesPage from "./VendorDashboard/VendorProfileAddServicesPage";
import VendorProfileSettingsUpdateAvailabilityPage from "./VendorDashboard/VendorProfileupdateAvailabilityPage";

const VendorDashboardPagesRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<VendorPagesLayout />}>
        <Route index element={<VendorHomePage />} />
        <Route path="/calendar" element={<VendorCalenderPage />} />
        <Route path="/calendar/:id" element={<VendorCalenderDetailsPage />} />
        <Route path="/client" element={<VendorClientPage />} />
        <Route path="/client/:id/:name" element={<VendorClientDetailsPage />} />
        <Route path="/chat" element={<VendorChatListPage />} />
        <Route path="/message/:id" element={<VendorChatMessagePage />} />

        <Route path="/products" element={<VendorProductsitemsPage />} />
        <Route path="/product/:id" element={<VendorProductsDetailsPage />} />
        <Route
          path="/product/:id/locations"
          element={<VendorProductsitemsLocationPage />}
        />
        <Route path="/products/orders" element={<VendorProductsOrdersPage />} />
        <Route
          path="/products/order/:id"
          element={<VendorProductsOrdersDetailsPage />}
        />

        <Route
          path="/profile-settings"
          element={<VendorSettingsProfilePage />}
        />
        <Route path="/profile" element={<VendorProfilePage />} />
        <Route
          path="/profile-add-services"
          element={<VendorProfileSettingsAddServicesPage />}
        />
        <Route
          path="/profile-update-availability"
          element={<VendorProfileSettingsUpdateAvailabilityPage />}
        />
        <Route
          path="/profile-settings-bank-details"
          element={<VendorProfileSettingBanksPage />}
        />
        <Route
          path="/profile-settings-change-password"
          element={<VendorProfileSettingChangePasswordPage />}
        />
        <Route
          path="/profile-settings-getting-started"
          element={<VendorProfileSettingGettingStartedPage />}
        />
        <Route
          path="/profile-settings-help-center"
          element={<VendorProfileSettingHelpCenterPage />}
        />
        <Route
          path="/onboarding-address"
          element={<VendorOnboardingSetAddressPage />}
        />
        <Route
          path="/profile-settings-transactions"
          element={<VendorProfileSettingTransactionsPage />}
        />
        <Route
          path="/profile-settings-transaction/:id"
          element={<VendorTransactionsDetailsPage />}
        />
        <Route
          path="/onboarding-services-intro"
          element={<VendorOnboardingServiceIntroPage />}
        />
        <Route
          path="/onboarding-add-services"
          element={<VendorOnboardingAddServicePage />}
        />
        <Route
          path="/onboarding-availability"
          element={<VendorOnboardingAvailabilityPage />}
        />
        <Route
          path="/onboarding-photos"
          element={<VendorOnboardingPhotosPage />}
        />
        <Route
          path="/onboarding-business-title"
          element={<VendorOnboardingBusinessTitlePage />}
        />
        <Route path="/home" element={<VendorHomePage />} />
        <Route path="/logout" element={<VendorLogout />} />
        <Route path="*" element={<Nopage />} />
      </Route>
    </Routes>
  );
};

export default VendorDashboardPagesRoute;
