import { LoadingOutlined, CloseOutlined } from "@ant-design/icons";
import { notification, Modal } from "antd";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { vendorAuthSignUpBusinessDetails } from "../../apiservice/vendor-authService";
import { IVendorAuthType } from "../../apiservice/vendor-authService.type";
import useFormatApiRequest from "../../hooks/formatApiRequest";
import useAuth from "../../hooks/useAuth";
import { useAppDispatch, useAppSelector } from "../../Redux/reduxCustomHook";
import { RootState } from "../../Redux/store";
import { NotificationType } from "../../utils/mscType.type";
import "./vendoronboarding.css";

type IVendorOnboardingBusinessFormComp = {
  editMode?: boolean;
};

const VendorOnboardingBusinessFormComp: React.FC<
  IVendorOnboardingBusinessFormComp
> = ({ editMode = false }) => {
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [loadApi, setLoadApi] = useState(false);
  const [user, setUser] = useState<any>({});
  const [formData, setFormData] = useState<any>({});
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const fileInput = useRef<HTMLInputElement>(null);

  const [loadAuth, setLoadAuth] = useState(true);

  const authVendorData: IVendorAuthType = useAppSelector(
    (state: RootState) => state?.VendorAuthData
  );

  // Use to refresh the Auth State
  const authState = useAuth(loadAuth, () => {
    setLoadAuth(false);
  });

  const handleInputChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  const handleImageClick = () => {
    if (selectedFile) {
      setPreviewImage(URL.createObjectURL(selectedFile));
      setPreviewVisible(true);
    } else if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const handleRemoveImage = () => {
    setSelectedFile(null);
    setPreviewVisible(false);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    if (!selectedFile && !editMode) {
      openNotificationWithIcon("error", "", "Please upload a photo", "#FFC2B7");
      return;
    }

    const formData = new FormData();
    if (selectedFile) {
      formData.append("image", selectedFile);
    }
    Object.keys(user).forEach((key) => {
      formData.append(key, user[key]);
    });

    setFormData(formData);
    setLoadApi(true);
    setFormLoading(true);
  };

  // A custom hook to format the login Api
  const result = useFormatApiRequest(
    () => vendorAuthSignUpBusinessDetails(formData),
    loadApi,
    () => {
      setLoadApi(false);
    },
    () => {
      processApi();
    }
  );

  // Process Api
  const processApi = async () => {
    if (result.httpState === "SUCCESS") {
      setFormLoading(false);

      setTimeout(() => {
        // Handle Success Here
        if (!editMode) {
          navigate("/vendors/home");
        } else {
          setLoadAuth(true);
        }
      }, 1500);
      openNotificationWithIcon(
        "info",
        "",
        "Your Business Have been Updated",
        "#D9FFB5"
      );

      // Handle Success Here
    } else if (result.httpState === "ERROR") {
      setFormLoading(false);
      //Handle Error Here
      openNotificationWithIcon(
        "info",
        "",
        result.data?.response?.data?.errors?.[0] ||
          result.data?.response?.data?.message ||
          result.errorMsg ||
          "Login Error",
        "#FFC2B7"
      );
    }
  };

  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  return (
    <>
      {contextHolder}

      <div className="grid p-4">
        {!editMode ? (
          <div className="flex mt-4 mb-4">
            <p>
              Horem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
              vulputate libero et velit interdum, ac aliquet odio mattis.
            </p>
          </div>
        ) : null}

        <form onSubmit={handleSubmit}>
          <div className="flex mt-4 mb-4">
            <span className="font-semibold text-lg font-sans">
              {editMode ? "Change Profile Picture" : " Add A Profile Picture"}
            </span>
          </div>

          <div
            className="flex flex-col items-start justify-center image-container"
            style={{ cursor: "pointer", margin: "5px" }}
          >
            <img
              onClick={handleImageClick}
              className="mt-2"
              src={
                selectedFile
                  ? URL.createObjectURL(selectedFile)
                  : authVendorData?.data?.credentials?.dpUrl ||
                    `${process.env.PUBLIC_URL}/images/vendorOnboarding/profilePic.svg`
              }
              alt="Profile"
              style={{
                width: "120px",
                height: "120px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
            />
            {selectedFile && (
              <>
                <br />
                <button
                  type="button"
                  className=" bg-red-500 text-white p-1 rounded"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemoveImage();
                  }}
                >
                  Remove
                </button>
              </>
            )}
          </div>

          <input
            ref={fileInput}
            type="file"
            name="profileFile"
            className="hidden"
            accept="image/*"
            onChange={handleInputChange}
          />

          <div className="flex mt-4 mb-4">
            <span className="font-semibold text-lg font-sans">
              Business Details
            </span>
          </div>

          <div className="relative w-full mb-6">
            <div className="mb-4">
              <span>Business Name</span>
              <input
                required
                name="businessName"
                value={
                  user?.businessName ||
                  authVendorData?.data?.credentials?.businessName
                }
                onChange={(e) =>
                  setUser({ ...user, businessName: e.target.value })
                }
                type="text"
                className="pl-4 pr-4 py-2 border rounded-lg w-full h-16 bg-stone-100"
                placeholder="Business Name"
              />
            </div>
          </div>

          <div className="mb-2">
            <button className="w-full h-16 font-sans bg-stone-900 text-white rounded-xl text-lg">
              {!formLoading ? "Save" : <LoadingOutlined rev={undefined} />}
            </button>
          </div>
        </form>
      </div>

      <Modal
        visible={previewVisible}
        footer={null}
        onCancel={() => setPreviewVisible(false)}
        className="full-screen-modal"
        centered
        closable={false}
      >
        {previewImage && (
          <div style={{ textAlign: "center" }}>
            <img
              alt="Preview"
              style={{
                maxHeight: "80vh",
                maxWidth: "100%",
                margin: "auto",
                display: "block",
              }}
              src={previewImage}
            />
            <button
              className="absolute top-0 right-0 m-4 text-xl text-white"
              onClick={() => setPreviewVisible(false)}
            >
              <CloseOutlined />
            </button>
          </div>
        )}
      </Modal>
    </>
  );
};

export default VendorOnboardingBusinessFormComp;
