// src/components/CreateProductForm.js

import React, { useState, useRef } from "react";
import {
  UploadOutlined,
  LoadingOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { notification, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { vendorAuthSignUpBusinessDetails } from "../../apiservice/vendor-authService";
import useFormatApiRequest from "../../hooks/formatApiRequest";
import { useAppDispatch } from "../../Redux/reduxCustomHook";
import { NotificationType } from "../../utils/mscType.type";
import "./vendorProductsComp.css";

const CreateProductForm = () => {
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [loadApi, setLoadApi] = useState(false);
  const [payload, setPayload] = useState<any>({});
  const [formData, setFormData] = useState<any>({});
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const fileInput = useRef<HTMLInputElement>(null);

  const handleInputChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  const handleImageClick = () => {
    if (selectedFile) {
      setPreviewImage(URL.createObjectURL(selectedFile));
      setPreviewVisible(true);
    } else if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const handleRemoveImage = () => {
    setSelectedFile(null);
    setPreviewVisible(false);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    if (!selectedFile) {
      openNotificationWithIcon("error", "", "Please upload a photo", "#FFC2B7");
      return;
    }

    const formData = new FormData();
    if (selectedFile) {
      formData.append("image", selectedFile);
    }
    Object.keys(payload).forEach((key) => {
      formData.append(key, payload[key]);
    });

    setFormData(formData);
    setLoadApi(true);
    setFormLoading(true);
  };

  // A custom hook to format the login Api
  const result = useFormatApiRequest(
    () => vendorAuthSignUpBusinessDetails(formData),
    loadApi,
    () => {
      setLoadApi(false);
    },
    () => {
      processApi();
    }
  );

  // Process Api
  const processApi = async () => {
    if (result.httpState === "SUCCESS") {
      setFormLoading(false);

      setTimeout(() => {
        // Handle Success Here
        navigate("/vendors/home");
      }, 1500);
      openNotificationWithIcon(
        "info",
        "",
        "Your Business Has Been Updated",
        "#D9FFB5"
      );

      // Handle Success Here
    } else if (result.httpState === "ERROR") {
      setFormLoading(false);
      // Handle Error Here
      openNotificationWithIcon(
        "info",
        "",
        result.data?.response?.data?.errors?.[0] ||
          result.data?.response?.data?.message ||
          result.errorMsg ||
          "Login Error",
        "#FFC2B7"
      );
    }
  };

  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  return (
    <>
      {contextHolder}

      <div className="grid p-4">
        <form onSubmit={handleSubmit}>
          <div className="relative w-full mb-6">
            <div className="mb-4">
              <span>Product Name</span>
              <input
                required
                name="title"
                value={payload?.title || ""}
                onChange={(e) =>
                  setPayload({ ...payload, title: e.target.value })
                }
                type="text"
                className="pl-4 pr-4 py-2 border rounded-lg w-full h-16 bg-stone-100"
                placeholder="Product Name"
              />
            </div>
          </div>

          <div className="relative w-full mb-6">
            <div className="mb-4">
              <span>Product Description</span>
              <textarea
                required
                name="description"
                value={payload?.description || ""}
                onChange={(e) =>
                  setPayload({ ...payload, description: e.target.value })
                }
                className="pl-4 pr-4 py-2 border rounded-lg w-full h-16 bg-stone-100"
                placeholder="Product Description"
              />
            </div>
          </div>

          <div className="relative w-full mb-6">
            <div className="mb-4">
              <span>Amount</span>
              <input
                required
                name="amount"
                value={payload?.amount || ""}
                onChange={(e) =>
                  setPayload({ ...payload, amount: e.target.value })
                }
                type="number"
                className="pl-4 pr-4 py-2 border rounded-lg w-full h-16 bg-stone-100"
                placeholder="Amount"
              />
            </div>
          </div>

          <div className="relative w-full mb-6">
            <div className="mb-4">
              <span>Old Amount</span>
              <input
                required
                name="oldAmount"
                value={payload?.oldAmount || ""}
                onChange={(e) =>
                  setPayload({ ...payload, oldAmount: e.target.value })
                }
                type="number"
                className="pl-4 pr-4 py-2 border rounded-lg w-full h-16 bg-stone-100"
                placeholder="Old Amount"
              />
            </div>
          </div>

          <div className="relative w-full mb-6">
            <div className="mb-4">
              <span>Flat Shipping Fees</span>
              <input
                required
                name="flatShippingFees"
                value={payload?.flatShippingFees || ""}
                onChange={(e) =>
                  setPayload({ ...payload, flatShippingFees: e.target.value })
                }
                type="number"
                className="pl-4 pr-4 py-2 border rounded-lg w-full h-16 bg-stone-100"
                placeholder="Flat Shipping Fees"
              />
            </div>
          </div>

          <div className="relative w-full mb-6">
            <div className="mb-4">
              <label htmlFor="category">Product Category</label>
              <select
                id="category"
                className="pl-4 pr-4 py-2 border rounded-lg w-full h-16 bg-stone-100"
                value={payload?.Category}
                onChange={(e) =>
                  setPayload({ ...payload, oldAmount: e.target.value })
                }
              >
                <option value="">Select a category</option>
                <option value="electronics">Electronics</option>
                <option value="clothing">Clothing</option>
                <option value="books">Books</option>
                {/* Add more options as needed */}
              </select>
            </div>
          </div>

          <div className="flex mt-4 mb-4">
            <span className="font-semibold text-lg font-sans">
              Add Product Image
            </span>
          </div>

          <div
            className="flex flex-col items-start justify-center image-container"
            style={{ cursor: "pointer", margin: "5px" }}
          >
            <img
              onClick={handleImageClick}
              className="mt-2 rounded-xl"
              src={
                selectedFile
                  ? URL.createObjectURL(selectedFile)
                  : `${process.env.PUBLIC_URL}/images/vendorOnboarding/profilePic.svg`
              }
              alt="Profile"
              style={{
                objectFit: "cover",
              }}
            />
            {selectedFile && (
              <>
                <br />
                <button
                  type="button"
                  className="bg-red-500 text-white p-1 rounded"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemoveImage();
                  }}
                >
                  Remove
                </button>
              </>
            )}
          </div>

          <input
            ref={fileInput}
            type="file"
            name="profileFile"
            className="hidden"
            accept="image/*"
            onChange={handleInputChange}
          />

          <div className="mb-2 mt-4">
            <button className="w-full h-16 font-sans bg-stone-900 text-white rounded-xl text-lg">
              {!formLoading ? "Save" : <LoadingOutlined rev={undefined} />}
            </button>
          </div>
        </form>
      </div>

      <Modal
        visible={previewVisible}
        footer={null}
        onCancel={() => setPreviewVisible(false)}
        className="full-screen-modal"
        centered
        closable={false}
      >
        {previewImage && (
          <div style={{ textAlign: "center" }}>
            <img
              alt="Preview"
              style={{
                maxHeight: "80vh",
                maxWidth: "100%",
                margin: "auto",
                display: "block",
              }}
              src={previewImage}
            />
            <button
              className="absolute top-0 right-0 m-4 text-xl text-white"
              onClick={() => setPreviewVisible(false)}
            >
              <CloseOutlined />
            </button>
          </div>
        )}
      </Modal>
    </>
  );
};

export default CreateProductForm;
