import React, { useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import "./vendorProductsComp.css";

const VendorProductDetailCard = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="flex flex-col p-2 bg-white border border-gray-300 rounded-lg mb-4">
      <div className="flex items-center">
        {/* Title */}
        <div className="ml-2 flex-grow">
          <h2 className="pt-2 pb-2 font-sans text-lg font-semibold">
            Product Details
          </h2>
        </div>

        {/* Right Edit Icon */}
        <div className="ml-4 flex-shrink-0">
          <button
            className="text-gray-800 hover:text-green"
            onClick={showModal}
          >
            <EditOutlined className="text-2xl" />
          </button>
        </div>
      </div>

      {/* Picture */}
      <div className="flex">
        <img
          src={`https://via.placeholder.com/400`}
          alt="Profile"
          className="w-full h-full object-cover rounded-lg"
          style={{ height: "120px", width: "120px" }}
        />
      </div>

      {/* Product Information  */}
      <div className="flex flex-col">
        <h2 className="text-xl font-semibold mt-4">Product Name</h2>
        <p className="text-gray-500 mt-4">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec
          odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla
          quis sem at nibh elementum imperdiet. Duis sagittis ipsum. Praesent
          mauris. Fusce nec tellus sed augue semper porta. Mauris massa.
          Vestibulum lacinia arcu eget nulla.
        </p>
        <p className="text-gray-500 mt-4">
          {/* Price Field */}
          <strong>Price:</strong> $100
        </p>
        <p className="text-gray-500 mt-4">
          {/* Old Amount Field */}
          <strong>Old Price:</strong> $120
        </p>
        <p className="text-gray-500 mt-4">
          {/* Shipping Fees Field */}
          <strong>Shipping Fees:</strong> $10
        </p>
      </div>

      {/* Edit Profile Modal */}
      <Modal
        title="Edit Products"
        visible={isModalVisible}
        onCancel={handleCancel}
        okText="Save"
        cancelText="Cancel"
        footer={[<p style={{ minHeight: "00px" }}></p>]}
      >
        <div
          style={{ top: "50px", maxHeight: "450px", overflow: "scroll" }}
        ></div>
      </Modal>
    </div>
  );
};

export default VendorProductDetailCard;
