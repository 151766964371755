import { notification } from "antd";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { NotificationType } from "../../utils/mscType.type";
import "./Vendor-Home-Comp.css";

export const VendorHomeActionComp = () => {
  const [api, contextHolder] = notification.useNotification();
  const [link, setLink] = useState<string>("Soft-life.com/Emmanuel");

  const navigate = useNavigate();

  // Handle copying the shortened URL to the clipboard
  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(link);
      openNotificationWithIcon(
        "info",
        "",
        "Link Copied To Clip Board",
        "#D9FFB5"
      );
    } catch (err) {
      console.error("Unable to copy to clipboard.", err);
      alert("Copy to clipboard failed.");
    }
  };

  const goToPage = (location: string) => {
    navigate(location);
  };

  // Show notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  return (
    <>
      {/* The context is used to hold the notification from ant design */}
      {contextHolder}
      <div className="grid p-4">
        {/* Set Up Payment */}
        <h2 className="pt-2 pb-2 font-sans text-lg font-semibold">
          Set Up Online Booking
        </h2>
        <div
          onClick={() => {
            goToPage("/vendors/profile-settings-bank-details");
          }}
          className="flex p-4 border-2 border-gray-400 rounded-lg mb-6"
        >
          <div className="w-40 flex items-center justify-center">
            <img
              className="w-35 rounded-xl"
              alt="_img"
              src={`${
                process.env.PUBLIC_URL +
                "/images/vendorDashboard/vendorPaymentIcon.svg"
              }`}
            />
          </div>
          <div className="flex grow flex-col justify-center items-start">
            <h2 className="text-lg font-sans font-semibold pb-2">
              Set up Payments
            </h2>
            <p className="text-xs font-sans">
              Morem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
              vulputate libero et velit interdum, ac aliquet odio mattis.
            </p>
          </div>
        </div>

        {/* Set Up Invitation */}
        <div className="flex flex-col p-4 border border-gray-400 rounded-lg mb-6">
          <div className="flex flex-col justify-center items-center mt-2">
            <h2 className="mb-4 font-bold">Invites Client to book</h2>
            <p className="text-sm font-sans">
              Save time and invite your clients to book
            </p>
          </div>

          <div className="flex w-full mt-2">
            <div className="flex grow">
              <input
                readOnly
                value={link}
                className="w-full border h-12 rounded-lg text-sm px-2 py-2"
              />
            </div>
            <div className="basis-16 flex items-center justify-center">
              <button
                className="w-full border h-12 bg-black rounded-lg text-sm px-2 py-2 text-white"
                onClick={handleCopyClick}
              >
                Copy
              </button>
            </div>
          </div>
        </div>

        {/* Invite client to book  */}
        <div className="flex flex-col items-center p-4 border border-gray-400 rounded-lg mb-6">
          <h2 className="mb-4 font-bold text-center">
            Add Appointment To get Started
          </h2>

          <button
            onClick={() => {
              goToPage("/vendors/calendar");
            }}
            className="w-full p-2 bg-black text-white font-semibold font-sans rounded"
          >
            Add Appoinment
          </button>
        </div>
      </div>
    </>
  );
};

export default VendorHomeActionComp;
