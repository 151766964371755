import { LoadingOutlined } from "@ant-design/icons";
import { useState } from "react";
import { sampleApiCall } from "../../apiservice/authService";
import useFormatApiRequest from "../../hooks/formatApiRequest";
import "./Vendor-Appointment-Comp.css";

type ICreateAppointmentForm = {
  onFormSuccess?: () => void;
  onFormFailure?: (err?: string) => void;
};

const serviceOptions = [
  { value: "service1", label: "Service 1" },
  { value: "service2", label: "Service 2" },
];

const clientOptions = [
  { value: "client1", label: "Client 1" },
  { value: "client2", label: "Client 2" },
];

export const CreateAppointmentForm: React.FC<ICreateAppointmentForm> = ({
  onFormFailure,
  onFormSuccess,
}) => {
  const [loadApi, setLoadApi] = useState(false);
  const [payLoad, setpayLoad] = useState<any>({});
  const [formLoading, setFormLoading] = useState<boolean>(false);

  // Use to collect input changes
  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const name = event.target.name;
    const value = event.target.value;
    setpayLoad((values) => ({ ...values, [name]: value }));
  };

  // Use to submit form
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoadApi(true);
    setFormLoading(true);
  };

  // A custom hook to process form
  const result = useFormatApiRequest(
    () => sampleApiCall(payLoad),
    loadApi,
    () => {
      setLoadApi(false);
    },
    () => {
      processFormApi();
    }
  );

  // Process API response
  const processFormApi = async () => {
    if (result.httpState === "SUCCESS") {
      setFormLoading(false);
      if (onFormSuccess) {
        onFormSuccess();
      }
      alert("Appointment Added");
    } else if (result.httpState === "ERROR") {
      setFormLoading(false);
      if (onFormFailure) {
        onFormFailure(result.data?.response?.data?.message || result.errorMsg);
        alert(result.data?.response?.data?.message || result.errorMsg);
      }
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        {/* Client ID */}
        <div className="grid mt-4">
          <div>
            <span>
              Seleect Client <span className="w3-text-red">*</span>
            </span>
          </div>
          <select
            required
            name="clientId"
            value={payLoad?.clientId || ""}
            onChange={handleInputChange}
            className="pl-4 pr-4 py-2 border rounded-lg w-full h-16 bg-stone-100"
            style={{ width: "100%", height: "50px" }}
          >
            <option value="">Select Client</option>
            {clientOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>

        {/* Service ID */}
        <div className="grid mt-4">
          <div>
            <span>
              Select Service <span className="w3-text-red">*</span>
            </span>
          </div>
          <select
            required
            name="serviceId"
            value={payLoad?.serviceId || ""}
            onChange={handleInputChange}
            className="pl-4 pr-4 py-2 border rounded-lg w-full h-16 bg-stone-100"
            style={{ width: "100%", height: "50px" }}
          >
            <option value="">Select Service</option>
            {serviceOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>

        {/* Price */}
        <div className="grid mt-4">
          <div>
            <span>
              Price <span className="w3-text-red">*</span>
            </span>
          </div>
          <input
            required
            name="price"
            value={payLoad?.price || ""}
            onChange={handleInputChange}
            type="number"
            className="pl-4 pr-4 py-2 border rounded-lg w-full h-16 bg-stone-100"
            placeholder="Price"
            readOnly
            style={{ width: "100%", height: "50px" }}
          />
        </div>

        {/* Description */}
        <div className="grid mt-4">
          <div>
            <span>
              Description <span className="w3-text-red">*</span>
            </span>
          </div>
          <textarea
            required
            name="description"
            value={payLoad?.description || ""}
            onChange={handleInputChange}
            className="pl-4 pr-4 py-2 border rounded-lg w-full h-16 bg-stone-100"
            placeholder="Description"
            style={{ width: "100%", height: "100px" }}
          />
        </div>

        {/* Date and Time */}
        <div className="grid mt-4">
          <div>
            <span>
              Date and Time <span className="w3-text-red">*</span>
            </span>
          </div>
          <input
            required
            name="dateandtime"
            value={payLoad?.dateandtime || ""}
            onChange={handleInputChange}
            type="datetime-local"
            className="pl-4 pr-4 py-2 border rounded-lg w-full h-16 bg-stone-100"
            placeholder="Date and Time"
            style={{ width: "100%", height: "50px" }}
          />
        </div>

        {/* Submit Button */}
        <div className="flex items-center justify-end mt-4">
          <button
            className="h-12 rounded bg-black text-white p-2 px-5"
            disabled={formLoading}
          >
            {!formLoading ? (
              "Save Appointment"
            ) : (
              <LoadingOutlined rev={undefined} />
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateAppointmentForm;
