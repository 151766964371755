import { LoadingOutlined, MailOutlined } from "@ant-design/icons";
import { notification } from "antd";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { sampleApiCall } from "../../../apiservice/authService";
import { IAuthType } from "../../../apiservice/authService.type";
import useFormatApiRequest from "../../../hooks/formatApiRequest";
import { useAppDispatch } from "../../../Redux/reduxCustomHook";
import { NotificationType } from "../../../utils/mscType.type";
import "../userAuth.css";
import UserAuthHeader from "../userAuthHeader";

const UserForgetPassword = () => {
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [loadApi, setLoadApi] = useState(false);
  const [user, setUser] = useState<any>({});
  const [api, contextHolder] = notification.useNotification();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Use to collect Site Description Change
  const handleInputChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setUser((values: any) => ({ ...values, [name]: value }));
  };

  // Use to Submit Form
  const handleSubmit = (event: any) => {
    event.preventDefault();
    setLoadApi(true);
    setFormLoading(true);
  };

  // A custom hook to format the login Api
  const result = useFormatApiRequest(
    () => sampleApiCall(user),
    loadApi,
    () => {
      setLoadApi(false);
    },
    () => {
      processApi();
    }
  );

  // Process Api
  const processApi = async () => {
    if (result.httpState === "SUCCESS") {
      setFormLoading(false);
      const signinResult: IAuthType = result.data;
      // storePlainString(USER_TOKEN_KEY, signinResult?.data?.token || "");
      // storeJSON(USER_AUTH_DATA_KEY, signinResult);
      // dispatch({ type: "AUTH_ADD_DATA", payload: signinResult });

      setTimeout(() => {
        navigate("/auth/user-setnew-password");
      }, 1500);
      openNotificationWithIcon(
        "info",
        "",
        "We will email you password reset Instructions",
        "#D9FFB5"
      );

      // Handle Success Here
    } else if (result.httpState === "ERROR") {
      setFormLoading(false);
      //Handle Error Here
      openNotificationWithIcon(
        "info",
        "",
        result.data?.response?.data?.errors?.[0] ||
          result.data?.response?.data?.message ||
          result.errorMsg ||
          "Login Error",
        "#FFC2B7"
      );
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}

      {/* "Main Desgin" */}
      <div className="w3-content" style={{ minHeight: "400px" }}>
        <UserAuthHeader></UserAuthHeader>

        <div className="grid p-4">
          <div className="mb-6">
            <p className=" font-semibold font-sans text-xl">Forget Password</p>
          </div>

          <form onSubmit={handleSubmit}>
            {/* Email Address */}
            <div className="relative w-full mb-6">
              <input
                required
                name="email"
                value={user?.email}
                onChange={handleInputChange}
                type="email"
                className="pl-10 pr-4 py-2 border rounded-lg w-full  h-16 bg-stone-100"
                placeholder="Email Address"
              />
              <div
                className="absolute inset-y-0 left-0 pl-3  
                    flex items-center  
                    pointer-events-none"
              >
                <MailOutlined />
              </div>
            </div>

            {/* Forget Password Button */}
            <div className="mb-2">
              <button className="w-full h-16  font-sans bg-green text-white rounded-xl text-lg">
                {!formLoading ? (
                  "Request Password Change"
                ) : (
                  <LoadingOutlined rev={undefined} />
                )}
              </button>
            </div>

            {/* UnderLine */}
            <div className="mb-6 border-b-2 border-green">
              <br />
            </div>

            {/* Go To Login */}
            <div className="mb-2">
              <Link to={"/auth/signin"}>
                <button className="w-full font-sans h-16 border  border-green text-green rounded-xl text-lg">
                  Login to My Account
                </button>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default UserForgetPassword;
