import React, { useState, useEffect } from "react";
import {
  UploadOutlined,
  SendOutlined,
  ExclamationCircleOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { Modal, Button } from "antd";
import Pusher from "pusher-js";
import axios from "axios";
import EmojiPicker from "emoji-picker-react";
import { IAuthType } from "../../apiservice/authService.type";
import { IVendorAuthType } from "../../apiservice/vendor-authService.type";
import { useAppSelector } from "../../Redux/reduxCustomHook";
import { RootState } from "../../Redux/store";
import "./message-Comp.css";

const { confirm } = Modal;

const typeColor = {
  user: "green",
  vendor: "black",
};

type IChatMessageType = {
  userType?: "user" | "vendors";
};

const ChatMessageComp: React.FC<IChatMessageType> = ({ userType = "user" }) => {
  const [messages, setMessages] = useState([
    {
      id: "user-1002-1627890123456",
      text: "Hello!",
      userID: "1002",
      media: null,
      time: "10:00 AM",
      status: "delivered",
    },
    {
      id: "user-1001-1627890123457",
      text: "Hi! How are you?",
      userID: "1001",
      media: null,
      time: "10:01 AM",
      status: "sent",
    },
  ]);

  const [newMessage, setNewMessage] = useState("");
  const [selectedMedia, setSelectedMedia] = useState<any>(null);
  const [mediaPreview, setMediaPreview] = useState<any>(null);
  const [emojiPickerVisible, setEmojiPickerVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [timeoutId, setTimeoutId] = useState<any>(null);

  const [myID, setMyID] = useState("1001");
  const [channelName] = useState("chat");
  const [messageEventName] = useState("message");

  const authData: IAuthType = useAppSelector(
    (state: RootState) => state?.AuthData
  );

  const vendorAuthData: IVendorAuthType = useAppSelector(
    (state: RootState) => state?.VendorAuthData
  );

  useEffect(() => {
    // Update Channel, And User Ids
    setMyID(
      userType === "user"
        ? `${userType}-${authData?.data?.credentials?.id}`
        : `${userType}-${vendorAuthData?.data?.credentials?.id}`
    );
  }, [userType, authData, vendorAuthData]);

  useEffect(() => {
    const pusher = new Pusher(
      process.env.REACT_PUSHER_KEY || "4ab8ef9b253595e3405d",
      {
        cluster: process.env.REACT_PUSHER_CLUSTER || "eu",
      }
    );

    const channel = pusher.subscribe(channelName);
    channel.bind(messageEventName, (data) => {
      if (!messages.find((message) => message.id === data.id)) {
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            id: data.id,
            text: data.message,
            userID: data.userID,
            media: data.media || null,
            time: new Date().toLocaleTimeString(),
            status: "delivered",
          },
        ]);
      }
    });

    return () => {
      pusher.unsubscribe(channelName);
    };
  }, [messages, myID]);

  const generateUniqueMessageID = () => {
    return `${userType}-${myID}-${Date.now()}`;
  };

  const handleSendMessage = async () => {
    if (newMessage.trim() !== "" || selectedMedia) {
      const uniqueMessageID = generateUniqueMessageID();
      const messageData = {
        channelName,
        eventName: messageEventName,
        data: {
          id: uniqueMessageID,
          message: newMessage,
          userID: myID,
          media: selectedMedia,
        },
      };

      setMessages([
        ...messages,
        {
          id: uniqueMessageID,
          text: newMessage,
          userID: myID,
          media: selectedMedia,
          time: new Date().toLocaleTimeString(),
          status: "sent",
        },
      ]);
      setNewMessage("");

      try {
        const response = await axios.post(
          "https://apisoftlifetest.woodlodge.com.ng/api/v1/misc/pusher/send-message",
          messageData
        );
        console.log(response.data);
      } catch (error) {
        console.error("Error sending message:", error);
      }

      setSelectedMedia(null);
      setMediaPreview(null);
    }
  };

  const handleMediaUpload = (event) => {
    const file = event.target.files[0];
    setSelectedMedia(URL.createObjectURL(file));
    setMediaPreview(URL.createObjectURL(file));
  };

  const handleEmojiClick = (event, emojiObject) => {
    setNewMessage((prevMessage) => prevMessage + event.emoji); // Access emoji using emojiObject.emoji
    setEmojiPickerVisible(false);
  };

  const showDeleteConfirm = (id) => {
    confirm({
      title: "Are you sure you want to delete this message?",
      icon: <ExclamationCircleOutlined />,
      content: "This action cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDeleteMessage(id);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleDeleteMessage = (id) => {
    setMessages((messages) => messages.filter((message) => message.id !== id));
  };

  const showMediaInModal = (media) => {
    setModalContent(media);
    setModalVisible(true);
  };

  const handleLongPress = (id) => {
    // Implement long press handling here
    console.log("Long press detected for message with ID:", id);
    // You can optionally show delete options or perform any action here
    showDeleteConfirm(id);
  };

  const handleLongPressStart = (id) => {
    const timeoutId = setTimeout(() => {
      handleLongPress(id);
    }, 2000); // Adjust the timeout duration as needed
    setTimeoutId(timeoutId);
    return () => clearTimeout(timeoutId);
  };

  return (
    <div className="flex flex-col h-screen bg-gray-100">
      <Modal
        visible={modalVisible}
        footer={null}
        onCancel={() => setModalVisible(false)}
      >
        {modalContent && (
          <img src={modalContent} alt="Media" className="w-full h-full" />
        )}
      </Modal>

      <div
        className={`bg-${typeColor[userType]} text-white p-4 flex items-center justify-between`}
      >
        <div className="flex items-center">
          <Button
            icon={<ArrowLeftOutlined />}
            onClick={() => console.log("Back button clicked")}
            style={{ marginRight: "10px" }}
          />
          <img
            src="https://via.placeholder.com/40"
            alt="Profile"
            className="rounded-full mr-2"
          />
          <h1 className="text-lg font-bold">Chat</h1>
        </div>
        <button className="text-sm">Settings</button>
      </div>

      <div className="flex-grow p-4 overflow-y-auto">
        {messages.map((message) => (
          <div
            key={message.id}
            className={`flex ${
              message.userID === myID ? "justify-end" : "justify-start"
            } mb-2`}
            onTouchStart={() => handleLongPressStart(message.id)}
            onTouchEnd={() => clearTimeout(timeoutId)}
          >
            {message.userID !== myID && (
              <img
                src="https://via.placeholder.com/30"
                alt="Profile"
                className="rounded-full mr-2"
                style={{ height: "30px" }}
              />
            )}
            <div
              className={`max-w-xs p-2 rounded-lg ${
                message.userID === myID
                  ? `bg-${typeColor[userType]} text-white`
                  : "bg-gray-300"
              }`}
            >
              <div className="flex items-center justify-between">
                <div>
                  {message.text}
                  {message.media && (
                    <img
                      src={message.media}
                      alt="Media"
                      className="mt-2 rounded cursor-pointer"
                      onClick={() => showMediaInModal(message.media)}
                    />
                  )}
                </div>
              </div>
              <div className="text-xs mt-2">
                <span>{message.time}</span>
                {message.userID === myID && (
                  <span className="ml-2">
                    {message.status === "sent"
                      ? "✓"
                      : message.status === "delivered"
                      ? "✓✓"
                      : ""}
                  </span>
                )}
              </div>
            </div>
            {message.userID === myID && (
              <img
                src="https://via.placeholder.com/30"
                alt="Profile"
                className="rounded-full ml-2"
                style={{ height: "30px" }}
              />
            )}
          </div>
        ))}
      </div>

      {mediaPreview && (
        <div className="bg-white p-4 flex items-center justify-center">
          <img src={mediaPreview} alt="Preview" className="max-h-40 rounded" />
        </div>
      )}

      <div className="bg-white p-4 flex items-center">
        <textarea
          className="flex-grow border rounded-lg p-2 mr-2 resize-none"
          placeholder="Type a message"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          rows={1}
          style={{ minHeight: "40px", maxHeight: "100px" }}
        />
        <button
          className="mr-2"
          onClick={() => setEmojiPickerVisible(!emojiPickerVisible)}
        >
          😊
        </button>
        {emojiPickerVisible && (
          <div className="absolute bottom-16 right-4">
            <EmojiPicker onEmojiClick={handleEmojiClick} />
          </div>
        )}
        <label htmlFor="media-upload" className="mr-2 cursor-pointer">
          <UploadOutlined className={`text-${typeColor[userType]} text-xl`} />
          <input
            id="media-upload"
            type="file"
            className="hidden"
            accept="image/*,video/*"
            onChange={handleMediaUpload}
          />
        </label>
        <button
          className={`bg-${typeColor[userType]} text-white p-2 rounded-lg`}
          onClick={handleSendMessage}
        >
          <SendOutlined />
        </button>
      </div>
    </div>
  );
};

export default ChatMessageComp;
