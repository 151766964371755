import {
  LoadingOutlined,
  DeleteOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { notification, Modal } from "antd";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { vendorAddImage } from "../../apiservice/vendor-authService";
import { IVendorAuthType } from "../../apiservice/vendor-authService.type";
import useFormatApiRequest from "../../hooks/formatApiRequest";
import useAuth from "../../hooks/useAuth";
import { useAppDispatch, useAppSelector } from "../../Redux/reduxCustomHook";
import { RootState } from "../../Redux/store";
import { NotificationType } from "../../utils/mscType.type";
import "./vendoronboarding.css";

type IVendorOnboardingPhotoFormComp = {
  editMode?: boolean;
};

const VendorOnboardingPhotoFormComp: React.FC<
  IVendorOnboardingPhotoFormComp
> = ({ editMode = false }) => {
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [loadApi, setLoadApi] = useState(false);
  const [formData, setFormData] = useState<any>({});
  const [selectedFiles, setSelectedFiles] = useState<(File | null)[]>([null]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const fileInput = useRef<HTMLInputElement>(null);

  const [loadAuth, setLoadAuth] = useState(true);

  const authVendorData: IVendorAuthType = useAppSelector(
    (state: RootState) => state?.VendorAuthData
  );

  // Use to refresh the Auth State
  const authState = useAuth(loadAuth, () => {
    setLoadAuth(false);
  });

  const handleInputChange = (event: any) => {
    const files = Array.from(event.target.files);
    if (files) {
      const newSelectedFiles: any = [
        ...selectedFiles.filter((file) => file !== null),
        ...files,
      ];
      setSelectedFiles([...newSelectedFiles, null]);
    }
  };

  const handleImageClick = (file: File | null, index: number) => {
    if (file) {
      setPreviewImage(URL.createObjectURL(file));
      setPreviewVisible(true);
    } else if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const handleRemoveImage = (index: number) => {
    const newSelectedFiles = [...selectedFiles];
    newSelectedFiles.splice(index, 1);
    setSelectedFiles(newSelectedFiles);
    setPreviewVisible(false); // Close preview if open
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    const uploadedImagesCount = selectedFiles.filter(
      (file) => file !== null
    ).length;
    if (uploadedImagesCount < 4 && !editMode) {
      openNotificationWithIcon(
        "error",
        "",
        "Please upload at least 4 photos",
        "#FFC2B7"
      );
      return;
    }

    const formData = new FormData();
    selectedFiles.forEach((file, index) => {
      if (file) {
        formData.append(`image`, file);
      }
    });

    setFormData(formData);
    setLoadApi(true);
    setFormLoading(true);
  };

  // A custom hook to format the login Api
  const result = useFormatApiRequest(
    () => vendorAddImage(formData),
    loadApi,
    () => {
      setLoadApi(false);
    },
    () => {
      processApi();
    }
  );

  // Process Api
  const processApi = async () => {
    if (result.httpState === "SUCCESS") {
      setFormLoading(false);
      setTimeout(() => {
        if (!editMode) {
          navigate("/vendors/onboarding-business-title");
        } else {
          setLoadAuth(true);
        }
      }, 1500);
      openNotificationWithIcon(
        "info",
        "",
        "Your Photo Have Been Added",
        "#D9FFB5"
      );

      // Handle Success Here
    } else if (result.httpState === "ERROR") {
      setFormLoading(false);
      openNotificationWithIcon(
        "info",
        "",
        result.data?.response?.data?.errors?.[0] ||
          result.data?.response?.data?.message ||
          result.errorMsg ||
          "Error Uploading Files",
        "#FFC2B7"
      );
    }
  };

  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  return (
    <>
      {contextHolder}

      <div className="grid p-4">
        {!editMode ? (
          <div className="flex mt-4 mb-4">
            <p>
              Horem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
              vulputate libero et velit interdum, ac aliquet odio mattis.
            </p>
          </div>
        ) : null}

        <form onSubmit={handleSubmit}>
          <div className="flex mt-4 mb-4">
            <span className="font-semibold text-lg font-sans">
              {editMode ? "Select Image" : "Choose At Least 4 Photos"}
            </span>
          </div>

          <div className="grid grid-cols-2 mt-4 mb-8 gap-2">
            {selectedFiles.map((file, index) => (
              <div
                key={index}
                className="relative flex items-center justify-center image-container"
                style={{ cursor: "pointer", margin: "5px" }}
                onClick={() => handleImageClick(file, index)}
              >
                <img
                  className="mt-2"
                  src={
                    file
                      ? URL.createObjectURL(file)
                      : `${process.env.PUBLIC_URL}/images/vendorOnboarding/noPhoto.svg`
                  }
                  alt={`Image_${index}`}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "120px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  }}
                />
                {file && (
                  <>
                    <button
                      type="button"
                      className="absolute top-0 right-0 bg-red-500 text-white p-1 rounded"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemoveImage(index);
                      }}
                    >
                      <DeleteOutlined />
                    </button>
                  </>
                )}
              </div>
            ))}
          </div>

          <input
            ref={fileInput}
            type="file"
            name="profileFile"
            className="hidden"
            accept="image/*"
            multiple
            onChange={handleInputChange}
          />

          <div className="mb-2">
            <button
              type="submit"
              className="w-full h-16 font-sans bg-stone-900 text-white rounded-xl text-lg"
            >
              {!formLoading ? (
                editMode ? (
                  "Save"
                ) : (
                  "Next"
                )
              ) : (
                <LoadingOutlined rev={undefined} />
              )}
            </button>
          </div>
        </form>
      </div>

      <Modal
        visible={previewVisible}
        footer={null}
        onCancel={() => setPreviewVisible(false)}
        className="full-screen-modal"
        centered
        closable={false}
      >
        {previewImage && (
          <div style={{ textAlign: "center" }}>
            <img
              alt="Preview"
              style={{
                maxHeight: "80vh",
                maxWidth: "100%",
                margin: "auto",
                display: "block",
              }}
              src={previewImage}
            />
            <button
              className="absolute top-0 right-0 m-4 text-xl text-white"
              onClick={() => setPreviewVisible(false)}
            >
              <CloseOutlined />
            </button>
          </div>
        )}
      </Modal>
    </>
  );
};

export default VendorOnboardingPhotoFormComp;
