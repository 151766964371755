import { Button, Empty, notification, Pagination, Result, Spin } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { sampleApiCall } from "../../apiservice/authService";
import useFormatApiRequest from "../../hooks/formatApiRequest";
import { useAppDispatch } from "../../Redux/reduxCustomHook";
import { ILoadState } from "../../utils/loading.utils.";
import "./shared-Comp.css";
type NotificationType = "success" | "info" | "warning" | "error";

type ITopServiceComp = {
  externalFilter?: any;
  initialDefaultFilter?: any;
  hidePagination?: boolean;
};

export const TopServiceComp: React.FC<ITopServiceComp> = ({
  externalFilter,
  initialDefaultFilter,
  hidePagination = true,
}) => {
  const [servicesLoadState, setServicesLoadState] =
    useState<ILoadState>("loading");
  const [loadservicesData, setLoadservicesData] = useState(true);
  const [servicesDefaultFilter, setServicesDefaultFilter] = useState(
    initialDefaultFilter || {}
  );
  const [tableData, setTableData] = useState<any[]>([]);
  const [api, contextHolder] = notification.useNotification();

  // Pagination Constant/Variables
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const perPage = 10;

  // For Navigator/Redux
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Use Effect to reload API when External Filter has changed
  useEffect(() => {
    if (externalFilter) {
      setServicesDefaultFilter({
        ...servicesDefaultFilter,
        ...externalFilter,
      });
      setLoadservicesData(true);
      setServicesLoadState("loading");
    }
  }, [externalFilter]);

  // A custom hook to Load All services Details
  const servicesDataResult = useFormatApiRequest(
    () => sampleApiCall(servicesDefaultFilter),
    loadservicesData,
    () => {
      setLoadservicesData(false);
    },
    () => {
      processservicesResult();
    }
  );

  // Process The Current Services Data Result
  const processservicesResult = async () => {
    if (servicesDataResult.httpState === "SUCCESS") {
      setTableData(
        servicesDataResult.data?.data || [
          {
            title: "Braid",
            imageUrl: `${
              process.env.PUBLIC_URL + "/images/topService/service1.png"
            }`,
          },
          {
            title: "Loc",
            imageUrl: `${
              process.env.PUBLIC_URL + "/images/topService/service2.png"
            }`,
          },
          {
            title: "Natural Hair",
            imageUrl: `${
              process.env.PUBLIC_URL + "/images/topService/service3.png"
            }`,
          },
          {
            title: "Braid",
            imageUrl: `${
              process.env.PUBLIC_URL + "/images/topService/service4.png"
            }`,
          },
          {
            title: "Loc",
            imageUrl: `${
              process.env.PUBLIC_URL + "/images/topService/service5.png"
            }`,
          },
          {
            title: "Natural Hair",
            imageUrl: `${
              process.env.PUBLIC_URL + "/images/topService/service6.png"
            }`,
          },
        ]
      );
      setServicesLoadState("completed");
      setTotalItems(servicesDataResult.data?.meta?.total || 1);
    } else if (servicesDataResult.httpState === "ERROR") {
      setServicesLoadState("error");
    } else if (servicesDataResult.httpState === "LOADING") {
      setServicesLoadState("loading");
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  // Use to Control Pagination On Change Event
  const onPageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setServicesDefaultFilter({
      ...servicesDefaultFilter,
      page: page,
      perPage: pageSize,
    });
    setLoadservicesData(true);
  };

  // Navigate to the next Page
  const navigateForward = async (index: number) => {
    // dispatch({
    //   type: "TENANT_ADD_SELECTED_Services",
    //   payload: tableData[index],
    // });
    navigate(`/explore/explore-professionals`);
  };

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}
      <div className="grid p-4">
        {/* " Show Loading Indicator" */}
        {servicesLoadState === "loading" && (
          <div className="mt-2 mb-2 flex justify-center items-center pt-20 pb-20">
            <Spin size="large" />
          </div>
        )}

        {/* " Show Loading Error" */}
        {servicesLoadState === "error" && (
          <div className="mt-2 mb-2">
            <Result
              status="500"
              title={<span className="">Error</span>}
              subTitle={
                <span className="">
                  Sorry, something went wrong, it could be a network Related
                  error
                </span>
              }
              extra={
                <Button
                  onClick={() => setLoadservicesData(true)}
                  type="primary"
                >
                  Reload
                </Button>
              }
            />
          </div>
        )}

        {/* " Show No data" */}
        {servicesLoadState === "noData" && (
          <div className="mt-2">
            <Empty></Empty>
          </div>
        )}

        {/* " Show Data " */}
        {servicesLoadState === "completed" && (
          <div className="mt-2">
            <div>
              {/* Title */}
              <div className="mb-4">
                <span className="font-sans text-lg">
                  Find top pros by service
                </span>
              </div>

              {/* Services */}
              <div className="grid">
                <div className="grid grid-cols-3 gap-4">
                  {tableData.map((services, index) => (
                    <>
                      <div key={index}>
                        {/* Cards */}
                        <div
                          onClick={() => {
                            navigateForward(index);
                          }}
                          className="flex flex-col p-4 w-full items-center shadow-lg stone-100 bg-white border border-gray-200 rounded-lg"
                        >
                          <div className="p-1">
                            <img
                              className=""
                              alt="Services"
                              src={services.imageUrl}
                              style={{
                                maxWidth: "100%",
                                maxHeight: "100px",
                              }}
                            />
                          </div>

                          <div className="p-1">
                            <span className="font-sans text-sm mb-8">
                              {services?.title}
                            </span>
                          </div>
                        </div>
                        {/* End  Cards */}
                      </div>
                    </>
                  ))}
                </div>
              </div>
              {/* End Services */}
            </div>

            {!hidePagination && (
              <div className="mt-2">
                <Pagination
                  current={currentPage || 1}
                  onChange={onPageChange}
                  pageSize={perPage}
                  total={totalItems}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
