import TopBar from "../../../components/LayoutComponent/TopBar/topBar";
import VendorOnboardingAvailabilityFormComp from "../../../components/vendorOnboardingComponents/Vendor-onboarding-Availability-Form-comp";
import "../vendorDashboard.css";

const VendorOnboardingAvailabilityPage = () => {
  return (
    <>
      {/* "Main Desgin" */}
      <div className="w3-content" style={{ minHeight: "400px" }}>
        <TopBar
          showBackButton
          displayType={"title"}
          title={"When Are You available"}
        ></TopBar>

        <VendorOnboardingAvailabilityFormComp></VendorOnboardingAvailabilityFormComp>
      </div>
    </>
  );
};

export default VendorOnboardingAvailabilityPage;
