import "./Vendor-Profile-Comp.css";
import VendorTransactionsDetailsCard from "./VendorTransactionsDetailCard";

export const VendorTransactionsDetailsComp = () => {
  return (
    <div className="grid p-4">
     <VendorTransactionsDetailsCard></VendorTransactionsDetailsCard>
    </div>
  );
};

export default VendorTransactionsDetailsComp;
