import { LoadingOutlined } from "@ant-design/icons";
import { useState } from "react";
import { sampleApiCall } from "../../apiservice/authService";
import useFormatApiRequest from "../../hooks/formatApiRequest";
import "./Vendor-Appointment-Comp.css";

type ICreateClientForm = {
  onFormSuccess?: () => void;
  onFormFailure?: (err?: string) => void;
};

export const CreateClientForm: React.FC<ICreateClientForm> = ({
  onFormFailure,
  onFormSuccess,
}) => {
  const [loadApi, setLoadApi] = useState(false);
  const [payLoad, setpayLoad] = useState<any>({});
  const [formLoading, setFormLoading] = useState<boolean>(false);

  // Use to collect input changes
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setpayLoad((values) => ({ ...values, [name]: value }));
  };

  // Use to submit form
  const handleSubmit = (event: any) => {
    event.preventDefault();
    setLoadApi(true);
    setFormLoading(true);
  };

  // A custom hook to process form
  const result = useFormatApiRequest(
    () => sampleApiCall(payLoad),
    loadApi,
    () => {
      setLoadApi(false);
    },
    () => {
      processFormApi();
    }
  );

  // Process API response
  const processFormApi = async () => {
    if (result.httpState === "SUCCESS") {
      setFormLoading(false);
      if (onFormSuccess) {
        onFormSuccess();
      }
      alert("Client Added");
    } else if (result.httpState === "ERROR") {
      setFormLoading(false);
      if (onFormFailure) {
        onFormFailure(result.data?.response?.data?.message || result.errorMsg);
      }
      alert(result.data?.response?.data?.message || result.errorMsg);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        {/* First Name */}
        <div className="grid mt-4">
          <div>
            <span>
              First Name <span className="w3-text-red">*</span>
            </span>
          </div>
          <input
            required
            name="firstName"
            value={payLoad?.firstName || ""}
            onChange={handleInputChange}
            type="text"
            className="pl-4 pr-4 py-2 border rounded-lg w-full h-16 bg-stone-100"
            placeholder="First Name"
            style={{ width: "100%", height: "50px" }}
          />
        </div>

        {/* Last Name */}
        <div className="grid mt-4">
          <div>
            <span>
              Last Name <span className="w3-text-red">*</span>
            </span>
          </div>
          <input
            required
            name="lastName"
            value={payLoad?.lastName || ""}
            onChange={handleInputChange}
            type="text"
            className="pl-4 pr-4 py-2 border rounded-lg w-full h-16 bg-stone-100"
            placeholder="Last Name"
            style={{ width: "100%", height: "50px" }}
          />
        </div>

        {/* Email Address */}
        <div className="grid mt-4">
          <div>
            <span>
              Email Address <span className="w3-text-red">*</span>
            </span>
          </div>
          <input
            required
            name="emailAddress"
            value={payLoad?.emailAddress || ""}
            onChange={handleInputChange}
            type="email"
            className="pl-4 pr-4 py-2 border rounded-lg w-full h-16 bg-stone-100"
            placeholder="Email Address"
            style={{ width: "100%", height: "50px" }}
          />
        </div>

        {/* Phone Number */}
        <div className="grid mt-4">
          <div>
            <span>
              Phone Number <span className="w3-text-red">*</span>
            </span>
          </div>
          <input
            required
            name="phoneNumber"
            value={payLoad?.phoneNumber || ""}
            onChange={handleInputChange}
            type="tel"
            className="pl-4 pr-4 py-2 border rounded-lg w-full h-16 bg-stone-100"
            placeholder="Phone Number"
            style={{ width: "100%", height: "50px" }}
          />
        </div>

        {/* Submit Button */}
        <div className="flex items-center justify-end mt-4">
          <button
            className="h-12 rounded bg-black text-white p-2 px-5"
            disabled={formLoading}
          >
            {!formLoading ? "Save Client" : <LoadingOutlined rev={undefined} />}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateClientForm;
