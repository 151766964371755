import "./Vendor-Profile-Comp.css";
import VendorProfileBusinessAvailabilityComp from "./VendorProfileBusinessAvailabilityComp";
import VendorProfileBusinessComp from "./VendorProfileBusinessComp";
import VendorProfileBusinessImagesComp from "./VendorProfileBusinessImagesComp";
import VendorProfileBusinessServicesComp from "./VendorProfileBusinessServicesComp";
import { VendorProfileDetailsComp } from "./VendorProfileDetailsComp";

export const VendorProfilewrapperComp = () => {
  return (
    <div className="grid p-4">
      <VendorProfileBusinessComp></VendorProfileBusinessComp>
      <VendorProfileDetailsComp></VendorProfileDetailsComp>
      <VendorProfileBusinessImagesComp></VendorProfileBusinessImagesComp>
      <VendorProfileBusinessServicesComp></VendorProfileBusinessServicesComp>
      <VendorProfileBusinessAvailabilityComp></VendorProfileBusinessAvailabilityComp>
    </div>
  );
};

export default VendorProfilewrapperComp;
