import { useEffect } from "react";
import { App } from "@capacitor/app";
import TopBar from "../../../components/LayoutComponent/TopBar/topBar";
import Tabbar from "../../../components/LayoutComponent/TabBar/Tabbar";
import HomeButtomCard from "../../../components/HomeComponents/HomeButtomCard";
import HomeTopCard from "../../../components/HomeComponents/HomeTopCard";
import { TopServiceComp } from "../../../components/sharedcomponents/TopService";
import "../userDashboard.css";

export const UserHomePage = () => {
  useEffect(() => {
    const backHandler = () => {
      App.exitApp();
      return true;
    };
    const backButtonListener = App.addListener("backButton", backHandler);
    return () => {
      backButtonListener.remove();
    };
  }, []);

  return (
    <>
      <div className="w3-content" style={{ minHeight: "400px" }}>
        <TopBar></TopBar>
        <HomeTopCard></HomeTopCard>
        <TopServiceComp></TopServiceComp>
        <HomeButtomCard></HomeButtomCard>
        <Tabbar menuType={'users'}></Tabbar>
      </div>
    </>
  );
};

export default UserHomePage;
