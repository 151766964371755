import TopBar from "../../../components/LayoutComponent/TopBar/topBar";
import VendorOnboardingAddressFormComp from "../../../components/vendorOnboardingComponents/Vendor-onboarding-Address-Form-comp";
import "../vendorDashboard.css";

const VendorOnboardingSetAddressPage = () => {
  return (
    <>
      {/* "Main Desgin" */}
      <div className="w3-content" style={{ minHeight: "400px" }}>
        <TopBar
          showBackButton
          displayType={"title"}
          title={"Continue Registration"}
        ></TopBar>
        <VendorOnboardingAddressFormComp></VendorOnboardingAddressFormComp>
      </div>
    </>
  );
};

export default VendorOnboardingSetAddressPage;
