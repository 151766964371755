import "./Home-Comp.css";

export const HomeButtomCard = () => {
  return (
    <>
      <div className="grid p-4">
        <div className="flex flex-row flex-nowrap bg-stone-400">
          <div className="basis-2/3 p-4">
            <h3 className="font-sans w3-large mb-2">
              Introduce to your friends!
            </h3>
            <p className="font-sans text-xs mb-8">
              Porem ipsum dolor sit amet, consectetur adipiscing
              elit.consectetur adipiscing elit.
            </p>
            <p className="mb-4">
              <span className="font-medium rounded-lg text-xs px-5 py-2.5 me-2 mb-2 text-white bg-black hover:bg-green-500">
                Share
              </span>
            </p>
          </div>

          <div className="basis-1/3 flex justify-end p-4">
            <img
              className="mt-2"
              src={`${
                process.env.PUBLIC_URL + "/images/homePage/homeButtomBanner.svg"
              }`}
              alt="Image_"
              style={{ maxWidth: "100%", maxHeight: "120px" }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeButtomCard;
