import TopBar from "../../../components/LayoutComponent/TopBar/topBar";
import VendorOnboardingPhotoFormComp from "../../../components/vendorOnboardingComponents/Vendor-onboarding-Photos-Form-comp";
import "../vendorDashboard.css";

const VendorOnboardingPhotosPage = () => {
  return (
    <>
      {/* "Main Desgin" */}
      <div className="w3-content" style={{ minHeight: "400px" }}>
        <TopBar
          showBackButton
          displayType={"title"}
          title={"Add Photos of your work"}
        ></TopBar>
        <VendorOnboardingPhotoFormComp></VendorOnboardingPhotoFormComp>
      </div>
    </>
  );
};

export default VendorOnboardingPhotosPage;
