import TopBar from "../../../components/LayoutComponent/TopBar/topBar";
import VendorOnboardingBusinessFormComp from "../../../components/vendorOnboardingComponents/Vendor-onboarding-Business-Form-comp";
import "../vendorDashboard.css";

const VendorOnboardingBusinessTitlePage = () => {
  return (
    <>
      {/* "Main Desgin" */}
      <div className="w3-content" style={{ minHeight: "400px" }}>
        <TopBar
          showBackButton
          displayType={"title"}
          title={"Business Information"}
        ></TopBar>
        <VendorOnboardingBusinessFormComp></VendorOnboardingBusinessFormComp>
      </div>
    </>
  );
};

export default VendorOnboardingBusinessTitlePage;
