import "./Vendor-Appointment-Comp.css";
import VendorAppointmentDetailCard from "./VendorAppointmentDetailCard";

export const VendorAppointmentDetailWrapperComp = () => {
  return <div className="grid p-4">
    <VendorAppointmentDetailCard></VendorAppointmentDetailCard>
  </div>;
};

export default VendorAppointmentDetailWrapperComp;
