import { Button, Empty, notification, Pagination, Result, Spin } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { sampleApiCall } from "../../apiservice/authService";
import useFormatApiRequest from "../../hooks/formatApiRequest";
import { useAppDispatch } from "../../Redux/reduxCustomHook";
import { ILoadState } from "../../utils/loading.utils.";
import "./Prop-Comp.css";
type NotificationType = "success" | "info" | "warning" | "error";

type IPropServicesList = {
  externalFilter?: any;
  initialDefaultFilter?: any;
  hidePagination?: boolean;
};

export const PropServicesList: React.FC<IPropServicesList> = ({
  externalFilter,
  initialDefaultFilter,
  hidePagination = true,
}) => {
  const [propServicesLoadState, setPropServicesLoadState] =
    useState<ILoadState>("loading");
  const [loadpropServicesData, setLoadPropServicesData] = useState(true);
  const [propServicesDefaultFilter, setPropServicesDefaultFilter] = useState(
    initialDefaultFilter || {}
  );
  const [tableData, setTableData] = useState<any[]>([]);
  const [api, contextHolder] = notification.useNotification();

  // Pagination Constant/Variables
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const perPage = 10;

  // For Navigator/Redux
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Use Effect to reload API when External Filter has changed
  useEffect(() => {
    if (externalFilter) {
      setPropServicesDefaultFilter({
        ...propServicesDefaultFilter,
        ...externalFilter,
      });
      setLoadPropServicesData(true);
      setPropServicesLoadState("loading");
    }
  }, [externalFilter]);

  // A custom hook to Load All propServices Details
  const propServicesDataResult = useFormatApiRequest(
    () => sampleApiCall(propServicesDefaultFilter),
    loadpropServicesData,
    () => {
      setLoadPropServicesData(false);
    },
    () => {
      processpropServicesResult();
    }
  );

  // Process The Current PropServices Data Result
  const processpropServicesResult = async () => {
    if (propServicesDataResult.httpState === "SUCCESS") {
      setTableData(
        propServicesDataResult.data?.data || [
          {
            title: "Braid",
            imageUrl: `${
              process.env.PUBLIC_URL + "/images/topService/service1.png"
            }`,
          },
          {
            title: "Loc",
            imageUrl: `${
              process.env.PUBLIC_URL + "/images/topService/service2.png"
            }`,
          },
          {
            title: "Natural Hair",
            imageUrl: `${
              process.env.PUBLIC_URL + "/images/topService/service3.png"
            }`,
          },
          {
            title: "Braid",
            imageUrl: `${
              process.env.PUBLIC_URL + "/images/topService/service4.png"
            }`,
          },
          {
            title: "Loc",
            imageUrl: `${
              process.env.PUBLIC_URL + "/images/topService/service5.png"
            }`,
          },
          {
            title: "Natural Hair",
            imageUrl: `${
              process.env.PUBLIC_URL + "/images/topService/service6.png"
            }`,
          },
        ]
      );
      setPropServicesLoadState("completed");
      setTotalItems(propServicesDataResult.data?.meta?.total || 1);
    } else if (propServicesDataResult.httpState === "ERROR") {
      setPropServicesLoadState("error");
    } else if (propServicesDataResult.httpState === "LOADING") {
      setPropServicesLoadState("loading");
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  // Use to Control Pagination On Change Event
  const onPageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPropServicesDefaultFilter({
      ...propServicesDefaultFilter,
      page: page,
      perPage: pageSize,
    });
    setLoadPropServicesData(true);
  };

  // Navigate to the next Page
  const navigateForward = async (index: number) => {
    // dispatch({
    //   type: "TENANT_ADD_SELECTED_PropServices",
    //   payload: tableData[index],
    // });
    navigate(`/explore/explore-professionals/1/calender`);
  };

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}
      <div className="grid p-4">
        {/* " Show Loading Indicator" */}
        {propServicesLoadState === "loading" && (
          <div className="mt-2 mb-2 flex justify-center items-center pt-20 pb-20">
            <Spin size="large" />
          </div>
        )}

        {/* " Show Loading Error" */}
        {propServicesLoadState === "error" && (
          <div className="mt-2 mb-2">
            <Result
              status="500"
              title={<span className="">Error</span>}
              subTitle={
                <span className="">
                  Sorry, something went wrong, it could be a network Related
                  error
                </span>
              }
              extra={
                <Button
                  onClick={() => setLoadPropServicesData(true)}
                  type="primary"
                >
                  Reload
                </Button>
              }
            />
          </div>
        )}

        {/* " Show No data" */}
        {propServicesLoadState === "noData" && (
          <div className="mt-2">
            <Empty></Empty>
          </div>
        )}

        {/* " Show Data " */}
        {propServicesLoadState === "completed" && (
          <div className="mt-2">
            <div>
              {/* Title */}
              <div className="w3-col mb-4">
                <span className="font-sans font-bold text-xl">
                  Top Services
                </span>
              </div>

              {/* PropServices */}
              <div className="w3-col">
                <div className="grid grid-cols-1 gap-4">
                  {tableData.map((propServices, index) => (
                    <>
                      <div key={index}>
                        {/* Cards */}
                        <div className="flex flex-row p-2 w-full bg-white border border-gray-200 rounded-xl">
                          <div className="p-2 basis-1/2">
                            <h2 className="font-semibold font-sans text-xl mb-2">
                              Samarthan Pyna
                            </h2>
                            <div className="flex flex-row font-light font-sans text-lg text-grey-700  mb-2">
                              <span className="basis-1/3">$15</span>
                              <span className="basis-2/3">2hr : 30 min</span>
                            </div>
                          </div>

                          <div className="basis-1/2 p-2 flex items-center justify-end">
                            <button
                              onClick={() => {
                                navigateForward(index);
                              }}
                              className="font-medium rounded-lg text-xs px-5 py-2.5 me-2 mb-2 text-white bg-black hover:bg-green-500"
                            >
                              Book/See Time
                            </button>
                          </div>
                        </div>
                        {/* End  Cards */}
                      </div>
                    </>
                  ))}
                </div>
              </div>
              {/* End PropServices */}
            </div>

            {!hidePagination && (
              <div className="mt-2">
                <Pagination
                  current={currentPage || 1}
                  onChange={onPageChange}
                  pageSize={perPage}
                  total={totalItems}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
