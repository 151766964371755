import TopBar from "../../../components/LayoutComponent/TopBar/topBar";
import Tabbar from "../../../components/LayoutComponent/TabBar/Tabbar";
import "../userDashboard.css";
import UserProfileSettingComp from "../../../components/userProfileComp/UserrProfileSettingListComp";

export const UserProfilePage = () => {
  return (
    <>
      <div className="w3-content" style={{ minHeight: "400px" }}>
        <TopBar></TopBar>
        <UserProfileSettingComp></UserProfileSettingComp>
        <Tabbar menuType={"users"}></Tabbar>
      </div>
    </>
  );
};

export default UserProfilePage;
