import "../vendorDashboard.css";
import { SmileOutlined } from "@ant-design/icons";
import { Result } from "antd";
import { useAppDispatch } from "../../../Redux/reduxCustomHook";
import { removeItem } from "../../../utils/localStorage";
import { VENDOR_AUTH_DATA_KEY, VENDOR_TOKEN_KEY } from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export const VendorLogout = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    doLogout();
  }, []);

  const doLogout = () => {
    removeItem(VENDOR_TOKEN_KEY);
    removeItem(VENDOR_AUTH_DATA_KEY);
    dispatch({ type: "VENDOR_AUTH_REMOVE_DATA" });
    setTimeout(() => {
      navigate("/");
    }, 1500);
  };

  return (
    <Result
      status="404"
      icon={<SmileOutlined rev={undefined} />}
      title="Logout"
      subTitle="You are Logout"
    />
  );
};

export default VendorLogout;
