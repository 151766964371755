import "../../App.css";
import { Routes, Route } from "react-router-dom";
import Nopage from "../Nopage/Nopage";
import UserSignin from "./userSignin/userSignin";
import UserForgetPassword from "./userForgetPassword/userForgetPassword";
import UserSetNewPassword from "./userSetNewPassword/userSetNewPassword";
import UserSignUp from "./userSignUp/userSignUp";
import UserVerifyEmail from "./userVerifyEmail/userVerifyEmail";

const UserAuthRoutes = () => {
  return (
    <Routes>
      <Route index element={<UserSignin />} />
      <Route path="/signin" element={<UserSignin />} />
      <Route path="/signup" element={<UserSignUp />} />
      <Route path="/verify-email" element={<UserVerifyEmail />} />
      <Route path="/user-forget-password" element={<UserForgetPassword />} />
      <Route path="/user-setnew-password" element={<UserSetNewPassword />} />
      <Route path="*" element={<Nopage />} />
    </Routes>
  );
};

export default UserAuthRoutes;
