import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import {
  Badge,
  Button,
  Empty,
  notification,
  Pagination,
  Result,
  Spin,
} from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { sampleApiCall } from "../../apiservice/authService";
import useFormatApiRequest from "../../hooks/formatApiRequest";
import { useAppDispatch } from "../../Redux/reduxCustomHook";
import { ILoadState } from "../../utils/loading.utils.";
import "./message-Comp.css";
type NotificationType = "success" | "info" | "warning" | "error";

type IVendorChatList = {
  externalFilter?: any;
  initialDefaultFilter?: any;
  hidePagination?: boolean;
};

export const VendorChatList: React.FC<IVendorChatList> = ({
  externalFilter,
  initialDefaultFilter,
  hidePagination = false,
}) => {
  const [chatListsLoadState, setChatListsLoadState] =
    useState<ILoadState>("loading");
  const [loadchatListsData, setLoadChatListsData] = useState(true);
  const [chatListsDefaultFilter, setChatListsDefaultFilter] = useState(
    initialDefaultFilter || {}
  );
  const [tableData, setTableData] = useState<any[]>([]);
  const [api, contextHolder] = notification.useNotification();

  // Pagination Constant/Variables
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const perPage = 10;

  // For Navigator/Redux
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Use Effect to reload API when External Filter has changed
  useEffect(() => {
    if (externalFilter) {
      setChatListsDefaultFilter({
        ...chatListsDefaultFilter,
        ...externalFilter,
      });
      setLoadChatListsData(true);
      setChatListsLoadState("loading");
    }
  }, [externalFilter]);

  // A custom hook to Load All chatLists Details
  const chatListsDataResult = useFormatApiRequest(
    () => sampleApiCall(chatListsDefaultFilter),
    loadchatListsData,
    () => {
      setLoadChatListsData(false);
    },
    () => {
      processchatListsResult();
    }
  );

  // Process The Current ChatLists Data Result

  const processchatListsResult = async () => {
    if (chatListsDataResult.httpState === "SUCCESS") {
      setTableData(
        chatListsDataResult.data?.data || [
          {
            fullName: "Emmanuel Soligbo",
            dateJoined: "2021-06-12",
            image: "https://example.com/images/alice_turner.jpg",
          },
          {
            fullName: "Emmanuel Soligbo",
            dateJoined: "2021-06-12",
            image: "https://example.com/images/alice_turner.jpg",
          },
          {
            fullName: "Emmanuel Soligbo",
            dateJoined: "2021-06-12",
            image: "https://example.com/images/alice_turner.jpg",
          },
          {
            fullName: "Emmanuel Soligbo",
            dateJoined: "2021-06-12",
            image: "https://example.com/images/alice_turner.jpg",
          },
          {
            fullName: "Emmanuel Soligbo",
            dateJoined: "2021-06-12",
            image: "https://example.com/images/alice_turner.jpg",
          },
          {
            fullName: "Emmanuel Soligbo",
            dateJoined: "2021-06-12",
            image: "https://example.com/images/alice_turner.jpg",
          },
        ]
      );
      setChatListsLoadState("completed");
      setTotalItems(chatListsDataResult.data?.meta?.total || 1);
    } else if (chatListsDataResult.httpState === "ERROR") {
      setChatListsLoadState("error");
    } else if (chatListsDataResult.httpState === "LOADING") {
      setChatListsLoadState("loading");
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  // Use to Control Pagination On Change Event
  const onPageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setChatListsDefaultFilter({
      ...chatListsDefaultFilter,
      page: page,
      perPage: pageSize,
    });
    setLoadChatListsData(true);
  };

  // Navigate to the next Page
  const navigateForward = async (index: number) => {
    navigate(`/vendors/message/${index}`);
  };

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}
      <div className="grid pt-4">
        {/* " Show Loading Indicator" */}
        {chatListsLoadState === "loading" && (
          <div className="mt-2 mb-2 flex justify-center items-center pt-20 pb-20">
            <Spin size="large" />
          </div>
        )}

        {/* " Show Loading Error" */}
        {chatListsLoadState === "error" && (
          <div className="mt-2 mb-2">
            <Result
              status="500"
              title={<span className="">Error</span>}
              subTitle={
                <span className="">
                  Sorry, something went wrong, it could be a network Related
                  error
                </span>
              }
              extra={
                <Button
                  onClick={() => setLoadChatListsData(true)}
                  type="primary"
                >
                  Reload
                </Button>
              }
            />
          </div>
        )}

        {/* " Show No data" */}
        {chatListsLoadState === "noData" && (
          <div className="mt-2">
            <Empty></Empty>
          </div>
        )}

        {/* " Show Data " */}
        {chatListsLoadState === "completed" && (
          <div className="mt-2">
            <div>
              {/* ChatLists */}
              <div className="grid grid-cols-1 gap-4">
                {tableData.map((clients, index) => (
                  <>
                    <div key={index}>
                      {/* Cards */}
                      <div
                        className="flex flex-row p-2 w-full bg-white border border-gray-200 rounded-xl"
                        onClick={() => navigateForward(index)}
                      >
                        <p className="w-12 flex items-center justify-start">
                          <img
                            className="w3-circle"
                            style={{ width: "48px" }}
                            src={`${
                              process.env.PUBLIC_URL +
                              "/images/auth/profileSample.png"
                            }`}
                            alt=""
                          />
                        </p>

                        <div className="grow p-2 flex flex-col items-start justify-center">
                          <h2 className="font-semibold font-sans text-md mb-2">
                            {clients?.fullName}
                          </h2>
                          <p className="text-sm font-san">
                            Please I love your service...
                          </p>
                        </div>

                        <p className="w-12 flex flex-col items-end justify-center">
                          <p className="mb-2">
                            <Badge count={4} />
                          </p>
                          <p className="text-sm font-san">Yesterday</p>
                        </p>
                      </div>
                      {/* End  Cards */}
                    </div>
                  </>
                ))}
              </div>
              {/* End ChatLists */}
            </div>

            {!hidePagination && (
              <div className="grid mt-6">
                <Pagination
                  current={currentPage || 1}
                  onChange={onPageChange}
                  pageSize={perPage}
                  total={totalItems}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
