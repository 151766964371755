import React from "react";
import "./Vendor-Appointment-Comp.css";

const VendorAppointmentDetailCard = () => {
  return (
    <>
      <div className="flex flex-col p-2 bg-white border border-gray-300 rounded-lg mb-4">
        <div className="flex items-center">
          {/* Title */}
          <div className="flex-grow">
            <h2 className="pt-2 pb-2 font-sans text-lg font-semibold">
              Appointment Details
            </h2>
          </div>
        </div>

        {/* Order Information  */}
        <div className="flex flex-col">
          <h2 className="text-xl font-semibold mt-4"> Service Name</h2>
          <p className="text-gray-500 mt-4">
            {/* Price Field */}
            <strong>Date:</strong> 15th Jul, 2023, 11:30 Am
          </p>
          <p className="text-gray-500 mt-4">
            {/* Price Field */}
            <strong>Duration:</strong> 1 Hours : 30 Minutes
          </p>
          <p className="text-gray-500 mt-4">
            {/* Price Field */}
            <strong>Price:</strong> $100
          </p>
          <p className="text-gray-500 mt-4">
            {/* Tax Field */}
            <strong>Tax:</strong> $10
          </p>

          <p className="text-gray-500 mt-4">
            {/*   Quantity  */}
            <strong>Total :</strong> $120
          </p>
        </div>
      </div>

      <div className="flex flex-col p-2 bg-white border border-gray-300 rounded-lg mb-4">
        <div className="flex items-center">
          {/* Title */}
          <div className="flex-grow">
            <h2 className="pt-2 pb-2 font-sans text-lg font-semibold">
              Client Information
            </h2>
          </div>
        </div>

        {/* Order Information  */}
        <div className="flex flex-col">
          <h2 className="text-xl font-semibold mt-4">Emmanuel Soligbo</h2>
          <p className="text-gray-500 mt-4">
            {/* Phone Number */}
            <strong>Phone Number</strong> 091481199229
          </p>
          <p className="text-gray-500 mt-4">
            {/* Address */}
            <strong>Address:</strong> 21 Universal Street
          </p>
        </div>
      </div>

      <div className="flex flex-col p-2 bg-white border border-gray-300 rounded-lg mb-4">
        <div className="flex items-center">
          {/* Title */}
          <div className="flex-grow">
            <h2 className="pt-2 pb-2 font-sans text-lg font-semibold">
              Actions
            </h2>
          </div>
        </div>
      </div>
    </>
  );
};

export default VendorAppointmentDetailCard;
