import { LoadingOutlined } from "@ant-design/icons";
import { Spin, Result, Button, Empty } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { sampleApiCall } from "../../apiservice/authService";
import useFormatApiRequest from "../../hooks/formatApiRequest";
import { formatCurrency } from "../../utils/basic.utils";
import { ILoadState } from "../../utils/loading.utils.";
import PaystackPop from "@paystack/inline-js";
import "./Prop-Comp.css";

export const PropsBooking = () => {
  const [loadApi, setLoadApi] = useState(false);
  const [payLoad, setpayLoad] = useState<any>({});
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [paymentDetailsLoadState, setPaymentDetailsLoadState] =
    useState<ILoadState>("loading");

  const [loadPaymentDetails, setLoadPaymentDetails] = useState(true);

  // For Navigator/Redux

  const navigate = useNavigate();

  // Load The Payment Details
  const paymentDetailsDataResult = useFormatApiRequest(
    () => sampleApiCall(),
    loadPaymentDetails,
    () => {
      setLoadPaymentDetails(false);
    },
    () => {
      processPaymentDetailsResult();
    }
  );

  // Process The Current  Payment Details Result
  const processPaymentDetailsResult = async () => {
    if (paymentDetailsDataResult.httpState === "SUCCESS") {
      setPaymentDetailsLoadState("completed");
    } else if (paymentDetailsDataResult.httpState === "ERROR") {
      setPaymentDetailsLoadState("error");
    } else if (paymentDetailsDataResult.httpState === "LOADING") {
      setPaymentDetailsLoadState("loading");
    }
  };

  // Use to collect Input change Change
  const handleInputChange = (event: any) => {
    const name = event.target.name;
    const value =
      name === "agreePayment" ? event.target.checked : event.target.value;
    setpayLoad((values: any) => ({ ...values, [name]: value }));
    console.log(event.target.checked);
  };

  // Use to Submit Form
  const handleSubmit = (event: any) => {
    event.preventDefault();
    // if (!payLoad.agreePayment) {
    //   alert("Please Agree That you accept the tenant Agreement.");
    //   return;
    // }
    setLoadApi(true);
    setFormLoading(true);
  };

  // A custom hook to save form data
  const result = useFormatApiRequest(
    () => sampleApiCall(),
    loadApi,
    () => {
      setLoadApi(false);
    },
    () => {
      processFormApi();
    }
  );

  // Process Api
  const processFormApi = async () => {
    if (result.httpState === "SUCCESS") {
      setFormLoading(false);
      // console.log(result.data);
      payWithPayStack(result.data?.paystackMetadata);
      // Handle Success Here
    } else if (result.httpState === "ERROR") {
      setFormLoading(false);
      alert(result.data?.response?.data?.message || result.errorMsg);
      //Handle Error Here
    }
  };

  const payWithPayStack = (meta: any) => {
    const paystack = new PaystackPop();
    // console.log(meta);
    paystack.newTransaction({
      key: process.env.REACT_APP_PAYSTACK_PK,
      email: "annonymouslinkmie@gmail.com",
      amount: 1000 * 100 || 0,
      currency: "NGN",
      metadata: meta || {},
      onSuccess: (transaction) => {
        alert("Your Payment was succesful, Thank you.");
        navigate("/");
      },
      onCancel: () => {
        console.log("Pop Up closed");
        alert("Sorry we could not process your payment");
      },
    });
  };

  return (
    <>
      <div className="grid p-4">
        {/* " Show Loading Indicator" */}
        {paymentDetailsLoadState === "loading" && (
          <div
            className="mt-2 mb-2 flex justify-center items-center pt-20 pb-20"
            style={{ paddingTop: "100px" }}
          >
            <Spin size="large" />
          </div>
        )}

        {/* " Show Loading Error" */}
        {paymentDetailsLoadState === "error" && (
          <div className="mt-2 mb-2">
            <Result
              status="500"
              title={<span className="">Error</span>}
              subTitle={
                <span className="">
                  Sorry, something went wrong, it could be a network Related
                  error
                </span>
              }
              extra={
                <Button
                  onClick={() => setLoadPaymentDetails(true)}
                  type="primary"
                >
                  Reload
                </Button>
              }
            />
          </div>
        )}

        {/* " Show No data" */}
        {paymentDetailsLoadState === "noData" && (
          <div className="mt-2">
            <Empty></Empty>
          </div>
        )}

        {/* " Show No data" */}
        {paymentDetailsLoadState === "completed" && (
          <div className="font-sans mt-2">
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <h2 className="font-sans font-bold text-xl">Payment Summary</h2>
                <p className="text-sm font-sans">
                  Morem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                  vulputate libero et velit interdum, ac aliquet odio mattis.
                </p>
              </div>
              {/* Forms Here */}
              <div className="grid p-4 bg-stone-400 -500 mt-4 mb-4">
                {/* Details */}

                {/*  Booking Price */}
                <div className="flex mt-2 border-b  border-gray-400 pb-2">
                  <div className="basis-1/2">Booking Fees </div>
                  <div className="basis-1/2 flex items-end justify-end">
                    $15
                  </div>
                </div>

                {/*  Tax */}
                <div className="flex mt-4 border-b  border-gray-400 pb-2">
                  <div className="basis-1/2"> Tax </div>
                  <div className="basis-1/2 flex items-end justify-end">
                    $2.5
                  </div>
                </div>

                {/*  Total Price */}
                <div className="flex mt-4 border-b  border-gray-400 pb-2">
                  <div className="basis-1/2 font-semibold"> Total </div>
                  <div className="basis-1/2 font-semibold flex items-end justify-end">
                    $17.5
                  </div>
                </div>

                <div className="mt-8 font-semibold"> Contact Details </div>

                {/* Full Name */}
                <div className="mt-8">
                  <span className="">Full Name</span>
                  <input
                    required
                    name="fullName"
                    value={payLoad?.fullName}
                    className="w-full h-12 px-2 py-5 me-2 mb-2 mt-1 border border-gray-400 rounded-lg p-2 font-sans text-sm"
                    placeholder="FullName"
                  />
                </div>

                {/* Phone Number */}
                <div className="mt-4">
                  <span className="">Phone Number</span>
                  <input
                    required
                    name="phoneNumber"
                    value={payLoad?.phoneNumber}
                    className="w-full h-12 px-2 py-5 me-2 mb-2 mt-1 border border-gray-400 rounded-lg p-2 font-sans text-sm"
                    placeholder="Phone Number"
                    type={"tel"}
                  />
                </div>

                {/* Email */}
                <div className="mt-4">
                  <span className="">Email</span>
                  <input
                    required
                    name="email"
                    value={payLoad?.email}
                    className="w-full h-12 px-2 py-5 me-2 mb-2 mt-1 border border-gray-400 rounded-lg p-2 font-sans text-sm"
                    placeholder="Email"
                    type={"email"}
                  />
                </div>

                {/* Button Here */}
                <div className="grid mt-4">
                  <button
                    onClick={handleSubmit}
                    className="w-full h-12 font-medium rounded-lg text-xs text-center px-2.5 py-3 me-2 mb-2 text-white bg-green hover:bg-black hover:text-white"
                  >
                    Accept And Book
                  </button>
                </div>
              </div>

              <div className="mb-4">
                <h2 className="font-sans font-bold text-xl">
                  Cancellation Policy
                </h2>
                <p className="text-sm font-sans">
                  Morem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                  vulputate libero et velit interdum, ac aliquet odio mattis.
                </p>
              </div>
            </form>
          </div>
        )}
      </div>
    </>
  );
};

export default PropsBooking;
