import React, { useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import "./Vendor-Profile-Comp.css";
import { useNavigate } from "react-router-dom";

const VendorProfileBusinessAvailabilityComp = () => {
  const navigate = useNavigate();
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const initialAvailability = daysOfWeek.map((day) => ({
    day,
    start: "09:00",
    end: "18:00",
  }));

  const [availability, setAvailability] = useState(initialAvailability);

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className=" p-2 bg-white border border-gray-300 rounded-lg mb-4">
      <div className="flex items-center">
        {/* Title */}
        <div className="ml-2 flex-grow">
          <h2 className="pt-2 pb-2 font-sans text-lg font-semibold">
            Availability
          </h2>
        </div>

        {/* Right Edit Icon */}
        <div className="ml-4 flex-shrink-0">
          <button
            onClick={() => {
              handleNavigation("/vendors/profile-update-availability");
            }}
            className="text-gray-800 hover:text-green"
          >
            <EditOutlined className="text-2xl" />
          </button>
        </div>
      </div>

      <div className="flex flex-wrap">
        <div className="p-4 ">
          {availability.map(({ day, start, end }) => (
            <div key={day} className="flex items-center justify-between my-2">
              <span className="text-gray-700">{day}</span>
              <div className="flex items-center">
                <span className="border rounded p-1 mx-1 bg-gray-100">
                  {start || "--:--"}
                </span>
                <span className="mx-1">to</span>
                <span className="border rounded p-1 mx-1 bg-gray-100">
                  {end || "--:--"}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VendorProfileBusinessAvailabilityComp;
