import { LoadingOutlined, LockOutlined, MailOutlined } from "@ant-design/icons";
import { notification } from "antd";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  authConfirmToken,
  sampleApiCall,
} from "../../../apiservice/authService";
import { IAuthType } from "../../../apiservice/authService.type";
import {
  vendorAuthConfirmToken,
  vendorAuthSendverifyEmail,
} from "../../../apiservice/vendor-authService";
import useFormatApiRequest from "../../../hooks/formatApiRequest";
import useAuth from "../../../hooks/useAuth";
import { useAppDispatch, useAppSelector } from "../../../Redux/reduxCustomHook";
import { RootState } from "../../../Redux/store";
import { NotificationType } from "../../../utils/mscType.type";
import "../vendorAuth.css";
import VendorAuthHeader from "../vendorAuthHeader";

const VendorVerifyEmail = () => {
  const [loadTokenApi, setLoadTokenApi] = useState(true);
  const [formLoading, setFormLoading] = useState<boolean>(true);
  const [loadApi, setLoadApi] = useState(false);
  const [payload, setPayload] = useState<any>({});

  const navigate = useNavigate();
  const authData: any = useAppSelector((state: RootState) => state);
  const [loadAuth, setLoadAuth] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  // Use to collect Site Description Change
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setPayload((values) => ({ ...values, [name]: value }));
  };

  // Use to Submit Form
  const handleSubmit = (event: any) => {
    event.preventDefault();
    setLoadApi(true);
    setFormLoading(true);
  };

  // Use to refresh the Auth State
  const authState = useAuth(loadAuth, () => {
    setLoadAuth(false);
  });

  // A custom hook to Send Token
  const resultSendToken = useFormatApiRequest(
    () => vendorAuthSendverifyEmail(),
    loadTokenApi,
    () => {
      setLoadTokenApi(false);
    },
    () => {
      processTokenApiResult();
    }
  );

  // Process Api Token Result
  const processTokenApiResult = async () => {
    if (resultSendToken.httpState === "SUCCESS") {
      setFormLoading(false);
      // Handle Success Here
      openNotificationWithIcon(
        "info",
        "",
        "A token has being sent to your email",
        "#D9FFB5"
      );
    } else if (resultSendToken.httpState === "ERROR") {
      setFormLoading(false);
      //Handle Error Here
      openNotificationWithIcon(
        "info",
        "",
        result.data?.response?.data?.message ||
          result.errorMsg ||
          "Error in sending token",
        "#FFC2B7"
      );
    }
  };

  // A custom hook to format confirm OTP
  const result = useFormatApiRequest(
    () => vendorAuthConfirmToken(payload),
    loadApi,
    () => {
      setLoadApi(false);
    },
    () => {
      processApi();
    }
  );

  // Process Api
  const processApi = async () => {
    if (result.httpState === "SUCCESS") {
      setFormLoading(false);
      setLoadAuth(true);
      // Handle Success Here
      openNotificationWithIcon(
        "info",
        "",
        "Your Email has being verified",
        "#D9FFB5"
      );
      setTimeout(() => {
        navigate("/vendors/onboarding-address");
      }, 2500);
    } else if (result.httpState === "ERROR") {
      setFormLoading(false);
      //Handle Error Here
      openNotificationWithIcon(
        "info",
        "",
        result.data?.response?.data?.errors?.[0] ||
          result.data?.response?.data?.message ||
          result.errorMsg ||
          "Wrong Token",
        "#FFC2B7"
      );

      // Temporary Success
      navigate("/vendors/onboarding-address");
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}

      {/* "Main Desgin" */}
      <div className="w3-content" style={{ minHeight: "400px" }}>
        <VendorAuthHeader></VendorAuthHeader>

        <div className="grid p-4">
          <div className="mb-6">
            <p className=" font-semibold font-sans text-xl">Verify Account </p>
          </div>

          <form onSubmit={handleSubmit}>
            {/* Code */}
            <div className="relative w-full mb-2">
              <input
                required
                name="token"
                value={payload?.token}
                onChange={handleInputChange}
                type="token"
                className="pl-10 pr-4 py-2 border rounded-lg w-full h-16 bg-stone-100"
                placeholder="Verification Code"
              />
              <div
                className="absolute inset-y-0 left-0 pl-3  
                    flex items-center  
                    pointer-events-none"
              >
                <LockOutlined />
              </div>
            </div>

            {/* Resend Token  */}
            <div className="flex items-end justify-end mb-6">
              <span
                onClick={() => {
                  setLoadTokenApi(true);
                  setFormLoading(true);
                }}
                className="text-stone-700 font-sans"
              >
                {!formLoading ? (
                  "Resend Code"
                ) : (
                  <LoadingOutlined rev={undefined} />
                )}
              </span>
            </div>

            {/* Verify Button */}
            <div className="mb-2">
              <button className="w-full h-16  font-sans bg-stone-900 text-white rounded-xl text-lg">
                {!formLoading ? (
                  "Verify Account"
                ) : (
                  <LoadingOutlined rev={undefined} />
                )}
              </button>
            </div>

            {/* UnderLine */}
            <div className="mb-6 border-b-2 border-black">
              <br />
            </div>

            {/* Go To Login */}
            <div className="mb-2">
              <Link to={"/vendor-auth/signin"}>
                <button className="w-full font-sans h-16 border  border-stone-900 text-black rounded-xl text-lg">
                  Login to My Business
                </button>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default VendorVerifyEmail;
