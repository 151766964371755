import { PlusCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Empty,
  Modal,
  notification,
  Pagination,
  Result,
  Spin,
} from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { vendorGetSubServices } from "../../apiservice/vendor-authService";
import { IVendorSubServiceData } from "../../apiservice/vendor-authService.type";
import useFormatApiRequest from "../../hooks/formatApiRequest";
import { useAppDispatch } from "../../Redux/reduxCustomHook";
import { appZIndex } from "../../utils/appconst";
import { ILoadState } from "../../utils/loading.utils.";
import VendorOnboardingServicesFormComp from "./Vendor-onboarding-Service-Form-comp";
import "./vendoronboarding.css";
type NotificationType = "success" | "info" | "warning" | "error";

type IVendorServiceSubCategoryList = {
  externalFilter?: any;
  initialDefaultFilter?: any;
  hidePagination?: boolean;
};

export const VendorServiceSubCategoryList: React.FC<
  IVendorServiceSubCategoryList
> = ({ externalFilter, initialDefaultFilter, hidePagination = true }) => {
  const [serviceSubCategoriesLoadState, setServiceSubCategoriesLoadState] =
    useState<ILoadState>("loading");
  const [loadserviceSubCategoriesData, setLoadServiceSubCategoriesData] =
    useState(true);
  const [
    serviceSubCategoriesDefaultFilter,
    setServiceSubCategoriesDefaultFilter,
  ] = useState(initialDefaultFilter || {});
  const [subServiceIndex, setSubServiceIndex] = useState<number>(-1);
  const [tableData, setTableData] = useState<IVendorSubServiceData[]>([]);
  const [api, contextHolder] = notification.useNotification();

  // Pagination Constant/Variables
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const perPage = 10;

  // For Navigator/Redux
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState<boolean>(false);

  const handleShowModal = (index) => {
    setSubServiceIndex(index);
    setShowModal(true);
  };

  const handleCancelModal = () => {
    setShowModal(false);
  };

  // Use Effect to reload API when External Filter has changed
  useEffect(() => {
    if (externalFilter) {
      setServiceSubCategoriesDefaultFilter({
        ...serviceSubCategoriesDefaultFilter,
        ...externalFilter,
      });
      setLoadServiceSubCategoriesData(true);
      setServiceSubCategoriesLoadState("loading");
    }
  }, [externalFilter]);

  // A custom hook to Load All serviceSubCategories Details
  const serviceSubCategoriesDataResult = useFormatApiRequest(
    () => vendorGetSubServices(serviceSubCategoriesDefaultFilter),
    loadserviceSubCategoriesData,
    () => {
      setLoadServiceSubCategoriesData(false);
    },
    () => {
      processserviceSubCategoriesResult();
    }
  );

  // Process The Current ServiceSubCategories Data Result
  const processserviceSubCategoriesResult = async () => {
    if (serviceSubCategoriesDataResult.httpState === "SUCCESS") {
      setTableData(serviceSubCategoriesDataResult.data?.data || []);
      setServiceSubCategoriesLoadState("completed");
      setTotalItems(serviceSubCategoriesDataResult.data?.meta?.total || 1);
    } else if (serviceSubCategoriesDataResult.httpState === "ERROR") {
      setServiceSubCategoriesLoadState("error");
    } else if (serviceSubCategoriesDataResult.httpState === "LOADING") {
      setServiceSubCategoriesLoadState("loading");
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  // Use to Control Pagination On Change Event
  const onPageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setServiceSubCategoriesDefaultFilter({
      ...serviceSubCategoriesDefaultFilter,
      page: page,
      perPage: pageSize,
    });
    setLoadServiceSubCategoriesData(true);
  };

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}
      <div className="grid">
        {/* " Show Loading Indicator" */}
        {serviceSubCategoriesLoadState === "loading" && (
          <div className="mt-2 mb-2 flex justify-center items-center pt-20 pb-20">
            <Spin size="large" />
          </div>
        )}

        {/* " Show Loading Error" */}
        {serviceSubCategoriesLoadState === "error" && (
          <div className="mt-2 mb-2">
            <Result
              status="500"
              title={<span className="">Error</span>}
              subTitle={
                <span className="">
                  Sorry, something went wrong, it could be a network Related
                  error
                </span>
              }
              extra={
                <Button
                  onClick={() => setLoadServiceSubCategoriesData(true)}
                  type="primary"
                >
                  Reload
                </Button>
              }
            />
          </div>
        )}

        {/* " Show No data" */}
        {serviceSubCategoriesLoadState === "noData" && (
          <div className="mt-2">
            <Empty></Empty>
          </div>
        )}

        {/* " Show Data " */}
        {serviceSubCategoriesLoadState === "completed" && (
          <div className="mt-2">
            <div>
              {/* ServiceSubCategories */}
              <div className="w3-col">
                <div className="grid grid-cols-1 gap-y-2">
                  {tableData.map((serviceSubCategories, index) => (
                    <>
                      <div key={index}>
                        {/* Cards */}
                        <div className="flex  items-center justify-start p-1 w-full bg-white border border-gray-200 rounded-xl">
                          <div className="p-2 basis-3/4">
                            <span>{serviceSubCategories.title}</span>
                          </div>

                          <div className="basis-1/4 p-2 flex items-center justify-end">
                            <span
                              onClick={() => {
                                handleShowModal(index);
                              }}
                              className="font-medium rounded-lg text-xs px-5 py-2.5 me-2 mb-2 text-white bg-black hover:bg-green-500"
                            >
                              <PlusCircleOutlined />
                            </span>
                          </div>
                        </div>
                        {/* End  Cards */}
                      </div>
                    </>
                  ))}
                </div>
              </div>
              {/* End ServiceSubCategories */}
            </div>

            {!hidePagination && (
              <div className="mt-2">
                <Pagination
                  current={currentPage || 1}
                  onChange={onPageChange}
                  pageSize={perPage}
                  total={totalItems}
                />
              </div>
            )}
          </div>
        )}
      </div>

      {/* Modal */}
      <Modal
        zIndex={appZIndex.modal}
        open={showModal}
        title={"Add Service"}
        onCancel={handleCancelModal}
        width={700}
        footer={[<p style={{ minHeight: "00px" }}></p>]}
      >
        <div style={{ maxHeight: "400px", overflow: "scroll" }}>
          <VendorOnboardingServicesFormComp
            title={tableData[subServiceIndex]?.title}
            subServiceId={tableData[subServiceIndex]?.id}
          ></VendorOnboardingServicesFormComp>
        </div>
      </Modal>
    </>
  );
};
