import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { Button, Empty, notification, Pagination, Result, Spin } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { sampleApiCall } from "../../apiservice/authService";
import useFormatApiRequest from "../../hooks/formatApiRequest";
import { useAppDispatch } from "../../Redux/reduxCustomHook";
import { ILoadState } from "../../utils/loading.utils.";
import "./Vendor-Appointment-Comp.css";
type NotificationType = "success" | "info" | "warning" | "error";

type IVendorAppointmentList = {
  externalFilter?: any;
  initialDefaultFilter?: any;
  hidePagination?: boolean;
};

export const VendorAppointmentList: React.FC<IVendorAppointmentList> = ({
  externalFilter,
  initialDefaultFilter,
  hidePagination = false,
}) => {
  const [appointmentLoadState, setAppointmentLoadState] =
    useState<ILoadState>("loading");
  const [loadappointmentData, setLoadAppointmentData] = useState(true);
  const [appointmentDefaultFilter, setAppointmentDefaultFilter] = useState(
    initialDefaultFilter || {}
  );
  const [tableData, setTableData] = useState<any[]>([]);
  const [api, contextHolder] = notification.useNotification();

  // Pagination Constant/Variables
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const perPage = 10;

  // For Navigator/Redux
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Use Effect to reload API when External Filter has changed
  useEffect(() => {
    if (externalFilter) {
      setAppointmentDefaultFilter({
        ...appointmentDefaultFilter,
        ...externalFilter,
      });
      setLoadAppointmentData(true);
      setAppointmentLoadState("loading");
    }
  }, [externalFilter]);

  // A custom hook to Load All appointment Details
  const appointmentDataResult = useFormatApiRequest(
    () => sampleApiCall(appointmentDefaultFilter),
    loadappointmentData,
    () => {
      setLoadAppointmentData(false);
    },
    () => {
      processappointmentResult();
    }
  );

  // Process The Current Appointment Data Result
  const processappointmentResult = async () => {
    if (appointmentDataResult.httpState === "SUCCESS") {
      setTableData(appointmentDataResult.data?.data || [{}, {}, {}]);
      setAppointmentLoadState("completed");
      setTotalItems(appointmentDataResult.data?.meta?.total || 1);
    } else if (appointmentDataResult.httpState === "ERROR") {
      setAppointmentLoadState("error");
    } else if (appointmentDataResult.httpState === "LOADING") {
      setAppointmentLoadState("loading");
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  // Use to Control Pagination On Change Event
  const onPageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setAppointmentDefaultFilter({
      ...appointmentDefaultFilter,
      page: page,
      perPage: pageSize,
    });
    setLoadAppointmentData(true);
  };

  // Navigate to the next Page
  const navigateForward = async (index: number) => {
    navigate(`/vendors/calendar/${index}`);
  };


  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}
      <div className="grid pt-4">
        {/* " Show Loading Indicator" */}
        {appointmentLoadState === "loading" && (
          <div className="mt-2 mb-2 flex justify-center items-center pt-20 pb-20">
            <Spin size="large" />
          </div>
        )}

        {/* " Show Loading Error" */}
        {appointmentLoadState === "error" && (
          <div className="mt-2 mb-2">
            <Result
              status="500"
              title={<span className="">Error</span>}
              subTitle={
                <span className="">
                  Sorry, something went wrong, it could be a network Related
                  error
                </span>
              }
              extra={
                <Button
                  onClick={() => setLoadAppointmentData(true)}
                  type="primary"
                >
                  Reload
                </Button>
              }
            />
          </div>
        )}

        {/* " Show No data" */}
        {appointmentLoadState === "noData" && (
          <div className="mt-2">
            <Empty></Empty>
          </div>
        )}

        {/* " Show Data " */}
        {appointmentLoadState === "completed" && (
          <div className="mt-2">
            <div>
              {/* Appointment */}

              <div className="grid grid-cols-1 gap-4">
                {tableData.map((appointment, index) => (
                  <>
                    <div key={index}>
                      {/* Cards */}
                      <div
                        onClick={() => navigateForward(index)}
                        className="flex flex-row p-2 w-full bg-white border border-gray-200 rounded-xl"
                      >
                        <div className="grow p-2 flex flex-col items-start justify-center">
                          <div className="font-semibold font-sans text-md mb-2">
                            {"Appointment Name"}
                          </div>
                          <div className="w-full text-gray-600 text-sm font-sans">
                            <p>{"12th July 2024"}</p>
                          </div>
                        </div>
                      </div>
                      {/* End  Cards */}
                    </div>
                  </>
                ))}
              </div>
              {/* End Appointment */}
            </div>

            {!hidePagination && (
              <div className="grid mt-6">
                <Pagination
                  current={currentPage || 1}
                  onChange={onPageChange}
                  pageSize={perPage}
                  total={totalItems}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
