import { PlusCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import "./vendoronboarding.css";

export const VendorServiceIntroComp = () => {
  return (
    <>
      <div className="grid p-4">
        <div className="flex mt-4">
          <p>
            Horem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
            vulputate libero et velit interdum, ac aliquet odio mattis.
          </p>
        </div>

        <div className="flex bg-stone-400 mt-8">
          <div className="p-4">
            <h3 className="font-sans w3-large mb-2">
              Tips for adding services
            </h3>
            <p className="font-sans text-xs mb-8">
              <ul className="list-disc">
                <li className="p-2">
                  Horem ipsum dolor sit amet, consecte adipiscing elit.
                </li>
                <li className="p-2">
                  Horem ipsum dolor sit amet, consecte adipiscing elit.
                </li>
              </ul>
            </p>
          </div>
        </div>

        <div className="flex mt-8">
          <p className="mb-4">
            <Link to={"/vendors/onboarding-add-services"}>
              <button className="font-medium rounded-lg text-xs px-5 py-2.5 me-2 mb-2 text-white bg-black hover:bg-green-500 h-12">
                <PlusCircleOutlined /> &nbsp; Add service
              </button>
            </Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default VendorServiceIntroComp;
