import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import useAuth from "./hooks/useAuth";
import { ProtectedRoute } from "./pages/ProtectedRoute/ProtectedRoutePage";
import { StatusBar, Style } from "@capacitor/status-bar";
import { useEffect } from "react";
import ExplorePagesRoute from "./pages/explorePages/explorePagesRoute";
import UserAuthRoutes from "./pages/userAuthenticationpages/userAuthRoute";
import VendorAuthRoutes from "./pages/vendorAuth/vendorAuthRoute";
import UserDashboardPagesRoute from "./pages/userDashboard/userDashboardPagesRoute";
import VendorDashboardPagesRoute from "./pages/vendorDashboard/vendorDashboardPagesRoute";

const App = () => {
  const authState = useAuth();

  const setStatusBarStyleLight = async () => {
    try {
      await StatusBar.setStyle({ style: Style.Dark });
      await StatusBar.setBackgroundColor({ color: "#4CAF50" });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setStatusBarStyleLight();
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<ExplorePagesRoute />} />
        <Route path="explore/*" element={<ExplorePagesRoute />} />
        <Route path="users/*" element={<UserDashboardPagesRoute />} />
        {/* <Route
          path="users/*"
          element={
            <ProtectedRoute>
              <ExplorePagesRoute />
            </ProtectedRoute>
          }
        /> */}
        <Route path="vendor-auth/*" element={<VendorAuthRoutes />} />
        <Route path="vendors/*" element={<VendorDashboardPagesRoute />} />
        <Route path="auth/*" element={<UserAuthRoutes />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
