import "./Vendor-Appointment-Comp.css";
import VendorClientDetailCard from "./VendorClientDetailCard";

export const VendorClientDetailWrapper = () => {
  return <div className="grid p-4">
    <VendorClientDetailCard></VendorClientDetailCard>
  </div>;
};

export default VendorClientDetailWrapper;
