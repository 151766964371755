import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Empty,
  notification,
  Pagination,
  Result,
  Spin,
  Modal,
} from "antd";
import { sampleApiCall } from "../../apiservice/authService";
import useFormatApiRequest from "../../hooks/formatApiRequest";
import { useAppDispatch } from "../../Redux/reduxCustomHook";
import { ILoadState } from "../../utils/loading.utils.";
import "./Vendor-Profile-Comp.css";

type NotificationType = "success" | "info" | "warning" | "error";

type IVendorGetStartedVideosListComp = {
  externalFilter?: any;
  initialDefaultFilter?: any;
  hidePagination?: boolean;
};

export const VendorGetStartedVideosListComp: React.FC<
  IVendorGetStartedVideosListComp
> = ({ externalFilter, initialDefaultFilter, hidePagination = false }) => {
  const [videosLoadState, setvideosLoadState] = useState<ILoadState>("loading");
  const [loadVideosData, setLoadvideosData] = useState(true);
  const [videosDefaultFilter, setvideosDefaultFilter] = useState(
    initialDefaultFilter || {}
  );
  const [tableData, setTableData] = useState<any[]>([]);
  const [api, contextHolder] = notification.useNotification();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState<string>("");

  // Pagination Constant/Variables
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const perPage = 10;

  // For Navigator/Redux
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Use Effect to reload API when External Filter has changed
  useEffect(() => {
    if (externalFilter) {
      setvideosDefaultFilter({
        ...videosDefaultFilter,
        ...externalFilter,
      });
      setLoadvideosData(true);
      setvideosLoadState("loading");
    }
  }, [externalFilter]);

  // A custom hook to Load All videos Details
  const videosDataResult = useFormatApiRequest(
    () => sampleApiCall(videosDefaultFilter),
    loadVideosData,
    () => {
      setLoadvideosData(false);
    },
    () => {
      processvideosResult();
    }
  );

  // Process The Current videos Data Result
  const processvideosResult = async () => {
    if (videosDataResult.httpState === "SUCCESS") {
      setTableData(
        videosDataResult.data?.data ||
          Array.from({ length: 10 }, (_, i) => ({
            videoName: `Tutorial Video ${i + 1}`,
            image: "https://via.placeholder.com/400",
            videoUrl:
              "https://sample-videos.com/video123/mp4/720/big_buck_bunny_720p_1mb.mp4",
          }))
      );
      setvideosLoadState("completed");
      setTotalItems(videosDataResult.data?.meta?.total || 10);
    } else if (videosDataResult.httpState === "ERROR") {
      setvideosLoadState("error");
    } else if (videosDataResult.httpState === "LOADING") {
      setvideosLoadState("loading");
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  // Use to Control Pagination On Change Event
  const onPageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setvideosDefaultFilter({
      ...videosDefaultFilter,
      page: page,
      perPage: pageSize,
    });
    setLoadvideosData(true);
  };

  // Open video in modal
  const openVideoModal = (videoUrl: string) => {
    setCurrentVideo(videoUrl);
    setIsModalOpen(true);
  };

  return (
    <>
      {/* The context is used to hold the notification from ant design */}
      {contextHolder}
      <div className="grid pt-4">
        {/* Show Loading Indicator */}
        {videosLoadState === "loading" && (
          <div className="mt-2 mb-2 flex justify-center items-center pt-20 pb-20">
            <Spin size="large" />
          </div>
        )}

        {/* Show Loading Error */}
        {videosLoadState === "error" && (
          <div className="mt-2 mb-2">
            <Result
              status="500"
              title={<span className="">Error</span>}
              subTitle={
                <span className="">
                  Sorry, something went wrong, it could be a network related
                  error
                </span>
              }
              extra={
                <Button onClick={() => setLoadvideosData(true)} type="primary">
                  Reload
                </Button>
              }
            />
          </div>
        )}

        {/* Show No Data */}
        {videosLoadState === "noData" && (
          <div className="mt-2">
            <Empty />
          </div>
        )}

        {/* Show Data */}
        {videosLoadState === "completed" && (
          <div className="mt-2">
            <div>
              {/* Videos */}
              <div className="grid grid-cols-1 gap-4">
                {tableData.map((video, index) => (
                  <div
                    key={index}
                    onClick={() => openVideoModal(video.videoUrl)}
                  >
                    {/* Cards */}
                    <div className="p-4 rounded-lg border border-gray-300 shadow-lg">
                      <div className="flex items-center justify-center">
                        <img
                          style={{
                            width: "100%",
                            maxHeight: "200px",
                            objectFit: "cover",
                          }}
                          src={video.image}
                          alt=""
                        />
                      </div>
                      <div className="flex flex-col items-center justify-center pt-4">
                        <p className="font-sans text-sm text-gray-700">
                          {video.videoName}
                        </p>
                      </div>
                    </div>
                    {/* End Cards */}
                  </div>
                ))}
              </div>
              {/* End Videos */}
            </div>

            {!hidePagination && (
              <div className="grid mt-6">
                <Pagination
                  current={currentPage || 1}
                  onChange={onPageChange}
                  pageSize={perPage}
                  total={totalItems}
                />
              </div>
            )}
          </div>
        )}
      </div>

      {/* Video Modal */}
      <Modal
        title="Video Player"
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
      >
        <video width="100%" controls>
          <source src={currentVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Modal>
    </>
  );
};
