import TopBar from "../../../components/LayoutComponent/TopBar/topBar";
import { VendorServiceCategoryList } from "../../../components/vendorOnboardingComponents/Vendor-Service-Category-List";
import "../vendorDashboard.css";

const VendorOnboardingAddServicePage = () => {
  return (
    <>
      {/* "Main Desgin" */}
      <div className="w3-content" style={{ minHeight: "400px" }}>
        <TopBar
          showBackButton
          displayType={"title"}
          title={"Add Your Service"}
        ></TopBar>
        <VendorServiceCategoryList></VendorServiceCategoryList>
      </div>
    </>
  );
};

export default VendorOnboardingAddServicePage;
