import TopBar from "../../../components/LayoutComponent/TopBar/topBar";
import "../explore.css";
import Tabbar from "../../../components/LayoutComponent/TabBar/Tabbar";
import { IAuthType } from "../../../apiservice/authService.type";
import { useAppSelector } from "../../../Redux/reduxCustomHook";
import { RootState } from "../../../Redux/store";

export const NotificationsPage = () => {
  const authData: IAuthType = useAppSelector(
    (state: RootState) => state?.AuthData
  );

  return (
    <>
      <div className="container" style={{ minHeight: "400px" }}>
        <TopBar></TopBar>
        <div className="text-center">No Notification At the moment.</div>
        <Tabbar menuType={authData.data?.token ? "users" : "general"}></Tabbar>
      </div>
    </>
  );
};

export default NotificationsPage;
