import "./Vendor-Home-Comp.css";

export const VendorHomeTopCard = () => {
  return (
    <>
      <div className="grid p-4">
        <h2 className="pb-4 font-semibold text-xl font-sans"> Hi Emmanuel </h2>
        <img
          src={`${
            process.env.PUBLIC_URL + "/images/homePage/vendorBanner.svg"
          }`}
          alt="Image_"
          style={{ width: "100%" }}
        />
      </div>
    </>
  );
};

export default VendorHomeTopCard;
